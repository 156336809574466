
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation,Autoplay } from 'swiper/modules';
import { Box, Button } from '@mui/material';
import { StyledBox } from '../teamgroupStyle'; 

import { btnContainer, buttonLeft, buttonRight } from '../teamGroup_style';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';.
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CudLeader from './leaders';
import CudDeveloper from './coders';
import CudAnalist from './analist';
import CudDesigner from './designer';
import CudSEO from './seoteam';
import CudSupport from './support';
import { cuddaloreCoders, cuddaloreTeam } from '../TeamInfo';

function CuddaloreTeam() {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  const handleClick = (index) => {
    // swiperRef.current.swiper.slideTo(index);
    // setActiveIndex(index);
    swiperRef.current.swiper.slideToLoop(index);
  setActiveIndex(index);
  };

  const handleSlideChange = (swiper) => {
    // setActiveIndex(swiper.activeIndex);
    const index = swiper.realIndex; // Use realIndex for loop mode
  setActiveIndex(index);
  };
  return (
    <>
      <Box sx={{ position: 'relative', maxWidth: '1244px', margin: '0px auto',marginBottom:'50px' }}>
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={false} 
          navigation={false} 
          modules={[Pagination, Navigation,Autoplay]}
          onSlideChange={handleSlideChange}
          // className="mySwiper"
          // autoplay={{
          //   delay: 3000, // Delay between slides in milliseconds (3 seconds in this case)
          //   disableOnInteraction: false, // Continue autoplay after user interactions
          // }}


        >
          {cuddaloreTeam.map((data,index) => (
            <SwiperSlide key={index}>
              <StyledBox>
                <img src={data.image} style={{ maxWidth: '100%', height: 'auto' }} alt={`Slide ${index + 1}`} />
              </StyledBox>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
      <Box >

      <Box
          sx={btnContainer}
        >
          <Box
            onClick={handlePrevClick}
            sx={buttonLeft}
          >
            <ChevronLeftIcon />
          </Box>
          <Box
            onClick={handleNextClick}
            sx={buttonRight}
          >

            <ChevronRightIcon />
          </Box>
        </Box>

      </Box>

        {/* Custom Pagination */}
        <Box
          sx={{
            top: '100%',
            position: 'absolute',
            bottom: 20, // Increased space from bottom
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: 1,
            zIndex: 10, // Ensure pagination dots are above other content
            padding: 1, // Padding to make background more visible
            borderRadius: 1,
          }}
        >
          {cuddaloreTeam.map((data,index) => (
            <Button
              key={index}
              onClick={() => handleClick(index)}
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                border:'1px solid #fff',
                minWidth: 0,
                padding: 0,
                backgroundColor: activeIndex === index ? '#ccc' : 'transparent',
                '&:hover': {
                  backgroundColor: activeIndex === index ? '#ccc' : '#ccc',
                },
              }}
            />
          ))}
        </Box>
        </Box>


        <CudLeader/>
        <CudAnalist/>
        <CudDeveloper/>
        <CudDesigner/>
        <CudSEO/>
        <CudSupport/>
      </>
      )
}

      export default CuddaloreTeam
