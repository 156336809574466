import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { TabDes, TabTitle } from '../servicesTabStyle'

function CodeTab() {
  let temp1 = [
    {
        title: 'Clean Architecture',
        description: 'Our code is structured for efficiency, ensuring smooth functionality and scalability for your website.',
    },
    {
        title: 'Security-First Approach',
        description: 'We build with security in mind, implementing measures to protect your website from potential threats.',
    },
    {
        title: 'Optimized Performance',
        description: 'Speed is a priority; our code ensures quick loading times and a seamless user experience.',
    },

]

let temp2 = [
    {
        title: 'Scalability Built-In',
        description: 'We create websites that can grow with your business, allowing for future enhancements and expansions.',
    },
    {
        title: 'Cross-Browser Compatibility',
        description: 'Our code ensures your website performs well across all major browsers and platforms without issues.',
    },
    {
        title: 'Continuous Improvement',
        description: 'We continuously refine our code, implementing the latest technologies to keep your site at the cutting edge.',
    },
]
return (
    <>
        <Box sx={{}}>
            <Typography sx={{ color: '#fff', maxWidth: '750px', fontFamily: 'Lufga' ,fontSize:{md:'22px',sm:'18px'},fontWeight:500}}>
            At House of Websites, we write clean, efficient code designed for performance and scalability. Our approach ensures your website is secure, fast, and future-ready, with built-in flexibility to adapt to evolving technologies and business needs.
            </Typography>

            <Box sx={{ display: 'flex',gap:'15px','@media (max-width:768px)':{flexDirection:'column'}}}>
                <Box>
                    {
                        temp1.map((data) => (
                            <Box sx={{ maxWidth: '400px',margin:'25px 0'}}>
                                <Typography variant='h6' sx={TabTitle}>
                                    {data.title}
                                </Typography>
                                <Typography variant='body1' sx={TabDes}>
                                    {data.description}
                                </Typography>
                            </Box>
                        ))
                    }
                </Box>

                <Box>
                    {
                        temp2.map((data) => (
                            <Box sx={{ maxWidth: '400px',margin:'25px 0' }}>
                                <Typography variant='h6' sx={TabTitle}>
                                    {data.title}
                                </Typography>
                                <Typography variant='body1' sx={TabDes}>
                                    {data.description}
                                </Typography>
                            </Box>
                        ))
                    }
                </Box>
            </Box>


        </Box>
    </>
)
}

export default CodeTab
