import { useEffect } from "react";



export const ErrorHandler = (setNameError,setMailError,setInfoError,setNumberError,numberLength,fullName,phoneNo,emailAddress,aboutProject)=>{
   

  
  let check = 0;
  let emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if(fullName.trim()){
    
    setNameError(false);
    check++;
  }
  else{
  
    setNameError(true);
  }


  if(!emailAddress.trim()){
    setMailError(true)
  }
  else if(!emailTest.test(emailAddress)){
    setMailError(true)
  }
  else{
    setMailError(false)
    check++;
  }

// for phonenumber validation

let numCheck = false;
let numberToString = phoneNo.toString().length;

  if(Array.isArray(numberLength))
    numCheck = numberLength.includes(numberToString);
  else
    if(numberLength == numberToString)numCheck = true;




  if(phoneNo.trim() && numCheck){
    setNumberError(false);
    check++;
  }
  else{
    setNumberError(true);
  }


  //for about project

  if(aboutProject.trim()){
    setInfoError(false)
    check++;
  }
  else{
    setInfoError(true)
  }


  return check;

}




