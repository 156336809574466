import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { TabDes, TabTitle } from '../servicesTabStyle'

function ResearchTab() {

    let temp1 = [
        {
            title: 'Data-Driven Insights',
            description: 'Leveraging data to inform decisions, we ensure our solutions are backed by thorough research and analysis.',
        },
        {
            title: 'Emerging Trends',
            description: 'We stay ahead of digital trends to keep your website future-proof and adaptable to market changes.',
        },
        {
            title: 'User Behavior Analysis',
            description: 'Understanding user needs and behaviors helps us design websites that resonate with audiences.',
        },

    ]

    let temp2 = [
        {
            title: 'Market Exploration',
            description: 'In-depth research into your industry and competitors allows us to craft competitive digital strategies.',
        },
        {
            title: 'Strategic Planning',
            description: 'Our research involves crafting detailed strategies to align with your business objectives and goals.',
        },
        {
            title: 'Testing and Iteration',
            description: 'We continuously test and refine our research to ensure optimal results and lasting impact.',
        },
    ]
    return (
        <>
            <Box sx={{}}>
                <Typography sx={{ color: '#fff', maxWidth: '750px', fontFamily: 'Lufga' ,fontSize:{md:'22px',sm:'18px'},fontWeight:500}}>
                At House of Websites, research drives our strategy. We analyze data, explore trends, and study market dynamics to create informed, evidence-based solutions that ensure your website excels in user engagement and industry performance.
                </Typography>

                <Box sx={{ display: 'flex',gap:'15px','@media (max-width:768px)':{flexDirection:'column'}}}>
                    <Box>
                        {
                            temp1.map((data) => (
                                <Box sx={{ maxWidth: '400px',margin:'25px 0'}}>
                                    <Typography variant='h6' sx={TabTitle}>
                                        {data.title}
                                    </Typography>
                                    <Typography variant='body1' sx={TabDes}>
                                        {data.description}
                                    </Typography>
                                </Box>
                            ))
                        }
                    </Box>

                    <Box>
                        {
                            temp2.map((data) => (
                                <Box sx={{ maxWidth: '400px',margin:'25px 0' }}>
                                    <Typography variant='h6' sx={TabTitle}>
                                        {data.title}
                                    </Typography>
                                    <Typography variant='body1' sx={TabDes}>
                                        {data.description}
                                    </Typography>
                                </Box>
                            ))
                        }
                    </Box>
                </Box>


            </Box>
        </>
    )
}

export default ResearchTab
