import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import casestudy1 from '../../../asset/images/casestudy1.png';
import casestudy2 from '../../../asset/images/caseStudy/avanaImage.png';
import casestudy3 from '../../../asset/images/caseStudy/mycImage.png';
import { contentContainer, workContainer, workContent, workDesc } from './style';
import { Link, useParams } from 'react-router-dom';
import casestudy4 from '../../../asset/images/caseStudy/elixifyImage.png'
import casestudy5 from '../../../asset/images/caseStudy/kailasaImage.png'
import casestudy6 from '../../../asset/images/caseStudy/neuranestImage.png'

const Work = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const worksContainerRef = useRef(null);
  const params = useParams();
  const works = [
    // {
    //   id: 1,
    //   name: 'New concept properties',
    //   works: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard dummy text ever since',
    //   image: casestudy1,
    //   button: 'View Case Study',
    //   aos: 'fade-up',
    //   link: '/casestudy/ncp',
    //   ref:'ncp'
    // },
    {
      id: 2,
      name: 'Avana',
      works: 'NLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard dummy text ever since',
      image: casestudy2,
      button: 'View Case Study',
      aos: 'fade-up',
      link: '/casestudy/avana',
      ref:'avana'
    },
    {
      id: 3,
      name: 'Mycelium',
      works: 'SLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard dummy text ever since',
      image: casestudy3,
      button: 'View Case Study',
      aos: 'fade-up',
      link: '/casestudy/Mycelium',
      ref:'Mycelium'
    },
    {
      id: 4,
      name: 'Elixify',
      works: 'SLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard dummy text ever since',
      image: casestudy4,
      button: 'View Case Study',
      aos: 'fade-up',
      link: '/casestudy/elixify',
      ref:'elixify'
    },
    {
      id: 5,
      name: 'kailasa kaladhara',
      works: 'SLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard dummy text ever since',
      image: casestudy5,
      button: 'View Case Study',
      aos: 'fade-up',
      link: '/casestudy/vivarta',
      ref:'vivarta'
    },
    {
      id: 6,
      name: 'Neuranest',
      works: 'SLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been standard dummy text ever since',
      image: casestudy6,
      button: 'View Case Study',
      aos: 'fade-up',
      link: '/casestudy/neuranest',
      ref:'neuranest'
    },
  ];

  const worksTemp = [];
  works.map((data)=>{
    if(data.ref != params.slug)worksTemp.push(data);
  })

  const itemsToShow = () => {
    const width = window.innerWidth;
    if (width >= 1024) return 3;
    if (width >= 768) return 2;
    return 1;
  };

  const [visibleItems, setVisibleItems] = useState(itemsToShow);

  useEffect(() => {
    const handleResize = () => setVisibleItems(itemsToShow());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % worksTemp.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + worksTemp.length) % worksTemp.length);
  };

  useEffect(() => {
    const autoScroll = setInterval(nextSlide, 5000);
    return () => clearInterval(autoScroll);
  }, []);

  // Calculate visible works dynamically
  const visibleWorks = [];
  for (let i = 0; i < visibleItems; i++) {
    visibleWorks.push(worksTemp[(currentIndex + i) % worksTemp.length]);
  }

  return (
    <Box >
      <Box
      ref={worksContainerRef}
      id="work-section"
      sx={{ ...workContainer, position: 'relative', overflow: 'hidden' }}
      onMouseEnter={() => {
        worksContainerRef.current.querySelectorAll('.arrow').forEach((el) => (el.style.opacity = '1'));
      }}
      onMouseLeave={() => {
        worksContainerRef.current.querySelectorAll('.arrow').forEach((el) => (el.style.opacity = '0'));
      }}
    >
      <ArrowBackIosNewIcon
        className="arrow"
        onClick={prevSlide}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          opacity: 0,
          transition: 'opacity 0.3s',
          zIndex: 1,
        }}
      />
      <ArrowForwardIosIcon
        className="arrow"
        onClick={nextSlide}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          opacity: 0,
          transition: 'opacity 0.3s',
          zIndex: 1,
        }}
      />

      <Box>
        <Grid>
          <Grid item sx={contentContainer}>
            <Typography align="start" gutterBottom sx={workContent}>
              See more of our work
            </Typography>
            {/* <Typography align="start" gutterBottom sx={workDesc}>
              Apart from content, People, Planet, and Produce were also the three distinct pillars that
              guided Fresh Del Monte’s new visual language, which we brought to life using inspiring
              imagery and bright, vibrant colors.
            </Typography> */}
          </Grid>
        </Grid>

        <Grid container spacing={4} justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
          {visibleWorks.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              data-aos={item.aos}
            >
              <Box
                sx={{
                  borderRadius: '8px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  textAlign: 'start',
                  height: '100%',
                }}
              >
                <Box
                  component="img"
                  src={item.image}
                  alt={item.name}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    borderRadius: '8px 8px 0 0',
                    marginBottom: '16px',
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#fff', mb: 0.5, fontFamily: 'Lufga' }}
                >
                  {item.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '16px', mb: 2, fontFamily: 'Lufga', fontWeight: '300' ,lineHeight:'28px'}}
                >
                  {item.works}
                </Typography>
                {item.button && (
                  <Link to={item.link} target='blank'><Button
                    variant="text"
                    sx={{
                      textDecoration: 'underline',
                      textUnderlineOffset: '3px',
                      color: '#00E5FD',
                      textTransform: 'none',
                      marginTop: '15px',
                      fontSize: '18px',
                      letterSpacing: '0.7px',
                      fontFamily: 'Lufga',
                      fontWeight:'400',
                      padding: 0,
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    {item.button}
                  </Button></Link>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
    </Box>
  );
};

export default Work;
