import { Box, Typography } from '@mui/material'
import React from 'react'
import { serviceSteps } from '../../../constants'
import { StepDes, StepInfo, StepLeft, StepOuter, StepRight, StepTitle } from './serviceStep_style'

function ServiceStep() {
    return (
        <>

            <Box>


                <Box sx={{ maxWidth: '1296px', margin: '0 auto', padding: { md: '0 72px', sm: '0 2rem', xs: '0 20px' } }}>
                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', padding: { md: '100px 0 40px 0', xs: '40px 0 40px 0' }, gap: '20px', '@media (max-width:1100px)': {
                            flexDirection: 'column'
                        }
                    }}>
                        <Typography variant='h2' sx={{ fontSize: '100px', '@media (max-width:1250px)': { fontSize: '80px' }, '@media (max-width:1200px)': { fontSize: '60px' }, '@media (max-width:600px)': { fontSize: '36px' } }} >
                            How we
                            Work
                        </Typography>
                        <Typography sx={{ maxWidth: '700px', fontSize: '22px', fontFamily: 'Lufga', fontWeight: '500', display: 'flex', alignItems: 'center', '@media (max-width:600px)': { fontSize: '18px' } }}>
                            At House of Websites, we
                            Discover
                            needs,
                            Define
                            goals,
                            Design
                            creative solutions,
                            Develop
                            seamless code, and
                            Deliver
                            exceptional websites. Our process ensures strategic, user-centered results that drive engagement and foster digital growth.
                        </Typography>
                    </Box>


                    <Box sx={{ marginBottom: '100px' }}>
                        <hr />

                        {
                            serviceSteps.map((data) => (

                                <Box>

                                    <StepOuter>
                                        <StepLeft>
                                            <StepInfo>{data.step}</StepInfo>
                                            <StepTitle>{data.title}</StepTitle>

                                            <StepDes>{data.des}</StepDes>
                                        </StepLeft>
                                        <StepRight>
                                            <img src={data.image} style={{ maxWidth: '100%' }} alt={data.title}/>
                                        </StepRight>
                                    </StepOuter>

                                    <hr />
                                </Box>

                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ServiceStep
