import { styled } from '@mui/material/styles'
import { Box, Button, DialogTitle, InputLabel, TextField, Typography } from '@mui/material'

export const Root = styled('div')({
    // backgroundColor: '#070707',
    position: 'relative',
    color: 'white',
    background: `radial-gradient(
        circle at top,
        rgba(8, 0, 139, 1) -10%,
        rgba(15, 1, 33, 1) 45%
      )`,
})

export const ImageContainer = styled('div')({
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // backgroundRepeat: 'no-repeat',
    position: 'absolute',
    
})

export const Image = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
})

export const Container = styled('div')(({ theme }) => ({

    maxWidth: '1296px',
    margin: 'auto',
    position: 'relative',
    padding: '0 20px 100px',
    [theme.breakpoints.up('md')]: {
        padding:'0 4.5rem 100px'
    },
    
    '@media (min-width: 768px) and (max-width: 992px)':{
        padding: '0 2rem 100px',
    },
    '@media (min-width: 993px) and (max-width: 1200px)':{
        padding: '0 72px 100px',
    },

}))

export const BackButton = styled('div')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '20px',
    paddingTop: '125px',
    gap:'5px'
})

export const DetailsBox = styled('div')({
    maxWidth: '875px',
    margin: '41px auto 0',
    background: '#11111180;',
    borderRadius: '30px',
    padding: '30px 35px',
    border: '1px solid #FFD400',
    '@media (max-width:680px)':{
        padding: '30px 20px',
    },
})

export const TitleContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:680px)':{
        flexDirection: 'column',
        paddingBottom: '20px',
        gap: '6px',
    },
})

export const Title = styled(Typography)({
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily: 'Dystopian',
    // color: '#10200B',
    '@media (max-width:680px)':{
        fontSize: '22px',
    },
})

export const SubInfoContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
})

export const SubInfoText = styled(Typography)({
    fontSize: '12px',
    fontWeight: 'Light',
    paddingRight: '20px',
    fontFamily: 'Lufga',
    fontWeight: '300',
})

export const GridContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: '150px auto',
    gridRowGap: '18px',
    paddingTop: '10px',
    '@media (max-width:680px)':{
        gridTemplateColumns: '100px auto',
        gridRowGap: '10px',
        gridColumnGap: '10px',
    },
})

export const GridItem = styled('div')({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    fontFamily: 'Lufga',

})

export const DescriptionText = styled(Typography)(({ theme }) => ({

lineHeight:'24px',
    color: '#EEEEEE',
    fontSize: '14px',
    fontFamily: 'Lufga',
    fontWeight:200

}))

export const SectionTitle = styled(Typography)({
    fontWeight: '500',
    padding: '30px 0px 21px',
    fontFamily: 'Lufga',
})

export const ReadMoreText = styled(Typography)({
    fontFamily: 'Lufga',
    color: '#EEEEEE',
    fontSize: '14px',
    fontWeight:200,
})

export const ReadMoreButton = styled('span')({
    color: '#07E8EE',
    cursor: 'pointer',
    fontFamily: 'Lufga',
})

export const Ul = styled('ul')({
    margin: 0,
    '@media (max-width: 680px)': {
        paddingLeft: '17px',
    }
})

export const ButtonApply = styled('Button')({
    fontFamily: 'Lufga',
    backgroundColor: '#07E8EE',
    borderRadius: '5px',
    fontSize: '16px',
    padding: '11px 19px',
    border: '1px solid transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      border: '1px solid #07E8EE',
      backgroundColor: 'transparent',
      color: '#07E8EE',
    },
})



// Job Application Form style


export const DialogTitleStyle = styled(DialogTitle)(({ theme }) => ({
    textAlign: 'center',
    fontFamily: 'Lufga',
    fontSize: '22px',
    fontWeight: '700',
    paddingBottom: 0,
}))

export const DialogMainContainer = styled(Box)(({ theme }) => ({

    padding: '1.5rem 1.8rem ',
    display: 'flex',
    flexDirection: 'column',

}))

export const ContactInformation = styled(Typography)(({ theme }) => ({
    mb: 2,
    fontFamily: 'Lufga',
    fontSize: '14px',
    margin: 0,
    fontWeight: '700',
}))

export const PleaseContactInformation = styled(Typography)(({ theme }) => ({
    marginBottom: '1rem',
    fontFamily: 'Lufga',
    fontSize: '14px',
}))

export const DialogContentSubContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
}))

export const SocialMediaSubContainer = styled(Box)(({ theme,index }) => ({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginTop: index > 0 ? '8px' : '0',
}))

export const InputLabelFields = styled(InputLabel)(({ theme }) => ({
    fontFamily: 'Lufga',
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '5px',
}))

export const TextFieldStyle = styled(TextField)(({ theme }) => ({
    margin: 0,
    fontFamily: 'Lufga',

    '& .MuiFormHelperText-root': {
        margin: 0,
        fontFamily: 'Lufga',
    },
    '& .MuiOutlinedInput-input': {
        padding: '11px 14px',
        fontFamily: 'Lufga',
        fontWeight:400

    },
    '& fieldset': {
        border: '1px solid #D4D4D4',
    },
}))


export const ResumeSubContainer = styled(Box)(({ theme,error }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    border: error? '1px solid red': '1px solid #D4D4D4',

    borderRadius: '4px',
    padding: '5px 10px',
}))

export const ResumeUploadButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    padding: '5px',
    background: '#EEEEEE',
    fontFamily: 'Lufga',
    color: '#111111',
    fontSize: '14px',
}))

export const SubmitButton = styled(Button)(({ theme }) => ({
    background: '#0781F2',
    fontFamily: 'Lufga',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'capitalize',
    margin: '20px 0 15px 0',
    padding: '8px 0',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    '&:hover': {
        background: '#005BBB',
        boxShadow:
            '0px 4px 10px rgba(0, 0, 0, 0.2)',
    },
}))