import { LineWeight } from "@mui/icons-material"

export const overallbg = {
    backgroundColor: '#070707',
    color: '#fff',
    margin:'0 auto'
}

export const firstSection = {
    // height: '100vh',
    maxWidth:'1400px',
    margin:'0 auto',
    padding: '100px 4.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        padding: '70px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        padding: '40px 20px',
    },
    paddingBottom:'150px',
    h1:{
        margin:'100px 0px',
        fontSize:'60px',
        fontWeight:'700',
        maxWidth:'1222px',
        LineHeight:'',
        fontFamily: 'Dystopian',

        '@media (min-width: 601px) and (max-width: 900px)' : {
            padding: '70px 0rem',
            margin:'40px 0px',
            fontSize:'60px',
        },
        '@media (min-width: 320px) and (max-width: 600px)' : {
            padding: '40px 0px',
            fontSize:'30px',
            margin:'30px 0px'
        },
        '@media (min-width:1200px)':{
            margin:'100px 25px',
        }
    }
}
export const caseStudyBanner = {
    maxWidth:'1150px',
    backgroundColor:'#D9D9D9',
    // height:'80vh',
    margin:'0 auto',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:'10px'
}
export const caseStudyImageStyle = {
    maxWidth:'812px',
    margin:'50px 50px 0px 50px'
}