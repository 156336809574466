import { Box, styled } from "@mui/material";

export const StepOuter = styled(Box)(({ theme }) => ({

    display: 'flex',
    gap: '50px',
    padding: '40px 0',
    fontFamily: 'Lufga',
    '@media (max-width:600px)':{
        flexDirection:'column',
        padding:'25px 0'
    }


}));

export const StepLeft = styled(Box)(({ theme }) => ({

    display: 'flex',
    flexDirection: 'column',
    maxWidth: '420px',




}));
export const StepRight = styled(Box)(({ theme }) => ({

    display: 'flex',
    flexDirection: 'column',
    maxWidth: '820px',
    alignItems:'center',
    justifyContent:'center',

}));
export const StepTitle = styled(Box)(({ theme }) => ({
    marginBottom:'10px',
    fontWeight: '500',
    fontSize: '50px',
    '@media (max-width:992px)':{
        fontSize:'32px',
    },
    '@media (max-width:600px)':{
        fontSize:'24px',
    }

}));
export const StepDes = styled(Box)(({ theme }) => ({
    fontWeight: '500',
    fontSize: '18px',
    '@media (max-width:600px)':{
        fontSize:'16px',
    }

}));
export const StepInfo = styled(Box)(({ theme }) => ({
    marginBottom:'5px',
    fontWeight: '300',
    fontSize: '22px',
    '@media (max-width:600px)':{
        fontSize:'18px',
    }

}));