import React from 'react'
import {
    Grid,
    Box,
    Typography,
    Item,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Images } from '../../asset/images'
import './style.css'
import {
    WorkHeading,
    WorkHeaderColor,
    WorkHelloDreamer,
    WorkHelloDreamerPtag,
} from './work.style'
import { useState, useRef } from 'react'
import Website from './website'
import { Helmet } from 'react-helmet-async';
import workBg from '../../asset/images/workBg.png'
import workBgDown from '../../asset/images/workDownBg.png'
import vivartaBgBanner from '../../asset/images/caseStudy/vivarta_caseStudy.png'
import { caseStudyBanner, caseStudyImageStyle } from '../casestudy/style'
import CategoriesWorks from './caseStudyWorks/CategoriesWorks'
import { Link } from 'react-router-dom'

export default function Works() {
    //down scroll
    const scrollToSectionRef = useRef(null)

    const handleScroll = () => {
        if (scrollToSectionRef.current) {
            // Get the top position of the element relative to the document
            const elementPosition =
                scrollToSectionRef.current.getBoundingClientRect().top +
                document.documentElement.scrollTop

            // Adjust the offset as needed (e.g., 100px above the section)
            const offset = -200 // Adjust this value to where you want the scroll to stop

            // Scroll to the calculated position
            window.scrollTo({
                top: elementPosition - offset,
                behavior: 'smooth',
            })
        }
    }

    const theme = useTheme()
    const isLargeScreen = useMediaQuery('(min-width: 1235px)')
    const isMediumScreen = useMediaQuery('(max-width: 1235px)')

    const isLargeScreen2 = useMediaQuery('(min-width: 521px)')
    const isMediumScreen2 = useMediaQuery('(max-width: 521px)')
    console.log(isLargeScreen2, isMediumScreen2)

    return (
        <>
            <Helmet>
                <title>
                    Our Work | Case Studies of Web Design & SEO Success |HOW
                </title>
                <meta
                    name="title"
                    content="Our Work | Case Studies of Web Design & SEO Success |HOW"
                />
                <meta
                    name="description"
                    content="Discover our web design & SEO case studies at House of websites. Learn how we’ve helped businesses succeed—reach out and start your project with us!"
                />
                <meta
                    name="keywords"
                    content="House of Websites portfolio, Creative Web Solutions, our works, web design projects Chennai, website development portfolio, successful web design examples, creative project showcase, digital solutions portfolio, client work examples, website design case studies, innovative design projects, web development case studies, branding projects, eCommerce website examples, responsive web design works, Chennai web agency portfolio"
                />
                <link
                    rel="canonical"
                    href="https://houseofwebsites.com/works"
                />
                <meta
                    property="og:title"
                    content="Our Work | Case Studies of Web Design & SEO Success | HOW"
                />
                <meta
                    property="og:description"
                    content="Discover our web design & SEO case studies at House of Websites. Learn how we’ve helped businesses succeed—reach out and start your project with us!"
                />
                <meta
                    property="og:url"
                    content="https://houseofwebsites.com/works"
                />
                <meta
                    property="og:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="House of Websites" />
                <meta property="og:locale" content="en_IN" />
                <meta
                    name="twitter:title"
                    content="Our Work | Case Studies of Web Design & SEO Success | HOW"
                />
                <meta
                    name="twitter:description"
                    content="Discover our web design & SEO case studies at House of Websites. Learn how we’ve helped businesses succeed—reach out and start your project with us!"
                />
                <meta
                    name="twitter:url"
                    content="https://houseofwebsites.com/works"
                />
                <meta
                    name="twitter:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                ></meta>
            </Helmet>
            {/* <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ position: 'relative' }}>
                            <img
                                loading="eager"
                                src={Images.WorkHeroImg}
                                className="bannerImg"
                                alt="House of Websites"
                                style={{ width: '100%' }}
                            />
                            <Box
                                className="bannercontent container1 container2"
                                sx={{
                                    position: 'absolute',
                                    top: 'clamp(83px,4vw,166px)',
                                    left: '0',
                                    right: '0',
                                    color: 'white',
                                    padding: '10.375rem 0rem 0 ',
                                    maxWidth: '1296px',
                                    margin: '0 auto',
                                }}
                            >
                                <WorkHeading variant="h1">
                                    A Process — with
                                    <br />
                                    You in Mind
                                </WorkHeading>
                                {isLargeScreen2 && (
                                    <WorkHeaderColor variant="body2">
                                        We craft websites with your needs in
                                        mind. Our process ensures
                                        <br /> smooth execution, delivering a
                                        site that perfectly matches your
                                        <br /> vision.
                                    </WorkHeaderColor>
                                )}

                                {isMediumScreen2 && (
                                    <WorkHeaderColor variant="body2">
                                        We craft websites with your needs in
                                        mind. Our process ensures smooth
                                        execution, delivering a site that
                                        perfectly matches your vision.
                                    </WorkHeaderColor>
                                )}
                            </Box>

                            <Box
                                className="bannerComputer"
                                sx={{
                                    width: '100%',
                                    // position: 'absolute',
                                    // top: '62%',
                                    transform: 'translateY(-367px)',
                                    height: '0px',
                                    textAlign: 'center',
                                }}
                            >
                                <Grid
                                    container
                                    rowSpacing={1}
                                    sx={{
                                        padding: {
                                            xs: '0 1.25rem',
                                            sm: '0 2rem',
                                            md: '0 2rem',
                                            lg: '0',
                                        },
                                        maxWidth: '1296px',
                                        margin: '0 auto',
                                    }}
                                    className="imagecompPad container1 container2"
                                >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <img
                                            loading="eager"
                                            src={Images.WorkCompImg}
                                            alt="House of Websites"
                                            style={{ width: '100%' }}
                                        />
                                        <div
                                            id="downArrow"
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '17%',
                                                width: '156px',
                                                height: '100px',
                                                //  zIndex:'10',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleScroll}
                                        ></div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box> */}

            {/* <Box sx={{ width: '100%', backgroundColor: '#000' }}>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <Box
                            className="container1 container2"
                            sx={{
                                padding: {
                                    xs: '0 1.25rem',
                                    sm: '0 2rem',
                                    md: '0 4.5rem',
                                    lg: '0',
                                },
                                maxWidth: '1296px',
                                margin: '0 auto',
                            }}
                        >
                            <WorkHelloDreamer
                                className="dreamerCont"
                                // variant="h1"
                                ref={scrollToSectionRef}
                            >
                                Hello Trailblazers
                            </WorkHelloDreamer>
                            {isLargeScreen && (
                                <WorkHelloDreamerPtag variant="body2">
                                    We’re a globally recognized design
                                    consultancy, offering strategic insights and
                                    solutions. Our team blends brilliant
                                    minds—thinkers, strategists, designers,
                                    researchers, developers, and organizers.
                                    Perfectly sized, entirely independent, and
                                    dedicated to excellence.
                                </WorkHelloDreamerPtag>
                            )}
                            {isMediumScreen && (
                                <WorkHelloDreamerPtag variant="body2">
                                    We’re a globally recognized design
                                    consultancy, offering strategic insights and
                                    solutions. Our team blends brilliant
                                    minds—thinkers, strategists, designers,
                                    researchers, developers, and organizers.
                                    Perfectly sized, entirely independent, and
                                    dedicated to excellence.
                                </WorkHelloDreamerPtag>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box> */}

            <Box
                sx={{
                    backgroundImage:`url(${workBg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            >
              <Box
              sx={
                {
                    maxWidth:'1296px',
                    margin:'0 auto',
                    padding: '100px 4.5rem',
                    '@media (min-width: 601px) and (max-width: 900px)' : {
                        padding: '70px 2rem',
                    },
                    '@media (min-width: 320px) and (max-width: 600px)' : {
                        padding: '40px 20px',
                    },
                }
              }>
              <Box sx={{
                padding:'100px 0',
                maxWidth:'800px',

              }}>
                  <WorkHeading variant="h1">
                                    A Process — with
                                    <br />
                                    You in Mind
                                </WorkHeading>
                    <WorkHeaderColor>
                        We craft websites with your needs in mind. Our WorkHeaderColorrocess ensures
                        smooth execution, delivering a site that perfectly matches your
                        vision.
                    </WorkHeaderColor>
                </Box>

                <Box sx={{
                  ...caseStudyBanner,
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${workBgDown})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
               }}>
                  <Box sx={caseStudyImageStyle}>
                    
                     <Link to={'/casestudy/vivarta'}>
                     <img
                        src={vivartaBgBanner}
                        alt="Case Study Banner"
                        style={{
                           width: "100%",
                           float: "inline-end",
                           transition: "transform 0.8s ease-in-out, opacity 0.8s ease-in-out", // Smooth & slow effect
                        }}
                        onMouseEnter={(e) => {
                           e.target.style.transform = "translateY(10px) scale(1.05)"; // Move down & zoom in slightly
                           // e.target.style.opacity = "0.9"; // Light fade effect
                        }}
                        onMouseLeave={(e) => {
                           e.target.style.transform = "translateY(0) scale(1)"; // Reset position & zoom
                           e.target.style.opacity = "1"; // Reset opacity
                        }}
                     />
                     </Link>

                  </Box>
               </Box>


              </Box>

            </Box>
            <CategoriesWorks/>
            {/* <Website /> */}
        </>
    )
}
