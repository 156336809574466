import vivimg1 from '../asset/images/caseStudy/vivarta_caseStudy.png'
import vivimg2 from '../asset/images/caseStudy/caseStudyImg1.png'
import vivimg3 from '../asset/images/caseStudy/caseStudyImg2.png'
import vivimg4 from '../asset/images/caseStudy/caseStudyImg3.png'
import vivimg5 from '../asset/images/caseStudy/caseStudyImg4.png'
import vivimg6 from '../asset/images/caseStudy/caseStudyImg21.png'
import vivimg7 from '../asset/images/caseStudy/caseStudyImg22.png'
import vivimg8 from '../asset/images/caseStudy/caseStudyImg23.png'
import vivimg9 from '../asset/images/caseStudy/visuallang2.png'
import vivimg10 from '../asset/images/caseStudy/visuallang1.png'
import vivimg11 from '../asset/images/caseStudy/visuallang4.png'
import vivimg12 from '../asset/images/caseStudy/plus_jakarta.png'
import vivimg13 from '../asset/images/caseStudy/project1.png'
import vivimg14 from '../asset/images/caseStudy/project2.png'
import vivimg15 from '../asset/images/caseStudy/project3.png'
import vivimg16 from '../asset/images/caseStudy/project4.png'
import vivimg17 from '../asset/images/caseStudy/project5.png'
import vivimg18 from '../asset/images/caseStudy/project6.png'
import ncp1 from '../asset/images/caseStudy/ncp1.png'
import ncp2 from '../asset/images/caseStudy/ncp2.png'
import ncp3 from '../asset/images/caseStudy/ncp3.png'
import ncp4 from '../asset/images/caseStudy/ncp4.png'
import ncp5 from '../asset/images/caseStudy/ncp5.png'
import ncp6 from '../asset/images/caseStudy/ncp6.png'
import ncp7 from '../asset/images/caseStudy/ncp7.png'
import ncp8 from '../asset/images/caseStudy/ncp8.png'
import ncp9 from '../asset/images/caseStudy/ncp9.png'
import ncp10 from '../asset/images/caseStudy/ncp10.png'
import ncp11 from '../asset/images/caseStudy/ncp11.png'
import ncp12 from '../asset/images/caseStudy/ncp12.png'
import ncp13 from '../asset/images/caseStudy/ncp13.png'
import ncp14 from '../asset/images/caseStudy/ncp14.png'
import ncp15 from '../asset/images/caseStudy/ncp15.png'
import ncp16 from '../asset/images/caseStudy/ncp16.png'
import ncp17 from '../asset/images/caseStudy/ncp17.png'
import ncp18 from '../asset/images/caseStudy/ncp18.png'
import ncp19 from '../asset/images/caseStudy/ncp19.png'
import elix1 from '../asset/images/caseStudy/elixifyBannerImg.png'
import elix2 from '../asset/images/caseStudy/elix2.png'
import elix3 from '../asset/images/caseStudy/elix3.png'
import elix4 from '../asset/images/caseStudy/elix4.png'
import elix5 from '../asset/images/caseStudy/elix5.png'
import elix6 from '../asset/images/caseStudy/elix6.png'
import elix7 from '../asset/images/caseStudy/elix7.png'
import elix8 from '../asset/images/caseStudy/elix8.png'
import elix9 from '../asset/images/caseStudy/elix9.png'
import elix10 from '../asset/images/caseStudy/elix10.png'
import elix11 from '../asset/images/caseStudy/elix11.png'
import elix12 from '../asset/images/caseStudy/elixifyColorPattern.png'
import elix13 from '../asset/images/caseStudy/figtree.png'
import elix14 from '../asset/images/caseStudy/elix14.png'
import elix15 from '../asset/images/caseStudy/elix15.png'
import elix16 from '../asset/images/caseStudy/elix16.png'
import elix17 from '../asset/images/caseStudy/elix17.png'
import elix18 from '../asset/images/caseStudy/elix18.png'
import elix19 from '../asset/images/caseStudy/elix19.png'
import new1 from '../asset/images/caseStudy/neuranestBannerImg.png'
import new2 from '../asset/images/caseStudy/new2.png'
import new3 from '../asset/images/caseStudy/new3.png'
import new4 from '../asset/images/caseStudy/new4.png'
import new5 from '../asset/images/caseStudy/new5.png'
import new6 from '../asset/images/caseStudy/new6.png'
import new7 from '../asset/images/caseStudy/new7.png'
import new8 from '../asset/images/caseStudy/new8.png'
import new9 from '../asset/images/caseStudy/new9.png'
import new10 from '../asset/images/caseStudy/new10.png'
import new11 from '../asset/images/caseStudy/new11.png'
import new12 from '../asset/images/caseStudy/newColorPattern.png'
import new13 from '../asset/images/caseStudy/satoshi.png'
import new14 from '../asset/images/caseStudy/new14.png'
import new15 from '../asset/images/caseStudy/new15.png'
import new16 from '../asset/images/caseStudy/new16.png'
import new17 from '../asset/images/caseStudy/new17.png'
import new18 from '../asset/images/caseStudy/new18.png'
import new19 from '../asset/images/caseStudy/new19.png'
import myc1 from '../asset/images/caseStudy/mycBannerImg.png'
import myc2 from '../asset/images/caseStudy/myc2.png'
import myc3 from '../asset/images/caseStudy/myc3.png'
import myc4 from '../asset/images/caseStudy/myc4.png'
import myc5 from '../asset/images/caseStudy/myc5.png'
import myc6 from '../asset/images/caseStudy/myc6.png'
import myc7 from '../asset/images/caseStudy/myc7.png'
import myc8 from '../asset/images/caseStudy/myc8.png'
import myc9 from '../asset/images/caseStudy/myc9.png'
import myc10 from '../asset/images/caseStudy/myc10.png'
import myc11 from '../asset/images/caseStudy/mycColorPattern.png'
import myc12 from '../asset/images/caseStudy/manrope.png'
import myc13 from '../asset/images/caseStudy/myc13.png'
import myc14 from '../asset/images/caseStudy/myc14.png'
import myc15 from '../asset/images/caseStudy/myc15.png'
import myc16 from '../asset/images/caseStudy/myc16.png'
import myc17 from '../asset/images/caseStudy/myc17.png'
import myc18 from '../asset/images/caseStudy/myc18.png'
import av1 from '../asset/images/caseStudy/avanaBannerImg.png'
import av2 from '../asset/images/caseStudy/av2.png'
import av3 from '../asset/images/caseStudy/av3.png'
import av4 from '../asset/images/caseStudy/av4.png'
import av5 from '../asset/images/caseStudy/av5.png'
import av6 from '../asset/images/caseStudy/av6.png'
import av7 from '../asset/images/caseStudy/av7.png'
import av8 from '../asset/images/caseStudy/av8.png'
import av9 from '../asset/images/caseStudy/av9.png'
import av10 from '../asset/images/caseStudy/av10.png'
import av11 from '../asset/images/caseStudy/avanaColorPattern.png'
import av12 from '../asset/images/caseStudy/henken.png'
import av13 from '../asset/images/caseStudy/av13.png'
import av14 from '../asset/images/caseStudy/av14.png'
import av15 from '../asset/images/caseStudy/av15.png'
import av16 from '../asset/images/caseStudy/av16.png'
import av17 from '../asset/images/caseStudy/av17.png'
import av18 from '../asset/images/caseStudy/av18.png'

//for bg images

import kailasa from '../asset/images/caseStudy/kailasaImage.png'
import avanaBg from '../asset/images/caseStudy/avanaImage.png'
import mycBg from '../asset/images/caseStudy/mycImage.png'
import elixifyBg from '../asset/images/caseStudy/elixifyImage.png'
import neuranestBg from '../asset/images/caseStudy/neuranestImage.png'
import ncpBg from '../asset/images/casestudy1.png'


export const kailasaKalathara = {
    heroSection:{
        title:'Preserving Culture Through Classical Dance Education',
        image:vivimg1,
        bgImage:kailasa,
    },
    secondSection:{
        title:'Kailasa Kaladhara',
        subTitle:'Website Design, SEO',
        description:'Preserving culture isn’t just a passion—it’s our mission. Kailasa Kaladhara is dedicated to promoting Indian classical arts and empowering individuals with disabilities through education, inclusivity, and community-driven initiatives. We strive to create a world where tradition meets transformation, ensuring that heritage and accessibility go hand in hand.',
        title1:'Approach',
        subTitle1:'Experience Strategy',
        description1:'We positioned Kailasa Kaladhara as a leader in cultural preservation and social empowerment. The platform showcases its expertise in classical dance education and commitment to inclusivity, fostering engagement and community impact.By blending tradition with innovation, we reinforce trust and leadership, highlighting how Kailasa Kaladhara enriches lives through cultural education and inclusive initiatives.'
    },
    thirdSection:{
        image1:[
            vivimg2,vivimg3,vivimg4,vivimg5
        ],
        image2:[
            vivimg6,vivimg7,vivimg8

        ]
    },
    fourthSection:{
        title:'Visual Language',
        description:'The visual identity of Kailasa Kaladhara is rooted in its mission to preserve culture, empower communities, and inspire creativity through classical dance. The three pillars—Culture, Community, and Creativity—serve as the foundation for the brand’s visual language, brought to life through inspiring imagery, vibrant colors, and elegant design elements.',
        image:[
            vivimg9,vivimg10,vivimg11,vivimg12

        ]

    },fifthSection:{
        images:[
            vivimg13,vivimg14,vivimg15,vivimg16,vivimg17,vivimg18
        ],
        title1:'Website Design',
        description1:'We designed a clean, user-friendly platform that reflects Kailasa Kaladhara’s dedication to preserving cultural heritage and empowering communities through classical dance. With intuitive navigation and engaging visuals, the website ensures seamless access to information about programs, events, and initiatives, building trust and credibility in the world of cultural education.'
    },
    Testimonial:[
        {
            name: 'Ravisankar',
            post: 'Kailasa Kaladhara',
            content: 'Happy to share with you all that we won an award for our hospitality venture on our maiden year',
        },
        // {
        //     name: 'Naruto',
        //     post: '7th Hokage',
        //     content: 'Naruto recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Sasuke',
        //     post: 'Shadow Hokage',
        //     content: 'Sasuke recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Kakashi',
        //     post: '6th Hokage',
        //     content: 'Kakashi recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // }
      ]


}
export const ncp = {
    heroSection:{
        title:'Nourishing families for more than 135 years',
        image:ncp1,
        bgImage:ncpBg
    },
    secondSection:{
        title:'Fresh Del Monte',
        subTitle:'Strategy, Branding, Website Design',
        description:'Driving innovation isn’t a buzzword, it’s a company tenet. We challenge ourselves to come up with new processes and products, and are constantly improving to satisfy our consumers’ tastes.',
        title1:'Approach',
        subTitle1:'Experience Strategy',
        description1:'Our goal was to create one cohesive platform that combines Fresh Del Monte’s strengths as a distribution and logistics company, community advocate, and a leader in sustainability. To achieve this, we aimed to change brand perception and be known for more than just pineapples and bananas. We also wanted to eliminate the GMO stigma and improve communication through this platform: a website that shows how the brand deeply understands their consumers’ needs and fulfills retailers’ curiosities.By positioning the brand as the true definition of goodness, we simultaneously communicate their differentiator—a leader that meets the highest standards across everything they do—and their ultimate goal of delivering “goodness” to all.'
    },
    thirdSection:{
        image1:[
           ncp2,ncp3,ncp4,ncp5
        ],
        image2:[
           ncp6,ncp7,ncp8,ncp9

        ]
    },
    fourthSection:{
        title:'Visual Language',
        description:'Apart from content, People, Planet, and Produce were also the three distinct pillars that guided Fresh Del Monte’s new visual language, which we brought to life using inspiring imagery and bright, vibrant colors.',
        image:[
            ncp10,ncp11,ncp12,ncp13

        ]
        

    },fifthSection:{
        images:[
            ncp14,ncp15,ncp16,ncp17,ncp18,ncp19
        ],
        title1:'Website Design',
        description1:'Apart from content, People, Planet, and Produce were also the three distinct pillars that guided Fresh Del Monte’s new visual language, which we brought to life using inspiring imagery and bright, vibrant colors.'
    },
    Testimonial:[
        // {
        //     name: 'Claudia Pou',
        //     post: 'VP, Global Head of Corporate Communications',
        //     content: 'I cannot recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Naruto',
        //     post: '7th Hokage',
        //     content: 'Naruto recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Sasuke',
        //     post: 'Shadow Hokage',
        //     content: 'Sasuke recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Kakashi',
        //     post: '6th Hokage',
        //     content: 'Kakashi recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // }
      ]

}
export const elixify = {
    heroSection:{
        title:'Your Beauty, Perfected with World-Class Expertise',
        image:elix1,
        bgImage:elixifyBg
    },
    secondSection:{
        title:'Elixify',
        subTitle:'Website Design',
        description:'Elixify is an exciting new venture dedicated to providing exquisite, high-end deliveries with a focus on unparalleled accuracy and precision. Our mission is to revolutionize the cosmetology industry by introducing innovative and quality methodologies.',
        title1:'Approach',
        subTitle1:'Experience Strategy',
        description1:'We positioned Elixify as an innovation leader, bridging technology and user needs with cutting-edge solutions and exceptional service. By reinforcing trust and transparency, the platform empowers stakeholders, enhances experiences, and drives seamless digital transformation.'
    },
    thirdSection:{
        image1:[
            elix2,elix3,elix4,elix5
        ],
        image2:[
            elix6,elix7,elix8,elix9

        ]
    },
    fourthSection:{
        title:'Visual Language',
        description:'Elixify’s visual identity reflects its commitment to innovation, efficiency, and digital excellence. Built on three core pillars—Technology, Transformation, and Trust—the brand’s design language incorporates dynamic visuals, modern aesthetics, and a seamless user experience to showcase its expertise in business and digital solutions.',
        image:[
            elix10,elix11,elix12,elix13

        ]

    },fifthSection:{
        images:[
            elix14,elix15,elix16,elix17,elix18,elix19
        ],
        title1:'Website Design',
        description1:'We designed a sleek, user-friendly platform that showcases Elixify’s innovation and expertise. With intuitive navigation and engaging visuals, the website enhances accessibility, builds trust, and positions Elixify as a leader in digital transformation.'

    },
    Testimonial:
    [
        // {
        //     name: 'Akila',
        //     post: 'Elixify',
        //     content: 'I cannot recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Naruto',
        //     post: '7th Hokage',
        //     content: 'Naruto recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Sasuke',
        //     post: 'Shadow Hokage',
        //     content: 'Sasuke recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Kakashi',
        //     post: '6th Hokage',
        //     content: 'Kakashi recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // }
      ]
    }
export const neuranest = {
    heroSection:{
        title:'Nourishing families for more than 135 years',
        image:new1,
        bgImage:neuranestBg
    },
    secondSection:{
        title:'Neuranest',
        subTitle:'Website Design',
        description:'NeuraNest is dedicated to transforming data into actionable insights, delivering future-ready products that stay ahead of trends, competition, and consumer expectations. By leveraging predictive AI, we empower businesses to discover winning products first, ensuring they remain at the forefront of their industries.',
        title1:'Approach',
        subTitle1:'Experience Strategy',
        description1:'We positioned NeuraNest as a leader in predictive AI, bridging the gap between complex data and actionable insights. The platform highlights NeuraNest’s expertise in delivering innovative solutions that empower businesses to stay ahead of industry trends.By reinforcing trust and leadership, we showcased NeuraNest as more than an AI provider—a transformative partner driving data-driven growth. The goal is to simplify decision-making, improve outcomes, and redefine business success through cutting-edge AI solutions.'
    },
    thirdSection:{
        image1:[
            new2,new3,new4,new5
           
        ],
        image2:[
           new6,new7,new8,new9

        ]
    },
    fourthSection:{
        title:'Visual Language',
        description:'The visual identity of NeuraNest is rooted in its mission to transform data into actionable insights and empower businesses with predictive AI solutions. The three pillars—Innovation, Precision, and Trust—serve as the foundation for the brand’s visual language, brought to life through futuristic design, clean aesthetics, and dynamic visuals.',
        image:[            
        new10,new11,new12,new13
        ]

    },fifthSection:{
        images:[
            new14,new15,new16,new17,new18,new19
        ],
        title1:'Website Design',
        description1:'We designed a sleek, user-friendly platform showcasing NeuraNest expertise in predictive AI and data insights. With intuitive navigation and engaging visuals, the website ensures easy access to solutions while building trust and credibility.The structured design highlights NeuraNest’s innovative approach, positioning it as a leader in AI technology and empowering businesses to drive growth and stay ahead.'
    },
    Testimonial:
    [
        // {
        //     name: 'Rahul',
        //     post: 'Neuranest',
        //     content: 'I cannot recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Naruto',
        //     post: '7th Hokage',
        //     content: 'Naruto recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Sasuke',
        //     post: 'Shadow Hokage',
        //     content: 'Sasuke recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Kakashi',
        //     post: '6th Hokage',
        //     content: 'Kakashi recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // }
      ]
    }
    

export const mycelium = {
    heroSection:{
        title:'Your Beauty, Perfected with World-Class Expertise',
        image:myc1,
        bgImage:mycBg
    },
    secondSection:{
        title:'Mycelium',
        subTitle:'Website Design',
        description:'MyCData is dedicated to providing real-time data access across enterprise applications and infrastructure through a comprehensive connectivity platform. By leveraging industry-leading data access and connectivity solutions, we empower businesses to streamline operations and make informed decisions, ensuring they remain competitive in their industries.',
        title1:'Approach',
        subTitle1:'Experience Strategy',
        description1:'We positioned MyCData as a leader in real-time data connectivity, enabling seamless integration across enterprise applications. The platform emphasizes innovation and empowers businesses to streamline operations, make data-driven decisions, and drive success with advanced connectivity solutions.'
    },
    thirdSection:{
        image1:[
           myc2,myc3,myc4,myc5
        ],
        image2:[
           
            myc6,myc7,myc8
        ]
    },
    fourthSection:{
        title:'Visual Language',
        description:'The visual identity of MyCData is rooted in its mission to simplify data integration, empower businesses, and drive seamless connectivity. The three pillars—Simplicity, Empowerment, and Innovation—serve as the foundation for the brand’s visual language, brought to life through clean design, intuitive visuals, and modern aesthetics.',
        image:[
            myc9,myc10,myc11,myc12

        ]
        
    },fifthSection:{
        images:[
            myc13,myc14,myc15,myc16,myc17,myc18
        ],
        title1:'Website Design',
        description1:'We created a sleek, user-friendly platform that highlights MyCData’s expertise in real-time data connectivity. With intuitive navigation and engaging visuals, the site ensures seamless access to solutions, reinforcing trust and industry leadership.'

    },
    Testimonial:
    [
        // {
        //     name: 'Sam',
        //     post: 'Mycelium',
        //     content: 'I cannot recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Naruto',
        //     post: '7th Hokage',
        //     content: 'Naruto recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Sasuke',
        //     post: 'Shadow Hokage',
        //     content: 'Sasuke recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Kakashi',
        //     post: '6th Hokage',
        //     content: 'Kakashi recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // }
      ]    
}

export const avanaSurgical = {
    heroSection:{
        title:'Enhancing Mobility, Strengthening Lives',
        image:av1,
        bgImage:avanaBg,
    },
    secondSection:{
        title:'Avana Surgical',
        subTitle:'Website Design',
        description:'OsteoKart is committed to enhancing musculoskeletal health by offering a range of supplements designed to support bone and joint function. By utilizing natural ingredients known for their efficacy, we aim to help individuals maintain mobility and overall well-being. Our products are formulated to promote the physiological health of cartilage and bones, ensuring users can lead active and healthy lives.',
        title1:'Approach',
        subTitle1:'Experience Strategy',
        description1:'We positioned OsteoKart as a trusted leader in musculoskeletal health, offering scientifically formulated supplements for bone and joint support. The platform highlights innovation and empowers individuals to maintain mobility, enhance well-being, and lead an active lifestyle with high-quality, effective solutions.'
    },
    thirdSection:{
        image1:[
           av2,av3,av4,av5
        ],
        image2:[
            av6,av7,av8

        ]
    },
    fourthSection:{
        title:'Visual Language',
        description:'The visual identity of OsteoKart is built on its mission to support musculoskeletal health, empower individuals, and promote overall well-being. The three pillars—Health, Strength, and Mobility—define the brand’s design language, brought to life through clean visuals, soothing colors, and a seamless user experience that reinforces trust and credibility in orthopedic care.',
        image:[
            av9,av10,av11,av12

        ]

    },fifthSection:{
        images:[
            av13,av14,av15,av16,av17,av18
        ],
        title1:'Website Design',
        description1:'We designed a clean, user-friendly platform that showcases OsteoKart’s commitment to musculoskeletal health. With intuitive navigation and engaging visuals, the site ensures seamless access to products and information, building trust and credibility in the wellness industry.'
    },
    Testimonial:[
        // {
        //     name: 'Claudia Pou',
        //     post: 'VP, Global Head of Corporate Communications',
        //     content: 'I cannot recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Naruto',
        //     post: '7th Hokage',
        //     content: 'Naruto recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Sasuke',
        //     post: 'Shadow Hokage',
        //     content: 'Sasuke recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // },
        // {
        //     name: 'Kakashi',
        //     post: '6th Hokage',
        //     content: 'Kakashi recommend 500 Designs highly enough. They are incredibly professional, as well as talented. I relied on them heavily for best practices and to take our website to the next level.',
        // }
      ]    
}
