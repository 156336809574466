import { Box, Typography } from '@mui/material'
import React from 'react'
import {bannerStyle,gradientTextStyle,abstact,circle,group} from './banner_style'

// import abstactImg from '../../../asset/images/teamAbstract.png'
// import circleImg from '../../../asset/images/teamCircle.png'
// import groupImg from '../../../asset/images/teamGroup.png'
// import backgroundImageUrl from '../../../asset/images/teamPlain.png'

import {Images} from '../../../asset/images';

import {} from '../../../asset/images/';

function TeamBanner() {
  return (
    <>
    
    <Box sx={{
    // background: `linear-gradient(
    //     126deg, 
    //     rgba(0, 0, 255, 0.8), 
    //     rgba(0, 0, 255, 0) 70.71%
    //   ), 
    //   linear-gradient(
    //     217deg, 
    //     rgba(0, 0, 0, 1), 
    //     rgba(0, 0, 0, 0.8) 70.71%
    //   ), 
    //   #0F0121`,
    background: 'rgb(8,0,139)',
    background: `radial-gradient(circle at top, rgba(8, 0, 139, 1) -25%, rgba(15, 1, 33, 1) 80%)`,
        color:'#fff',
        position:'relative',
        // backgroundImage: `url(${Images.OurTeamBg})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center', 
        // backgroundRepeat: 'no-repeat', 
        // backgroundColor:'red',
        
    }}>
        <Box sx={bannerStyle}>
             <Box sx={{
                 display:'flex',
                 justifyContent:'center',
                 flexDirection: 'column',
                 maxWidth:'715px',
                 display:'flex',
                 alignItems:'center',
                 textAlign:'center',
                 padding:'0 20px'
             }}>
                <Typography variant='h1' sx={gradientTextStyle}>
                HOW  Crew
                </Typography>
                <Typography variant='body1' sx={{
                    lineHeight:'24px',
                    fontWeight:'300',
                    fontFamily: 'Lufga',
                    maxWidth:'540px',
                    
                }}>
                At House of Websites, our team breathes life into your digital dreams, crafting stunning websites with passion, creativity, and an unwavering commitment to excellence. 
                </Typography>
             </Box>
            
        </Box>
            <Box sx={abstact}>
                <img src={Images.Abstract} alt='Abstract'/>
            </Box>
            <Box sx={circle}>
                <img src={Images.TeamCircle} alt='TeamCircle'/>
            </Box>
            <Box sx={group}>
                <img src={Images.TeamGroup} alt='TeamGroup' style={{
                    width:'100%',    
                }}/>
            </Box>
    </Box>
    </>
  )
}

export default TeamBanner
