export const imgConBg = {
    
    // margin: '0px 4rem 100px',
    borderRadius: '10px',
    padding: '55px 0',
    margin:'0 auto',
    maxWidth:'1400px',
    padding: '100px 7.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        padding: '70px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        padding: '40px 20px',
    },

}
export const imageSectionCase = {
    // margin: '0px 4rem 100px',
    padding:'100px 0',
    backgroundColor: '#E6E6E6',
    borderRadius:'7px'

}

export const imgCon1 = {
    maxWidth: '382px',
    flex: "0 0 auto",
    '@media (min-width: 320px) and (max-width: 600px)': {
        maxWidth: '182px',
    },
}

export const imgCon2 = {
    maxWidth: '486px', 
    flex: "0 0 auto",
    '@media (min-width: 320px) and (max-width: 600px)': {
        maxWidth: '286px', 
    },
}

export const imgConAlign1 = {
    paddingBottom: '53px',
    display: 'flex',
    gap: '30px',
    overflow: "hidden",
}

export const imgConAlign2 = {
    display: 'flex',
    gap: '50px',
    overflow: "hidden",
}