import { styled } from '@mui/material/styles'
import { Box, Button, Card, Typography } from '@mui/material'

export const BlogContainerSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#0F0121',
    padding: '6.25rem 0',
    maxWidth: '1296px',
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: { padding: '4rem 0' },

}))

export const BlogsText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Dystopian',
    fontWeight: 700,
    fontSize: '80px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingBottom:'4.6875rem',
    [theme.breakpoints.down('sm')]: { fontSize: '64px',paddingBottom:'3rem' },

}))

// export const BlogCardContainer =styled(Card)(({theme}) => ({
//     background: '#0F0121',
//     color: '#FFFFFF',
//     border: '2px solid transparent', // Set border to transparent to use border-image
//     borderImage: 'linear-gradient(270deg, #3EC5FF 0%, #00E5FD 100.61%, rgba(62, 197, 255, 0) 73.61%)',
//     borderImageSlice: 1,
//     borderRadius: '20px',

// }))

export const GradientBorderWrapper = styled('div')({
    borderRadius: '20px',
    background:
        'linear-gradient(#0F0121, #0F0121) padding-box, ' +
        'linear-gradient(270deg, #3EC5FF 0%, #00E5FD 100.61%, rgba(62, 197, 255, 0) 73.61%) border-box',
    border: '2px solid transparent',
    padding: '20px',
    boxSizing: 'border-box',
})

export const ContentContainer = styled(Card)(({ theme }) => ({
    maxWidth: '412px',
    width: '100%',
    backgroundColor: '#0F0121',
    padding: 0,
}))

export const DesignReadStyle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Lufga',
    fontSize: '14px',
    color: '#FEC61D',
    fontWeight: '200',
    padding: '10px 0 20px 0',
}))

export const BlogTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Lufga',
    fontSize: '20px',
    fontWeight: '500',
    color: '#FFFFFF',

}))

export const BlogDescription = styled(Typography)(({ theme }) => ({
    fontFamily: 'Lufga',
    fontSize: '14px',
    fontWeight: '300',
    color: '#AAAAAA',
    padding: '8px 0 25px 0',

}))

export const BlogContentDateName = styled(Typography)(({ theme }) => ({
    color:'#DDDDDD',
    fontFamily: 'Lufga',
    fontSize: '14px',
    fontWeight: '300',
    [theme.breakpoints.down('sm')]: { fontSize:'12px' },


}))