import { Box } from '@mui/material'
import React from 'react';
import gradient1 from '../../../asset/images/caseStudy/gradientBg1.png'
import gradient2 from '../../../asset/images/caseStudy/gradientBg2.png'
import gradient3 from '../../../asset/images/caseStudy/gradientBg3.png'
import gradient4 from '../../../asset/images/caseStudy/gradientBg4.png'
import CaseStudyDetail from './CaseStudyDetail';
import elixifyBanner from '../../../asset/images/caseStudy/elixifyBannerImg.png'
import avanaBanner from '../../../asset/images/caseStudy/avanaBannerImg.png'
import newBanner from '../../../asset/images/caseStudy/neuranestBannerImg.png'
import mycBanner from '../../../asset/images/caseStudy/mycBannerImg.png'
import vivartaBanner from '../../../asset/images/caseStudy/vivarta_caseStudy.png';
import { Link } from 'react-router-dom';



const CategoriesWorks = () => {
    return (
        <Box sx={{

        }}>
            <Box

                sx={{
                    maxWidth: '1296px',
                    margin: '0 auto',
                    padding: '100px 4.5rem',
                    '@media (min-width: 601px) and (max-width: 900px)': {
                        padding: '70px 2rem',
                    },
                    '@media (min-width: 320px) and (max-width: 600px)': {
                        padding: '40px 20px',
                    },
                }}>

                <Box>
                    <h2 style={{    fontFamily:'Dystopian',fontSize:'58px'}}>
                        Website Design
                    </h2>
                    <Box sx={{ display: 'flex', flexDirection: 'column',gap:'30px' }}>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between',gap:'20px',flexDirection: { xs: "column", md: "row" }, }}>


                            <Box >
                                <Box sx={{
                                    backgroundImage: `url(${gradient1})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    maxWidth: '600px',
                                    
                                }}>
                                    <Link to={'/casestudy/avana'}><img src={avanaBanner} style={{ width: '100%' }} /></Link>
                                </Box>
                                <h2 style={{   fontFamily:'lufga',}}>Avana Surgical</h2>
                                <p style={{   fontFamily:'lufga',}}>Website Design, E-commerce</p>
                            </Box>

                            <Box>
                                <Box sx={{
                                    backgroundImage: `url(${gradient2})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    maxWidth: '600px',
                                    
                                }}>
                                    
                                    <Link to={'/casestudy/Mycelium'}><img src={mycBanner} style={{ width: '100%' }} /></Link>
                                </Box>
                                <h2 style={{   fontFamily:'lufga',}}>Mycelium</h2>
                                <p style={{   fontFamily:'lufga',}}>Website Design</p>
                            </Box>


                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between',gap:'20px',flexDirection: { xs: "column", md: "row" }, }}>


                            <Box >
                                <Box sx={{
                                    backgroundImage: `url(${gradient3})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    maxWidth: '600px',
                                    
                                }}>
                                   <Link to={'/casestudy/neuranest'}> <img src={newBanner} style={{ width: '100%' }} /></Link>
                                </Box>
                                <h2 style={{   fontFamily:'lufga',}}>Neuranest</h2>
                                <p style={{   fontFamily:'lufga',}}>Website Design</p>
                            </Box>



                            <Box>
                                <Box sx={{
                                    backgroundImage: `url(${gradient4})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    maxWidth: '600px',
                                    
                                }}>
                                    
                                    <Link to={'/casestudy/elixify'}><img src={elixifyBanner} style={{ width: '100%' }} /></Link>
                                </Box>
                                <h2 style={{   fontFamily:'lufga',}}>Elixify</h2>
                                <p style={{   fontFamily:'lufga',}}>Website Design</p>
                            </Box>


                        </Box>


                    

                    </Box>
                </Box>


            </Box>
        </Box>
    )
}

export default CategoriesWorks
