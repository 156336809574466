import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SingleBlog = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        const url =(`http://localhost/houseofwebsite/backend/wp-json/wp/v2/posts?slug=${slug}`)
        axios.get(url).then((res) => {
            setPost(res.data[0]); // Get the first post that matches the slug
        });
    }, [slug]);

    console.log(post,"posttt-single");
    

    if (!post) return <p>Loading...</p>;

    // Elementor HTML content from WordPress
    const postContent = post.content.rendered;

    const featuredImage = post._embedded?.['wp:featuredmedia']?.[0]?.source_url;

    return (
        <div className="single-post-container">
            {/* <img src={featuredImage} alt={post.title.rendered} />

            <h1 className='text-lg font-bold'>{post.title.rendered}</h1>
            <h1>Blog ....</h1> */}

            <div className="elementor-content" dangerouslySetInnerHTML={{ __html: postContent }}></div>
        </div>
    );
};

export default SingleBlog;
