// import React from 'react'
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { AccordContent, AccordDes, AccordSum, AccordTitle } from './serviceAccord_style';
import { Images } from '../../../asset/images';
import { ServiceAccord } from '../../../constants';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,

    backgroundColor: '#070707',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={null}
        {...props}
    />
))(({ theme }) => ({
    color: '#fff',
    padding: '32px 0',
    borderBottom: '1px solid #777777',
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },

    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding:'20px 0',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function ServiceAccordian() {




    const [expanded, setExpanded] = React.useState('panel1');
    const [icon, setIcon] = React.useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    return (
        <>
            <Box sx={{ backgroundColor: '#070707' }}>
                <Box sx={{ maxWidth: '1296px', margin: '0 auto', padding: { md: '100px 72px', sm: '50px 2rem', xs: '50px 20px' } }}>


                <Box sx={{
                        display: 'flex', justifyContent: 'space-between', padding: { md: '0px 0 60px 0', xs: '0px 0 40px 0' }, gap: '20px', '@media (max-width:1100px)': {
                            flexDirection: 'column',color:'#fff',
                        }
                    }}>
                        <Typography variant='h2' sx={{ fontSize: '100px',color:'#fff', '@media (max-width:1250px)': { fontSize: '80px' }, '@media (max-width:1200px)': { fontSize: '60px' }, '@media (max-width:600px)': { fontSize: '36px' } }} >
                        Our 
                        Services
                        </Typography>
                        <Typography sx={{ maxWidth: '700px',color:'#fff', fontSize: '22px', fontFamily: 'Lufga', fontWeight: '500', display: 'flex', alignItems: 'center', '@media (max-width:600px)': { fontSize: '18px' } }}>
                        At House of Websites, we are driven by integrity, creativity, and excellence. We believe in building lasting relationships, fostering innovation, and delivering impactful solutions that resonate with both our clients and the world.
                        </Typography>
                    </Box>



                    {ServiceAccord.map((item) =>

                        <Accordion key={item.uni} expanded={expanded === item.panal} onChange={handleChange(item.panal)}>

                            <AccordionSummary aria-controls={item.arial} id={item.id} onClick={() => { if(item.panal == icon){setIcon('')}else{setIcon(item.panal)} }}>

                                <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', width: '100%' }}>

                                    <Box sx={{ maxWidth: '150px','@media (max-width:500px)':{display:'none'} }}><img src={item.images} style={{ maxWidth: '100%' }} /></Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'end' }}>
                                        <Typography variant='h5' sx={{fontWeight:'700','@media (max-width:500px)':{fontSize:'20px'}}}>{item.service}</Typography>
                                        {icon == item.panal ? <RemoveIcon /> : <AddIcon />}
                                    </Box>

                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={AccordContent}>

                                    <Typography sx={{ color: '#f2f2f2', maxWidth: '983px', fontFamily: 'Lufga', fontSize: { md: '22px', sm: '18px' }, fontWeight: 500 }}>
                                        {item.content}
                                    </Typography>

                                    <Box sx={AccordSum}>
                                     
                                        {
                                            item.temp.map((data) => (
                                                <Box      key={data.title}
                                                sx={{
                                                  flexBasis: 'calc(50% - 15px)', // Two items per row with equal width
                                                  maxWidth: '400px',
                                                  margin: '25px 0',
                                                  "@media (max-width:768px)": {
                                                    flexBasis: 'calc(50% - 10px)', // Adjust for smaller screens, two per row
                                                  },
                                                  "@media (max-width:500px)": {
                                                    flexBasis: '100%', // Full width on small screens (stacked layout)
                                                    margin: '10px 0',
                                                  },
                                                }}>
                                                    <Typography variant='h6' sx={AccordTitle}>
                                                        {data.title}
                                                    </Typography>
                                                    <Typography variant='body1' sx={AccordDes}>
                                                        {data.description}
                                                    </Typography>
                                                </Box>
                                            ))
                                        }
                                 
                                    </Box>


                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )}

                </Box>
            </Box>
        </>
    )
}

export default ServiceAccordian
