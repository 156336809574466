

export const ServicesTable = {
    display:'flex',
    justifyContent:'space-evenly',
        fontWeight:'700',
    fontSize:'64px',

}

export const AccordTitle = { color: '#fff', marginBottom:'16px',fontWeight:'700',fontSize:'24px','@media (max-width:600px)':{fontSize:'20px'}}
export const AccordDes = { color: '#AAAAAA', fontFamily: 'Lufga',fontSize:'20px','@media (max-width:600px)':{fontSize:'16px'} }

export const AccordContent = {
    paddingLeft:'150px',
    '@media (max-width:1170px)':{
        paddingLeft:'0',
    }
}

export const AccordSum = {
     display: 'flex', 
        columnGap: '80px', 
        '@media (max-width:1210px)':{columnGap:'40px'},
        '@media (max-width:1070px)':{columnGap:'20px'},
        '@media (max-width:768px)': { flexDirection: 'column' },
         maxWidth: '1083px', 
         flexWrap: 'wrap' 

}