

export const ServicesTab = {
    display:'flex',
    justifyContent:'space-evenly',
        fontWeight:'700',
    fontSize:'64px',

}

export const TabTitle = { color: '#fff', marginBottom:'16px'}
export const TabDes = { color: '#AAAAAA', fontFamily: 'Lufga' }