import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    LinearProgress,
    Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Images } from '../../../asset/images'
import {
    BorderLinearProgress,
    LandingAccordionContainer,
} from '../landing.style'
import { accordionData } from '../../../constants'
import anime from 'animejs/lib/anime.es.js'
import '../bubble/Bubble.css'

function LandingAccordion() {
    const textRef = useRef(null)
    const sectionRef = useRef(null)
    const [hasAnimated, setHasAnimated] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !hasAnimated) {
                    const textWrapper = textRef.current

                    if (textWrapper) {
                        textWrapper.innerHTML = textWrapper.textContent.replace(
                            /\S/g,
                            "<span class='letter'>$&</span>"
                        )

                        const letters = textWrapper.querySelectorAll('.letter')

                        const animation = anime
                            .timeline({ loop: false })
                            .add({
                                targets: letters,
                                translateY: ['1.1em', 0],
                                translateZ: 0,
                                duration: 750,
                                delay: (el, i) => 50 * i,
                            })
                            .add({
                                targets: textWrapper,
                                opacity: [1, 1], 
                                duration: 1000,
                                easing: 'easeOutExpo',
                                delay: 1000, 
                                complete: () => {
                                    setHasAnimated(true)
                                },
                            })

                        return () => animation.pause()
                    }

                    observer.disconnect()
                }
            },
            {
                threshold: 0.1, 
            }
        )

        if (sectionRef.current) {
            observer.observe(sectionRef.current)
        }

        return () => observer.disconnect()
    }, [hasAnimated])

    const [expanded, setExpanded] = useState('panel1')

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Box
            sx={{
                background: '#0F0121',
                color: 'white',
                padding: {
                    // lg:'4rem',
                    md: '10.0625rem 4.5rem 4rem 4.5rem',
                    sm: '5rem 2rem 2rem 2rem',
                    xs: '1.25rem',
                },
            }}
        >
            <Box sx={{
                  maxWidth: '1296px',
                  margin: '0 auto',
            }}>
            <Box ref={sectionRef}>
                <Typography
                    className="ml6"
                    sx={{
                        fontSize: {
                            md: '64px',
                            sm: '50px',
                            xs: '30px',
                        },
                        fontWeight: 700,
                    }}
                >
                    <span className="text-wrapper">
                        <span
                            ref={textRef}
                            className={`letters ${
                                hasAnimated ? 'animated' : ''
                            }`}
                            style={{
                                color: 'white',
                                opacity: hasAnimated ? 1 : 0,
                            }}
                        >
                            Our Expertise
                        </span>
                    </span>
                </Typography>
            </Box>
            {accordionData.map(
                ({
                    panel,
                    title,
                    content,
                    image,
                    progressValue,
                    height,
                    topImage,
                    topPara,
                }) => (
                    <React.Fragment key={panel}>
                        <LandingAccordionContainer
                            expanded={expanded === panel}
                            onChange={handleChange(panel)}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <Box
                                        component="img"
                                        alt='AccordionArrowIcon'
                                        src={Images?.AccordionArrowIcon}
                                        sx={{
                                            width: {
                                                lg: 'auto',
                                                md: 'auto',

                                                xs: '30px',
                                            },
                                            // height: {
                                            //     lg: 'auto',
                                            //     // sm: '40px',
                                            //     xs: '40px',
                                            // },
                                        }}
                                    />
                                }
                                aria-controls={`${panel}-content`}
                                id={`${panel}-header`}
                                sx={{
                                    '& .MuiAccordionSummary-content': {
                                        margin: '2rem 0',
                                    },
                                    padding: {
                                        lg: '0 4rem 0 1.5rem',
                                        md: '0',
                                        // sm: '0',
                                        // xs: '0',
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        // width: '33%',
                                        flexShrink: 0,
                                        color:
                                            expanded === panel
                                                ? 'rgba(0, 229, 253, 1)'
                                                : 'rgba(255, 255, 255, 1)',
                                        fontSize: {
                                            xs: '20px', 
                                            sm: '32px', 
                                            md: '40px',  
                                            lg: '48px', 
                                           
                                        },
                                        fontWeight: 500,
                                        fontFamily: 'Lufga',
                                    }}
                                >
                                    {title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    
                                    gap: {
                                      
                                        sm: '1rem',
                                        xs: '1rem',
                                    },
                                    // flexWrap: 'wrap',
                                    padding: {
                                        lg: '0 4rem 0 1.5rem',
                                        md: '0',
                                        
                                        sm: '0',
                                        xs: '0',
                                    },
                                    height: {
                                        lg: height,
                                        md: height,
                                        sm: '350px',
                                        xs:'430px'

                                    },
                                    justifyContent: {
                                        sm: 'start',
                                        md: 'space-between',
                                        xs: 'center',
                                    },
                                    flexWrap:{
                                        lg:'wrap',
                                        md:'nowrap',
                                        sm:'wrap',
                                        xs:'wrap'
                                    },
                                    marginRight: {
                                        lg: '7rem',
                                        md:'4rem'
                                    },
                                    // '@media (max-width: 1260px) and (min-width: 1200px)':
                                    //     {
                                    //         gap: '5rem',
                                    //     },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Lufga',
                                        fontSize: '18px',
                                        fontWeight: '300',
                                        position: {
                                            lg: 'relative',
                                            md: 'static',
                                        },
                                        top: {
                                            lg: topPara,
                                            md: topPara,
                                        },
                                        width: {
                                            lg: '50%',
                                            md:'50%',
                                            xs:'100%',
                                            sm: '100%',
                                        },
                                    }}
                                >
                                    {content}
                                </Typography>
                          

                            <Box
                                    component="img"
                                    alt={title}
                                    src={image}
                                    sx={{
                                        position: {
                                            lg: 'relative',
                                            md: 'relative',
                                        },

                                        top: {
                                            lg: topImage,
                                            md: '-50px',
                                        },
                                        width: {
                                            lg:'250px',
                                            md:'250px',
                                            sm:'250px',
                                            xs: '240px',
                                        },

                                        transform:'rotate(-15deg)',
                                        bottom:'-10px'
                                    }}
                                />
                            </AccordionDetails>
                        </LandingAccordionContainer>
                        <BorderLinearProgress
                            variant="determinate"
                            value={progressValue}
                            customColor={
                                expanded === panel
                                    ? 'rgba(0, 229, 253, 1)'
                                    : 'rgba(56, 55, 55, 1)'
                            }
                        />
                    </React.Fragment>
                )
            )}

            </Box>
        </Box>
    )
}

export default LandingAccordion
