
export const projectContentContainer = {
    padding: '100px 7.5rem 50px 7.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        // padding: '70px 2rem',
        padding: '70px 2rem 50px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        // padding: '40px 20px',
        padding: '20px 20px 50px 20px',
    },
    margin:'0 auto',
    maxWidth:'1400px',

}
export const projectContentOuter = {
    maxWidth:'1400px',
    margin:'0 auto',
    padding: '100px 7.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        padding: '70px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        padding: '40px 20px',
    },
}

export const projectTitle = {
    fontWeight: '700px',
    fontSize: '40px',
    paddingBottom: '24px',
    '@media (min-width: 320px) and (max-width: 600px)': {
        fontSize: '32px',
    },
}

export const projectContent = {
    fontSize: '18px',
    fontFamily: 'Lufga',
    fontWeight: '300',
}
export const projectContain = {
    maxWidth:'1400px',
    margin:'0 auto',
    padding: '100px 7.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        padding: '70px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        padding: '40px 20px',
    },
}

export const projectImgBg = {
    backgroundColor: '#515151',
    borderRadius: '10px',
}

export const gridContainer = {
    display: 'flex',
    gap: '20px',
    maxWidth: '1157px',
    margin:'0 auto',
    '@media (min-width: 320px) and (max-width: 900px)': {
        gap: '10px',
    },
    '@media (min-width: 320px) and (max-width: 600px)': {
        flexDirection: 'column',
    },
}

export const gridFirstCon = {
    maxWidth: '637px',
    maxHeight: '440px',
}

export const gridSecCon = {
    display: 'grid',
    maxWidth: '500px',
    gap: '20px',
    '@media (min-width: 320px) and (max-width: 900px)': {
        gap: '10px',
    },
}

export const gridSecImgCon = {
    maxHeight: '210px',
}