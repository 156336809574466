import { Height, Margin } from "@mui/icons-material";
import { Images } from "../../../asset/images";
import { private_excludeVariablesFromRoot } from "@mui/material";

export const bg = {
    background: `url(${Images.Grid})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    // maxWidth:'1400px',
    // margin:'0 auto',
    // padding: '100px 7.5rem',
    // '@media (min-width: 601px) and (max-width: 900px)' : {
    //     padding: '70px 2rem',
    // },
    // '@media (min-width: 320px) and (max-width: 600px)' : {
    //     padding: '40px 20px',
    // },
}
export const caseStudyBg = {
    maxWidth:'1400px',
    margin:'0 auto',
    padding: '100px 7.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        padding: '70px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        padding: '40px 20px',
    },
}
export const heading = {
    fontSize: '40px',
    fontWeight: '700',
    paddingBottom: '16px',
    '@media (min-width: 320px) and (max-width: 600px)' : {
        fontSize: '32px',
    },
}

export const secondHead = {
    fontFamily: 'Lufga',
    paddingBottom: '32px',
    fontSize: '18px',
}

export const secondSecContent = {
    maxWidth: '951px',
    fontWeight: '500',
    fontSize: '24px',
    fontFamily: 'Lufga',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        fontSize: '20px',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        fontSize: '18px',
    },
}

export const secondSecContent2 = {
    maxWidth: '951px',
    fontWeight: '300',
    fontSize: '18px',
    fontFamily: 'Lufga',
    lineHeight: '35px !important'
    
}