import Houseofwebsites from '../images/Houseofwebsites.webp'
import HOWLogo from '../svg/HowLogo.svg'
import ArrowIcon from '../svg/ArrowIcon.svg'
import HOWBanner from './HomeBannerWeb.webp'
import HOW_VIDEO from '../video/HowMainVideoC.mp4'
import AccordionArrowIcon from '../svg/AccordionArrowIcon.svg'

import Branding from '../images/BrandingAccordion.webp'
import DesignAndDevelopment from '../images/DesignDevelopment.webp'
import SEO from '../images/SEO.webp'
import Ecommerce from '../images/ECommence.webp'

import UpComma from './UpComma.webp'
import DownComma from './DownComma.webp'
import MaskGroup from './MaskGroup.webp'

import ViewProjects from '../images/ViewProject.webp'
import LandingProject1 from '../images/NCPProject.webp'
import LandingProject2 from '../images/AvarthaProject.webp'
import LandingProject3 from '../images/CKProject.webp'
import LandingProject4 from '../images/Paradham.webp'
import LandingProject5 from '../images/MyceliumProject.webp'
import SeeAllProject from '../images/SeeAllProjects.webp'
import LandingProjectPattern from '../images/Pattern.webp'
import ViewProject from '../images/ViewProject.webp'
import SeeAllProjectArrow from '../images/SeeAllProjectArrow.svg'
import WorkbannerBG from './WorkbannerBG.webp'
import WorkOverlayImg from './Work-overlayImg.webp'
import WorkHeroImg from './Workherobg_com.webp'
import WorkCompImg from './Work-computer.webp'
import ReadImg from './read icon.webp'
import SelectWork from './Rectangle 299.webp'
import Identity from './Identity.webp'
import ApplicationDesign from './Image 248.webp'
import PackingDesign from './Packing design.webp'
import WebsiteDesign from './websiteedesign.webp'
import WebsiteDesign2 from './Website2.webp'
import WorkContact from './Contactus.webp'
import SideArrow from './Work_sidearrow.webp'
import Line from './Line 30.webp'
import Messages from './Messages.webp'
import GetNewTechnlogy from './GetNewTechnology.webp'
import LetsTalk from '../svg/LetsTalkIcon.svg'
import FooterIcon from '../svg/FooterLogo.svg'
import CareerBanner from './CareerBannerBg.webp'
import Innovation from './Innoviation.webp'
import Excelence from './Excellence.webp'
import Empathy from './Empathy.webp'
import Colabrate from './Collaboration.webp'
import Adoptablity from './Adaptability.webp'
import Acountablity from './Accountability.webp'
import Location from '../svg/Location.svg'
import Search from '../svg/Search.svg'
import RightArrow from '../svg/arrow-right.svg'
import LocationWhite from '../svg/LocationWhite.svg'

import BoxDesign from './boxDesign.webp'
import Boxses from './boxses.webp'
import Cubeblur from './cubeblur.webp'
import Cubes from './cubes.webp'
import Doublecircle from './doublecircle.webp'
import Greenblur from './green blur.webp'
import OurTeamBg from './ourTeambg.webp'
import Purpleblur from './purpleblur.webp'
import Purpleblurbig from './purpleblurbig.webp'
import Abstract from './teamAbstract.webp'
import TeamCircle from './teamCircle.webp'
import TeamGroup from './teamGroup.webp'

import CareersDetialsBlur from './Ellipse 1.webp'
import CDArrow from './cd-arrow.svg'
import Bookmark from './Bookmark.svg'
import Share from './Share.svg'
import Experience from './Experience.svg'
import JobType from './JobType.svg'
import CDLocation from './CDLocation.svg'
import Rupee from './rupee.svg'

//ContactUs
import Eugene1 from './eugene1.webp'
import Linkedin from './linkedin.webp'
import MailThat from './mail.webp'
import PhoneCall from './phone.webp'
import ContactBg from './contactbg.webp'
import DownArrow from './down-arrow.webp'
import Automatic from './AutomaticLogo.webp'
import Wordpress from './wordpress-logo.svg fill.webp'
import Interpre from './interprefy-logo.svg fill.webp'
import InterPlayer from './players-health-logo.svg fill.webp'
import BlockWorks from './BlockworksLogo.webp'
import NewNew from './newnew-logo.svg.png'
import Video from './video.webp'
// import AvatarUser from "./AvatarUser.png";
import SearchWhat from './search.webp'
import StepMail from './step-mail.webp'
import contactbg1 from './contactbg1.svg'
import contactbg2 from './contactbg2.svg'
import AvatarUser from './AvatarUser.webp'
import footerbg from './footerbg.webp'
import contactbannerbg from './contactbannerbg.webp'

import SlideImg1 from './SlideImg1.webp'
import SlideImg2 from './SlideImg2.webp'
import SlideImg3 from './SlideImg3.webp'
import SlideImg4 from './SlideImg4.webp'
import SlideImg5 from './SlideImg5.webp'
import EmpProfile1 from './EmpProfile1.webp'
import EmpProfile2 from './EmpProfile2.webp'
import EmpProfile3 from './EmpProfile3.webp'
import EmpProfile4 from './EmpProfile4.webp'
import EmpProfile5 from './EmpProfile5.webp'
import EmpProfile6 from './EmpProfile6.webp'
import EmpProfile7 from './EmpProfile7.webp'
import QuoteImg from './QuoteImg.webp'
import CareerVideo from '../video/CareerBgVideo.mp4'

import ContactImg from './contactPerson.webp'

import ContactSVG from './contact-circle-img.webp'
import ContactMail from './Contact-mail-svg.svg'
import ContactPhone from './Contact-phone-svg.svg'
import ContactArrow from './Contact-Arrow.svg'
import ContactBlue from './contact-blue-blur.webp'
import ContactGreen from './contact-green-blur.webp'
import ContactLine from './contact-blurline.webp'

import DirectorImg from './Usha.webp'
import DirectorComma from '../svg/DirectorComma.svg'

import AboutusEarth from './Earth.webp'
import BlueBanner from '../images/Second.webp'
import OurStoryTemple from './OurStoryTemple.webp'
import GlobalAnimation from '../video/GlobeAnimation.gif'

import Dna from './Animaed.webp'
import Grp from './Grp_pht.webp'
import OurvalueBg from './our_values-bg.webp'
import OurRm from './our_RM.webp'
import Our_bg_border from './our_bg_border.webp'
import ourAwarness from './Awareness_our.svg'
import OurPeople from './People_our.svg'
import OurFlag from './Flag_our.svg'
import ContactIn from '../svg/ContactLinkdin.svg'
import Dnaroate from '../video/DNA Animation.gif'
import SignleLine from '../video/Strand.gif'

import ContactSridhar from './sridhar-con.webp'
import ContactVishal from './vishal-con.webp'
import Samasta from './samasta.webp'
import NCP_Tech from './NCp_TechStack.webp'
import CK_Tech from './CK_TechStack.webp'
import Jangoz_Tech from './Jangoz_TechStack.webp'
import Wordpress_Techstack from './Wordpress_Techstack.webp'
import Shopify_logo from './Shopify_logo.webp'

import SuccessIcon from '../svg/SuccessIcon.svg'

import ServiceBannerImg from './serviceBanner.webp'

import DiscoverImg from './discoverImg.webp'
import DefineImg from './defineImg.webp'
import DesignImg from './designImg.webp'
import DevelopImg from './developImg.webp'
import DeliveryImg from './deliveryImg.webp'

import BlogContent1 from './BlogContent2.webp'
import BlogContent2 from './BlogContent3.webp'
import BlogContent3 from './BlogContent.webp'
import BlogProfile from './BlogProfile.webp'

import AccordBlue from './AccordBlue.webp'
import AccordGreen from './AccordGreen.webp'
import AccordGrey from './AccodGrey.webp'
import AccordPurple from './AccordPurple.webp'
import AccordRed from './AccordRed.webp'

//case study
import Grid from './Grid-1.png';
import VisualLang1 from './caseStudy/visuallang1.png'
import VisualLang2 from './caseStudy/visuallang2.png'
import VisualLang3 from './caseStudy/visuallang3.png'
import VisualLang4 from './caseStudy/visuallang4.png'
import CaseStudyImg1 from './caseStudy/caseStudyImg1.png'
import CaseStudyImg2 from './caseStudy/caseStudyImg2.png'
import CaseStudyImg3 from './caseStudy/caseStudyImg3.png'
import CaseStudyImg4 from './caseStudy/caseStudyImg4.png'
import CaseStudyImg21 from './caseStudy/caseStudyImg21.png'
import CaseStudyImg22 from './caseStudy/caseStudyImg22.png'
import CaseStudyImg23 from './caseStudy/caseStudyImg23.png'
import Project1 from './caseStudy/project1.png'
import Project2 from './caseStudy/project2.png'
import Project3 from './caseStudy/project3.png'
import Project4 from './caseStudy/project4.png'
import Project5 from './caseStudy/project5.png'
import Project6 from './caseStudy/project6.png'

export const Images = {
    //home page images
    Houseofwebsites,
    HOWLogo,
    ArrowIcon,
    HOWBanner,
    HOW_VIDEO,
    AccordionArrowIcon,
    Branding,
    DesignAndDevelopment,
    SEO,
    Ecommerce,
    ViewProjects,
    LandingProject1,
    LandingProject2,
    LandingProject3,
    LandingProject4,
    LandingProject5,
    SeeAllProject,
    LandingProjectPattern,
    ViewProject,
    SeeAllProjectArrow,
    Messages,

    GetNewTechnlogy,

    LetsTalk,
    FooterIcon,

    UpComma,
    DownComma,

    MaskGroup,

    //Workpage Images
    WorkbannerBG,
    WorkOverlayImg,
    WorkHeroImg,
    WorkCompImg,
    ReadImg,
    SelectWork,
    Identity,
    ApplicationDesign,
    PackingDesign,
    WebsiteDesign,
    WebsiteDesign2,
    WorkContact,
    SideArrow,
    Line,
    Samasta,
    NCP_Tech,
    Jangoz_Tech,
    CK_Tech,
    Wordpress_Techstack,
    Shopify_logo,

    // our team images

    BoxDesign,
    Boxses,
    Cubeblur,
    Cubes,
    Doublecircle,
    Greenblur,
    OurTeamBg,
    Purpleblur,
    Purpleblurbig,
    Abstract,
    TeamCircle,
    TeamGroup,

    //Contact US images
    Eugene1,
    Linkedin,
    MailThat,
    PhoneCall,
    ContactBg,
    DownArrow,
    Automatic,
    Wordpress,
    Interpre,
    InterPlayer,
    BlockWorks,
    NewNew,
    Video,
    // ContactWhat,
    SearchWhat,
    StepMail,
    contactbg1,
    contactbg2,
    AvatarUser,
    footerbg,
    contactbannerbg,

    //Career Images
    CareerBanner,
    Innovation,
    Excelence,
    Empathy,
    Colabrate,
    Adoptablity,
    Acountablity,
    Location,
    Search,
    RightArrow,
    LocationWhite,
    SlideImg1,
    SlideImg2,
    SlideImg3,
    SlideImg4,
    SlideImg5,
    EmpProfile1,
    EmpProfile2,
    EmpProfile3,
    EmpProfile4,
    EmpProfile5,
    EmpProfile6,
    EmpProfile7,
    QuoteImg,
    CareerVideo,

    //Careers-Details
    CareersDetialsBlur,
    CDArrow,
    Bookmark,
    Share,
    Experience,
    Rupee,
    JobType,
    CDLocation,

    //About us

    AboutusEarth,
    BlueBanner,
    OurStoryTemple,

    GlobalAnimation,

    //contact image

    ContactImg,
    ContactSVG,
    ContactMail,
    ContactPhone,
    ContactArrow,
    ContactBlue,
    ContactGreen,
    ContactLine,
    ContactIn,
    ContactSridhar,
    ContactVishal,

    //about us

    DirectorImg,
    DirectorComma,

    // About us Images
    Dna,
    Grp,
    OurvalueBg,
    OurRm,
    OurFlag,
    OurPeople,
    ourAwarness,
    Our_bg_border,
    SignleLine,
    Dnaroate,

    SuccessIcon,

    //service page

    ServiceBannerImg,
    DiscoverImg,
    DefineImg,
    DesignImg,
    DevelopImg,
    DeliveryImg,

    BlogContent1,
    BlogContent2,
    BlogContent3,

    BlogProfile,

    AccordBlue,
    AccordGreen,
    AccordGrey,
    AccordPurple,
    AccordRed,


    //case study
    Grid,
    VisualLang1,
    VisualLang2,
    VisualLang3,
    VisualLang4,
    CaseStudyImg1,
    CaseStudyImg2,
    CaseStudyImg3,
    CaseStudyImg4,
    CaseStudyImg21,
    CaseStudyImg22,
    CaseStudyImg23,
    Project1,
    Project2,
    Project3,
    Project4,
    Project5,
    Project6,
}
