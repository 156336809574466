import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { TabDes, TabTitle } from '../servicesTabStyle'

function DesginTab() {

  let temp1 = [
    {
        title: 'Creative Vision',
        description: 'Our design process starts with understanding your brand’s identity and crafting visuals that reflect it.',
    },
    {
        title: 'User Experience Focus',
        description: 'We prioritize seamless and intuitive design, ensuring an engaging user experience from start to finish.',
    },
    {
        title: 'Responsive Design',
        description: 'Every design adapts fluidly across devices, ensuring optimal viewing on mobile, tablet, and desktop.',
    },

]

let temp2 = [
    {
        title: 'Brand Consistency',
        description: 'We ensure your design elements align with your brand, maintaining consistency across all digital platforms.',
    },
    {
        title: 'Aesthetic Precision',
        description: 'Attention to detail is key, with every pixel and color chosen carefully to reflect your brand’s elegance.',
    },
    {
        title: 'Innovative Interfaces',
        description: 'We push boundaries with modern, interactive designs that captivate users and elevate the digital experience.',
    },
]
return (
    <>
        <Box sx={{}}>
            <Typography sx={{ color: '#fff', maxWidth: '750px', fontFamily: 'Lufga' ,fontSize:{md:'22px',sm:'18px'},fontWeight:500}}>
            Our design process at House of Websites focuses on creativity and user experience. We craft visually compelling, intuitive websites that resonate with your audience while maintaining brand consistency and ensuring a seamless experience across all devices.
            </Typography>

            <Box sx={{ display: 'flex',gap:'15px','@media (max-width:768px)':{flexDirection:'column'}}}>
                <Box>
                    {
                        temp1.map((data) => (
                            <Box sx={{ maxWidth: '400px',margin:'25px 0'}}>
                                <Typography variant='h6' sx={TabTitle}>
                                    {data.title}
                                </Typography>
                                <Typography variant='body1' sx={TabDes}>
                                    {data.description}
                                </Typography>
                            </Box>
                        ))
                    }
                </Box>

                <Box>
                    {
                        temp2.map((data) => (
                            <Box sx={{ maxWidth: '400px',margin:'25px 0' }}>
                                <Typography variant='h6' sx={TabTitle}>
                                    {data.title}
                                </Typography>
                                <Typography variant='body1' sx={TabDes}>
                                    {data.description}
                                </Typography>
                            </Box>
                        ))
                    }
                </Box>
            </Box>


        </Box>
    </>
)
}

export default DesginTab
