import { routes } from '../../../routes'

export const HEADER_NAVBARS = [
    {
        id: 1,
        headerName: 'Home',
        navLink: routes.LANDING,
    },
    {
        id: 2,
        headerName: 'About Us',
        navLink: routes.ABOUTUS,
    },
    
    {
        id: 3,
        headerName: 'Services',
        navLink: routes.SERVICES,
    },
   
    {
        id: 4,
        headerName: 'Works',
        navLink: routes.WORKS,
    },
    {
        id: 5,
        headerName: 'Our Team',
        navLink: routes.OUR_TEAM,
    },
    {
        id: 6,
        headerName: 'Careers',
        navLink: routes.CAREERS,
    },
    {
        id: 7,
        headerName: 'Contact Us',
        navLink: routes.CONTACTUS,
    },
]
