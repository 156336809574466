import { Box } from '@mui/material'
import React from 'react'

const CaseStudyDetail = () => {
    return (
        <Box>
            <Box>
                <img />
            </Box>
            <h4></h4>
            <p></p>
        </Box>
    )
}

export default CaseStudyDetail
