import React from 'react'
import ServiceBanner from './serviceBanner'
import ServiceTab from './servicesTab'
import ServiceStep from './serviceSteps'
import ServiceAccordian from './serviceAccordian'
import { Helmet } from 'react-helmet-async'

function Servcies() {
    return (
        <>
            <Helmet>
                <title>
                    ECommerce Web Design Services | Custom Online Store
                    Development
                </title>
                <meta
                    name="title"
                    content="ECommerce Web Design Services | Custom Online Store Development"
                />

                <meta
                    name="description"
                    content="Boost your online business with our custom eCommerce web design services. We specialize in creating SEO-optimized, user-friendly eCommerce websites that drive sales and enhance customer experience."
                />
                <meta
                    name="keywords"
                    content="eCommerce web design, eCommerce website development, custom online store design, eCommerce website company, eCommerce solutions, eCommerce website design company, online store development"
                />
            </Helmet>
            <div>
                <ServiceBanner />
                {/* <ServiceTab/> */}
                <ServiceAccordian />
                <ServiceStep />
            </div>
        </>
    )
}

export default Servcies
