import React from 'react'
import { LeaderDes, Team, TeamBox, TeamCardBox, TeamDescription, TeamHead, TeamOut } from '../../teamgroupStyle'
import {cuddaloreTL, leaderObject} from '../../TeamInfo'
import TeamCard from '../../teamCards';
import des from '../../../../../asset/images/boxses.png'
import { Box } from '@mui/material';
import { Images } from '../../../../../asset/images';

function CudLeader() {
  return (
    <>
      <TeamOut>
      <LeaderDes>
          <img src={Images.Boxses} style={{width:'100%'}} alt='Boxes'/>
        </LeaderDes>

      <Team>
        <TeamBox>
            <TeamHead>
            The Helm
            </TeamHead>
            <TeamDescription>
            Our visionary leadership team drives innovation and excellence, guiding House of Websites towards creating outstanding digital solutions. 
            </TeamDescription>
        </TeamBox>
        <TeamCardBox>
            {cuddaloreTL.map((data)=> <TeamCard data={data}/>)}
        </TeamCardBox>
       </Team>
      </TeamOut>
     
       
    </>
  )
}

export default CudLeader