import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-cards'
import 'swiper/css/navigation' // Import Swiper navigation styles
import './Slider.css'
import { EffectCards, Navigation } from 'swiper/modules'
import { Box, Typography } from '@mui/material'
import { LandingClientsTitle } from './clients.style'
import { Images } from '../../../asset/images'
import { TestimonialSlides } from '../../../constants'

function LandingClientLove() {
    const swiperRef = useRef(null) 

    return (
        <Box
            sx={{
                background: 'rgba(15, 1, 33, 1)',
                position: 'relative', 
                // paddingBottom:'50px',
            
            }}
        >
            <LandingClientsTitle>Clients Love</LandingClientsTitle>

            <div className="swiper-container">
                <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    modules={[EffectCards, Navigation]}
                    className="mySwiper"
                    // loop={true} // Enable looping
                    speed={600}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }} // Use custom navigation elements
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                >
                    {TestimonialSlides.map((slide) => (
                        <SwiperSlide className="swiper-slide">
                            <Box
                                sx={{
                                    // display: 'flex',
                                    // alignItems: 'center',
                                    // justifyContent: 'center',
                                    // gap: {
                                    //     lg: '1.875rem',
                                    //     xs: '10px',
                                    // },
                                    // flexDirection: {
                                    //     lg: 'row',
                                    //     md: 'column',
                                    //     sm: 'column',
                                    //     xs: 'column',
                                    // },

                                    padding: {
                                        
                                        lg: '6.25rem',
                                        md: '4rem',
                                        sm: '2rem',
                                        xs: '1.5rem',
                                    },
                                   
                                }}
                            >
                                {/* <div>
                                    <Box
                                        component="img"
                                        src={slide?.image}
                                        alt={slide?.title || 'Image'}
                                        sx={{
                                            width: {
                                                lg: '210px',
                                                md: '210px',
                                                sm: '150px',
                                                xs: '150px',
                                            },
                                            height: 'auto',
                                        }}
                                    />
                                </div> */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: {
                                            lg:'1.5rem',
                                            md:'1.5rem',
                                            sm:'1rem',
                                            xs:'1rem'

                                        }
                                    }}
                                >
                                    <>
                                        <Box
                                            component="img"
                                            src={Images?.UpComma}
                                            alt="Up Comma"
                                            sx={{
                                                alignSelf: 'flex-start',
                                                position: 'relative',
                                                top: {
                                                    lg: '-1.5rem',
                                                    xs: '0',
                                                },
                                                width: {
                                                    lg: 'auto',
                                                    md: 'auto',
                                                    sm: 'auto',
                                                    xs: '15px',
                                                },
                                            }}
                                        />
                                        {/* <Typography
                                            sx={{
                                                color: 'rgba(0, 0, 0, 1)',
                                                fontSize: {
                                                    lg: '40px',
                                                    md: '30px',
                                                    sm: '22px',
                                                    xs: '20px',
                                                },
                                                fontWeight: '700',
                                            }}
                                        >
                                            {slide?.title}
                                        </Typography> */}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Lufga',
                                                color: 'rgba(255, 255, 255, 1)',
                                                fontSize: {
                                                    lg: '24px',
                                                    md: '24px',
                                                    sm: '14px',
                                                    xs: '14px',
                                                },
                                                fontWeight: '500',
                                                lineHeight:{
                                                    lg:'2.125rem',
                                                    md:'2.125rem',
                                                    sm:'1.5rem',
                                                    xs:'1.5rem'

                                                }
                                            }}
                                        >
                                            {slide?.description}
                                        </Typography>
                                        <Box
                                            component="img"
                                            src={Images?.DownComma}
                                            alt="Down Comma"
                                            sx={{
                                                alignSelf: 'flex-end',
                                                position: 'relative',
                                                bottom: '-0.5rem',
                                                width: {
                                                    lg: 'auto',
                                                    md: 'auto',
                                                    sm: 'auto',
                                                    xs: '15px',
                                                },
                                            }}
                                        />
                                    </>
                                    <Box sx={{
                                         display: 'flex',
                                         alignItems: 'center',
                                        //  justifyContent: '',
                                         gap: {
                                             lg: '1rem',
                                             md:'1rem',
                                             xs: '10px',
                                         },
                                         flexDirection: {
                                             lg: 'row',
                                             md: 'row',
                                             sm: 'row',
                                            //  xs: 'column',
                                         },
                                    }}>
                                        <div>
                                            <Box
                                                component="img"
                                                src={slide?.image}
                                                alt={slide?.title || 'Image'}
                                                sx={{
                                                   width:'64px',
                                                    height: '64px',
                                                }}
                                            />
                                        </div>
                                        <Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Lufga',
                                                color: 'rgba(255, 255, 255, 1)',
                                                fontSize: {
                                                    lg:'20px',
                                                    sm:'16px',
                                                },
                                                fontWeight: '300',

                                            }}
                                        >
                                            {slide?.clientName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Lufga',
                                                color: 'rgba(255, 255, 255, 1)',
                                                fontWeight: '800',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {slide?.projectName}
                                        </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </SwiperSlide>
                    ))}

                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </Swiper>
            </div>
        </Box>
    )
}

export default LandingClientLove
