import Houseofwebsites from '../images/Houseofwebsites.png'
import HOWLogo from '../svg/HowLogo.svg'
import ArrowIcon from '../svg/ArrowIcon.svg'
import HOWBanner from './HomeBannerWeb.webp'
import HOW_VIDEO from '../video/HowMainVideo.mp4'
import AccordionArrowIcon from '../svg/AccordionArrowIcon.svg'

import Branding from '../images/BrandingAccordion.png'
import DesignAndDevelopment from '../images/DesignDevelopment.png'
import SEO from '../images/SEO.png'
import Ecommerce from '../images/ECommence.png'

import UpComma from './UpComma.png'
import DownComma from './DownComma.png'
import MaskGroup from './MaskGroup.png'

import ViewProjects from '../images/ViewProject.png'
import LandingProject1 from '../images/NCPProject.png'
import LandingProject2 from '../images/AvarthaProject.png'
import LandingProject3 from '../images/CKProject.png'
import LandingProject4 from '../images/Paradham.png'
import LandingProject5 from '../images/MyceliumProject.png'
import SeeAllProject from '../images/SeeAllProjects.png'
import LandingProjectPattern from '../images/Pattern.png'
import ViewProject from '../images/ViewProject.png'
import SeeAllProjectArrow from '../images/SeeAllProjectArrow.svg'
import WorkbannerBG from './WorkbannerBG.png'
import WorkOverlayImg from './Work-overlayImg.png'
import WorkHeroImg from './Workherobg_com.png'
import WorkCompImg from './Work-computer.png'
import ReadImg from './read icon.png'
import SelectWork from './Rectangle 299.png'
import Identity from './Identity.png'
import ApplicationDesign from './Image 248.png'
import PackingDesign from './Packing design.png'
import WebsiteDesign from './websiteedesign.png'
import WebsiteDesign2 from './Website2.png'
import WorkContact from './Contactus.png'
import SideArrow from './Work_sidearrow.png'
import Line from './Line 30.png'
import Messages from './Messages.png'
import GetNewTechnlogy from './GetNewTechnology.png'
import LetsTalk from '../svg/LetsTalkIcon.svg'
import FooterIcon from '../svg/FooterLogo.svg'
import CareerBanner from './CareerBannerBg.png'
import Innovation from './Innoviation.png'
import Excelence from './Excellence.png'
import Empathy from './Empathy.png'
import Colabrate from './Collaboration.png'
import Adoptablity from './Adaptability.png'
import Acountablity from './Accountability.png'
import Location from '../svg/Location.svg'
import Search from '../svg/Search.svg'
import RightArrow from '../svg/arrow-right.svg'
import LocationWhite from '../svg/LocationWhite.svg'

import BoxDesign from './boxDesign.png'
import Boxses from './boxses.png'
import Cubeblur from './cubeblur.png'
import Cubes from './cubes.png'
import Doublecircle from './doublecircle.png'
import Greenblur from './green blur.png'
import OurTeamBg from './ourTeambg.png'
import Purpleblur from './purpleblur.png'
import Purpleblurbig from './purpleblurbig.png'
import Abstract from './teamAbstract.png'
import TeamCircle from './teamCircle.png'
import TeamGroup from './teamGroup.png'

import CareersDetialsBlur from './Ellipse 1.png'
import CDArrow from './cd-arrow.svg'
import Bookmark from './Bookmark.svg'
import Share from './Share.svg'
import Experience from './Experience.svg'
import JobType from './JobType.svg'
import CDLocation from './CDLocation.svg'
import Rupee from './rupee.svg'

//ContactUs
import Eugene1 from './eugene1.png'
import Linkedin from './linkedin.png'
import MailThat from './mail.png'
import PhoneCall from './phone.png'
import ContactBg from './contactbg.png'
import DownArrow from './down-arrow.png'
import Automatic from './AutomaticLogo.png'
import Wordpress from './wordpress-logo.svg fill.png'
import Interpre from './interprefy-logo.svg fill.png'
import InterPlayer from './players-health-logo.svg fill.png'
import BlockWorks from './BlockworksLogo.png'
import NewNew from './newnew-logo.svg.png'
import Video from './video.png'
// import AvatarUser from "./AvatarUser.png";
import SearchWhat from './search.png'
import StepMail from './step-mail.png'
import contactbg1 from './contactbg1.svg'
import contactbg2 from './contactbg2.svg'
import AvatarUser from './AvatarUser.png'
import footerbg from './footerbg.png'
import contactbannerbg from './contactbannerbg.png'

import SlideImg1 from './SlideImg1.png'
import SlideImg2 from './SlideImg2.png'
import SlideImg3 from './SlideImg3.png'
import SlideImg4 from './SlideImg4.png'
import SlideImg5 from './SlideImg5.png'
import EmpProfile1 from './EmpProfile1.png'
import EmpProfile2 from './EmpProfile2.png'
import EmpProfile3 from './EmpProfile3.png'
import EmpProfile4 from './EmpProfile4.png'
import EmpProfile5 from './EmpProfile5.png'
import EmpProfile6 from './EmpProfile6.png'
import EmpProfile7 from './EmpProfile7.png'
import QuoteImg from './QuoteImg.png'
import CareerVideo from '../video/CareerBgVideo.mp4'

import ContactImg from './contactPerson.png'

import ContactSVG from './contact-circle-img.png'
import ContactMail from './Contact-mail-svg.svg'
import ContactPhone from './Contact-phone-svg.svg'
import ContactArrow from './Contact-Arrow.svg'
import ContactBlue from './contact-blue-blur.png'
import ContactGreen from './contact-green-blur.png'
import ContactLine from './contact-blurline.png'

import DirectorImg from './Usha.png'
import DirectorComma from '../svg/DirectorComma.svg'

import AboutusEarth from './Earth.png'
import BlueBanner from '../images/Second.png'
import OurStoryTemple from './OurStoryTemple.png'
import GlobalAnimation from '../video/GlobeAnimation.gif'

import Dna from './Animaed.png'
import Grp from './Grp_pht.png'
import OurvalueBg from './our_values-bg.png'
import OurRm from './our_RM.png'
import Our_bg_border from './our_bg_border.png'
import ourAwarness from './Awareness_our.svg'
import OurPeople from './People_our.svg'
import OurFlag from './Flag_our.svg'
import ContactIn from '../svg/ContactLinkdin.svg'
import Dnaroate from '../video/DNA Animation.gif'
import SignleLine from '../video/Strand.gif'

import ContactSridhar from './sridhar-con.png'
import ContactVishal from './vishal-con.png'
import Samasta from './samasta.png'
import NCP_Tech from './NCp_TechStack.png'
import CK_Tech from './CK_TechStack.png'
import Jangoz_Tech from './Jangoz_TechStack.png'
import Wordpress_Techstack from './Wordpress_Techstack.png'
import Shopify_logo from './Shopify_logo.png'

import SuccessIcon from '../svg/SuccessIcon.svg'

import ServiceBannerImg from './serviceBanner.png'

import DiscoverImg from './discoverImg.png'
import DefineImg from './defineImg.png'
import DesignImg from './designImg.png'
import DevelopImg from './developImg.png'
import DeliveryImg from './deliveryImg.png'

import BlogContent1 from './BlogContent2.png'
import BlogContent2 from './BlogContent3.png'
import BlogContent3 from './BlogContent.png'
import BlogProfile from './BlogProfile.png'

import AccordBlue from './AccordBlue.png';
import AccordGreen from './AccordGreen.png';
import AccordGrey from './AccodGrey.png';
import AccordPurple from './AccordPurple.png';
import AccordRed from './AccordRed.png';

export const Images = {
    //home page images
    Houseofwebsites,
    HOWLogo,
    ArrowIcon,
    HOWBanner,
    HOW_VIDEO,
    AccordionArrowIcon,
    Branding,
    DesignAndDevelopment,
    SEO,
    Ecommerce,
    ViewProjects,
    LandingProject1,
    LandingProject2,
    LandingProject3,
    LandingProject4,
    LandingProject5,
    SeeAllProject,
    LandingProjectPattern,
    ViewProject,
    SeeAllProjectArrow,
    Messages,

    GetNewTechnlogy,

    LetsTalk,
    FooterIcon,

    UpComma,
    DownComma,

    MaskGroup,

    //Workpage Images
    WorkbannerBG,
    WorkOverlayImg,
    WorkHeroImg,
    WorkCompImg,
    ReadImg,
    SelectWork,
    Identity,
    ApplicationDesign,
    PackingDesign,
    WebsiteDesign,
    WebsiteDesign2,
    WorkContact,
    SideArrow,
    Line,
    Samasta,
    NCP_Tech,
    Jangoz_Tech,
    CK_Tech,
    Wordpress_Techstack,
    Shopify_logo,

    // our team images

    BoxDesign,
    Boxses,
    Cubeblur,
    Cubes,
    Doublecircle,
    Greenblur,
    OurTeamBg,
    Purpleblur,
    Purpleblurbig,
    Abstract,
    TeamCircle,
    TeamGroup,

    //Contact US images
    Eugene1,
    Linkedin,
    MailThat,
    PhoneCall,
    ContactBg,
    DownArrow,
    Automatic,
    Wordpress,
    Interpre,
    InterPlayer,
    BlockWorks,
    NewNew,
    Video,
    // ContactWhat,
    SearchWhat,
    StepMail,
    contactbg1,
    contactbg2,
    AvatarUser,
    footerbg,
    contactbannerbg,

    //Career Images
    CareerBanner,
    Innovation,
    Excelence,
    Empathy,
    Colabrate,
    Adoptablity,
    Acountablity,
    Location,
    Search,
    RightArrow,
    LocationWhite,
    SlideImg1,
    SlideImg2,
    SlideImg3,
    SlideImg4,
    SlideImg5,
    EmpProfile1,
    EmpProfile2,
    EmpProfile3,
    EmpProfile4,
    EmpProfile5,
    EmpProfile6,
    EmpProfile7,
    QuoteImg,
    CareerVideo,

    //Careers-Details
    CareersDetialsBlur,
    CDArrow,
    Bookmark,
    Share,
    Experience,
    Rupee,
    JobType,
    CDLocation,

    //About us

    AboutusEarth,
    BlueBanner,
    OurStoryTemple,

    GlobalAnimation,

    //contact image

    ContactImg,
    ContactSVG,
    ContactMail,
    ContactPhone,
    ContactArrow,
    ContactBlue,
    ContactGreen,
    ContactLine,
    ContactIn,
    ContactSridhar,
    ContactVishal,

    //about us

    DirectorImg,
    DirectorComma,

    // About us Images
    Dna,
    Grp,
    OurvalueBg,
    OurRm,
    OurFlag,
    OurPeople,
    ourAwarness,
    Our_bg_border,
    SignleLine,
    Dnaroate,

    SuccessIcon,

    //service page

    ServiceBannerImg,
    DiscoverImg,
    DefineImg,
    DesignImg,
    DevelopImg,
    DeliveryImg,

    BlogContent1,
    BlogContent2,
    BlogContent3,

    BlogProfile,

    AccordBlue,
AccordGreen,
AccordGrey,
AccordPurple,
AccordRed ,
}


