import React from 'react';
import ProjectCard from './ProjectCards';
import { Projects } from '../../../constants';
import { Images } from '../../../asset/images';
import {
  ProjectContainer,
  ProjectsTitle,
  ProjectsBackground,
  ProjectsBgImg,
  ProjectsGrid,
  UniqueImageSection,
  UniqueImage,
  UniqueContent,
  UniqueContentImg,
  SeeAllProject,
} from './projects.style';
import './SeeallAnimation.css'
import { routes } from '../../../routes';

function LandingProjects() {
  return (
    <ProjectContainer>
      <ProjectsTitle>
        We Work to Craft Solid Products <br />
        & <ProjectsBackground> Projects</ProjectsBackground> For You
      </ProjectsTitle>
      <ProjectsBgImg>
        <img src={Images.LandingProjectPattern} alt="LandingProjectPattern" />
      </ProjectsBgImg>
      <ProjectsGrid>
        {Projects.map((project, index) => (
          <ProjectCard
            key={index}
            image={project.image}
            title={project.title}
            description={project.description}
          />
        ))}
        <UniqueImageSection>
            <a href={routes?.WORKS} style={{textDecoration:'none'}}>
          <UniqueImage id='foo' />
          <UniqueContent>

            <SeeAllProject  >
              See
              <br />
              All Projects
            </SeeAllProject>
            <UniqueContentImg>
              <img src={Images.SeeAllProjectArrow} alt="SeeAllProjectArrow" />
            </UniqueContentImg>
          </UniqueContent>
            </a>
        </UniqueImageSection>
      </ProjectsGrid>
    </ProjectContainer>
  );
}

export default LandingProjects;
