import { Container } from '@mui/material'
import React from 'react'
import AboutusBanner from './about-banner'
import AboutusOurStory from './our-story'

import Director from './director'

import Dna from './DNA'
import { Helmet } from 'react-helmet-async'

export default function Aboutus() {
    return (
        <Container disableGutters maxWidth={false} style={{ padding: 0 }}>
            <Helmet>
                <title>About us | Creative web agency</title>
        <link rel="canonical" href="https://houseofwebsites.com" />

                <meta
                    name="description"
                    content="Looking for a top web design company in Chennai? House of Websites delivers custom, SEO-optimized, user-friendly websites tailored to your business needs"
                />
                <meta
                    name="keyword"
                    content="web design company in Chennai,
                    about our web agency,
                    who we are,
                    web development team,
                    digital marketing experts,
                    our mission and vision,
                    creative design agency,
                    web solutions provider,
                    Chennai web design experts,
                    professional web designers,
                    innovative web development,
                    website design philosophy,
                    client-centric approach,
                    team of designers and developers,
                    local web design services"
                />
            </Helmet>
            <AboutusBanner />
            <Director />
            <AboutusOurStory />
            <Dna />
        </Container>
    )
}
