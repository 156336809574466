export const visualContainer = {
    maxWidth:'1400px',
    margin:'0 auto',
    padding: '0px 7.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        padding: '70px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        padding: '40px 20px',
    },
}

export const visualHeading = {
    fontSize: '40px',
    paddingBottom: '24px',
    '@media (min-width: 320px) and (max-width: 600px)': {
        fontSize: '32px',
    },
}

export const visualContent = {
    maxWidth: '742px',
    fontSize: '18px',
    fontWeight: '300',
    fontFamily: 'Lufga',
    paddingBottom: '50px',
}

export const visualGrid = {
    display: 'grid',
    gridGap: '35px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    '@media (min-width: 320px) and (max-width: 600px)': {
        gridTemplateColumns: '1fr',
        gridGap: '15px',
    },
}

export const visualImageCon = {
    maxWidth: '630px',
}
