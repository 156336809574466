import React, { useEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { Images } from '../../asset/images'
import {
    LandingHeading,
    LandingHeaderColor,
    TextWritterContainer,
    TextWritterSpan,
    LandingBannerHeader,
} from './landing.style'
import Particles from './particles/Particles'
import Bubble from './bubble/Bubble'
import LandingVideo from './landingVideo'
import LandingAccordion from './ladning-accordion'
import LandingCount from './landing-count'
import LandingProjects from './landing-projects'
import LandingClientLove from './landing-clients'
import GetNewTechnologyEmail from './new technology-email/NewTechnology'
import BarLine from './bar-line'
import BlogSection from './blogs'
import { Helmet } from 'react-helmet-async'
import { BlogContainerSection, BlogsText } from './blogs/blog.style'

export default function Landing() {
    <Helmet>
        <title>Creative web agency</title>
        <meta
            name="description"
            content="House of Websites is a creative web agency specializing in building eye-catching websites and e-commerce solutions for MSMEs, helping your business stand out online."
        />
        <link rel="canonical" href="https://houseofwebsites.com" />
    </Helmet>
    return (
        <>
            <Box
                sx={{
                    overflowX: 'clip',
                }}
            >
                <Box
                    sx={{
                        marginBottom: '-10px',
                    }}
                >
                    <Box
                        component="img"
                        src={Images.HOWBanner}
                        alt="banner"
                        sx={{
                            width: '100%',
                            height: {
                                xs: '65vh',
                                sm: '75vh',
                                md: '90vh',
                                lg: '100vh',
                            },
                            objectFit: 'cover',
                        }}
                    />
                    <LandingBannerHeader>
                        <LandingHeading variant="h1">
                            We transform
                        </LandingHeading>
                        <LandingHeading variant="h1">
                            powerful visions into
                        </LandingHeading>
                        <LandingHeaderColor variant="h1">
                            dynamic websites
                        </LandingHeaderColor>
                    </LandingBannerHeader>
                    <Bubble />
                    <Particles />
                </Box>
                <LandingVideo />
            </Box>

            <BarLine />

            <LandingAccordion />

            <LandingProjects />
            <LandingCount />
            <LandingClientLove />
            <Box
                sx={{
                    background: 'rgba(15, 1, 33, 1)',
                    width: '100%',
                }}
            >
                {/* <GetNewTechnologyEmail /> */}

                <BlogContainerSection>
                    <BlogsText>Blogs </BlogsText>

                    <BlogSection />
                </BlogContainerSection>
            </Box>
        </>
    )
}
