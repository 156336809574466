// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width:900px) and (max-width:1200px)  {
    .abouts_growth_cont{
        margin-bottom: 11px;
    }
}
/* @media (min-width:1025px) and (max-width:1336px) {
   
    .container1{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
} */

`, "",{"version":3,"sources":["webpack://./src/pages/aboutus/DNA/DNA.css"],"names":[],"mappings":"AAAA;IACI;QACI,mBAAmB;IACvB;AACJ;AACA;;;;;;GAMG","sourcesContent":["@media (min-width:900px) and (max-width:1200px)  {\n    .abouts_growth_cont{\n        margin-bottom: 11px;\n    }\n}\n/* @media (min-width:1025px) and (max-width:1336px) {\n   \n    .container1{\n        padding-left: 20px !important;\n        padding-right: 20px !important;\n    }\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
