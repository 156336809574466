import React, { useEffect } from 'react'
import { Images } from '../../../asset/images'
import { Box, Typography } from '@mui/material'
import { contactSteps } from '../../../constants'
import { blueLine, stepHead } from './steps_style'
import apiClient from '../../../component/api/apiClient'
import { apiRouters } from '../../../component/api/apiRouters'

function ContactStep() {

    // useEffect(()=>{
        
    //     const fetchData = async () => {
    //         try {
    //           const response = await apiClient.get(apiRouters.contactSteps);
    //           console.log(response.data);


    //         } catch (e) {
    //           console.log(e);
    //         }
    //       };
    //       fetchData();
      

    // },[])

    let data = [
        {
            image: Images.Automatic
        },
        {
            image: Images.Wordpress
        },
        {
            image: Images.Interpre
        },
        {
            image: Images.InterPlayer
        },
        {
            image: Images.BlockWorks
        },
        {
            image: Images.NewNew
        },
    ]
    return (
        <>

            {/* <Box sx={{ backgroundColor: 'rgba(7, 7, 7, 1)', padding: '0 20px',postion:'relative' }}>

                <Box sx={blueLine}>
                    <img src={Images.ContactLine} style={{maxWidth:'100%'}}/>
                </Box>
                <Box sx={{ maxWidth: '1296px', margin: "0 auto", display: 'flex', justifyContent: 'space-evenly', padding: "70px 0 0 0", flexWrap: 'wrap' }}>
                    {data.map((data) => (
                        <Box sx={{ padding: '10px 15px' }}>
                            <img src={data.image} />
                        </Box>
                    ))}
                </Box>
            </Box> */}



            <Box sx={{ backgroundColor: 'rgba(7, 7, 7, 1)', padding:{md:'0 4.5rem',xs:'0 20px'}, color: '#fff',postion:'relative'  }}>

            <Box sx={blueLine}>
                    <img src={Images.ContactLine} style={{maxWidth:'100%'}} alt='ContactLine'/>
                </Box>
                
                <Box sx={{ maxWidth: '1296px',color:'#fff', margin: '0 auto', padding: '100px 0 100px 0', '@media (max-width:768px)': { padding: '25px 0 25px 0' } }}>
                    <Typography variant='h2' sx={stepHead}>
                        What are the next steps?
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', fontSize: '18px', fontFamily: 'Lufga', '@media (max-width:670px)': { justifyContent: 'center', fontSize: '16px' } }}>
                        {contactSteps.map((data) => (
                            <Box key={data.step} sx={{ display: 'flex', flexDirection: 'column', maxWidth: '285px', margin: '15px','@media(max-width:600px)':{margin:'15px 0'}}}>
                                <Box sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                                    <Box sx={{ paddingRight: '16px' }}>
                                        <img src={data.image} alt='image' />
                                    </Box>
                                    <Box>
                                        {data.step}
                                    </Box>
                                </Box>
                                <Box sx={{ paddingTop: '30px', lineHeight: '23px', fontWeight: '500' }}>
                                    {data.des}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ContactStep
