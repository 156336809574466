export const routes = {
  // LOGIN: "/login",
  LANDING: "/",
  DASHBOARD: "/",
  ABOUTUS: "/aboutus",
  SERVICES:"/services",
  WORKS: "/works",
  OUR_TEAM: "/ourteam",
  CAREERS:'/careers',
  CONTACTUS:'/contactus',
  CAREERS_DETAILS: '/careers/careers-details'
};
