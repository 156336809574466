import { Images } from "../../../asset/images"

export const ServiceBannerOuter = {
    backgroundImage: `url(${Images.ServiceBannerImg})`,
    WebkitBackgroundPosition: 'center',
    backgroundPosition: 'center',
    WebkitBackgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    //   height: '100vh',
    backgroundPositionY: '100%',
}

export const ServiceBannerCon = {
    display: 'flex', 
    alignItems: 'center', 
    height: '100%', 
    maxWidth: '1296px', 
    margin: '0 auto', 
    padding: { md: '0 72px', sm: '0 2rem', xs: '0 20px' },

    firstContent:{
        fontSize:'80px',
        marginBottom:'30px',
        '@media (max-width:1100px)':{
            fontSize:'70px',
        },
        '@media (max-width:900px)':{
            fontSize:'48px',
        }
        ,
        '@media (max-width:600px)':{
            fontSize:'38px',
        }  ,
        '@media (max-width:400px)':{
            fontSize:'24px',
        } 
    },
    secontContent:{
        fontSize:'22px',
        lineHeight:'32px',
        fontFamily:'Lufga',
        fontWeight:'500'  ,
        '@media (max-width:600px)':{
            fontSize:'16px',
        } 
    }
}