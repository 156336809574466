import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { visualHeading, visualContainer, visualContent, visualGrid, visualImageCon } from './style'
import { Images } from '../../../asset/images'
import Aos from 'aos'

const VisualLanguage = ({content}) => {

  const [isVisible, setIsVisible] = useState(false);
   
     useEffect(() => {
       if (isVisible) {
         Aos.init({
           duration: 1000, // Animation duration
           easing: 'ease-out', // Smooth animation
           once: true, // Animation happens only once
           offset: 120, // Animation triggers 120px before element is visible
         });
       }
     }, [isVisible]);
   
     // Intersection Observer
     const observerRef = React.useRef();
     useEffect(() => {
       const observer = new IntersectionObserver(
         ([entry]) => {
           if (entry.isIntersecting) {
             setIsVisible(true); // Set visibility when the component is in view
           }
         },
         { threshold: 0.3 } // Trigger when 30% of the component is visible
       );
   
       if (observerRef.current) {
         observer.observe(observerRef.current);
       }
   
       return () => observer.disconnect();
     }, []);

  return (
    <Box>
      <Box sx={visualContainer} ref={observerRef}>
      {isVisible && (
        <>
          <Typography sx={visualHeading}>{content?.title || 'title'}</Typography>
          <Typography sx={visualContent}>{content?.description || 'description'}</Typography>
          <Grid sx={visualGrid}>
            <Grid sx={visualImageCon}>
                <img data-aos="fade-down-right" style={{width: '100%'}} src={content?.image[0]} alt='Visual language image' />
            </Grid>
            <Grid sx={visualImageCon}>
                <img data-aos="fade-down-left" style={{width: '100%'}} src={content?.image[1]} alt='Visual language image' />
            </Grid>
            <Grid sx={visualImageCon}>
                <img data-aos="fade-up-right" style={{width: '100%'}} src={content?.image[2]} alt='Visual language image' />
            </Grid>
            <Grid sx={visualImageCon}>
                <img data-aos="fade-up-left" style={{width: '100%'}} src={content?.image[3]} alt='Visual language image' />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
    </Box>
  )
}

export default VisualLanguage
