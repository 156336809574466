import React from 'react'
import { CubeBlur, PurpleBlur, Star, StarBlur, Team, TeamBox, TeamCardBox, TeamDescription, TeamHead, TeamOut } from '../../teamgroupStyle'
import { backend, developerObject } from '../../TeamInfo'
import TeamCard from '../../teamCards'
import { Box } from '@mui/material'
import blur from '../../../../../asset/images/cubeblur.png'
import star from '../../../../../asset/images/Star 17.png'
import purp from '../../../../../asset/images/purpleblur.png'
import { Images } from '../../../../../asset/images'

function Developer() {
  return (
    <>

 <TeamOut>
        <CubeBlur>
          <img src={Images.Cubeblur} style={{width:'100%'}} alt='Cubeblur'/>
        </CubeBlur>
        <PurpleBlur>
          <img src={Images.Purpleblur} style={{width:'100%'}} alt='Purpleblur'/>
        </PurpleBlur>
 <Team>
        <TeamBox>
            <TeamHead>
            Coding Crew
            </TeamHead>
            <TeamDescription>
            Our coding experts bring digital dreams to life, delivering flawless, high-performance websites with precision and innovation. 
            </TeamDescription>
        </TeamBox>
        <TeamCardBox>
            {developerObject.map((data)=> <TeamCard data={data}/>)}
        </TeamCardBox>
       </Team>
 </TeamOut>




  <TeamOut>
        <StarBlur>
          <img src={Images.Purpleblur} style={{width:'100%'}} alt='Purpleblur'/>
        </StarBlur>
        {/* <Star>
          <img src={star} style={{width:'100%'}} alt="star"/>
        </Star> */}
  <Team>
       <TeamCardBox >
            {backend.map((data)=><TeamCard data={data}/>)}
        </TeamCardBox>
       </Team>
  </TeamOut>
    </>
  )
}

export default Developer

