import React, { useState } from 'react'
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Images } from '../../../asset/images'
import { HEADER_NAVBARS } from '../constants'
import PrimaryButton from '../buttons/PrimaryButton'
import { NavLink, useNavigate } from 'react-router-dom'
import { keyframes } from '@emotion/react'
import { routes } from '../../../routes'

const slideIn = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const Header = () => {
    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)
    const navigate = useNavigate()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
        navigate(routes?.CONTACTUS)
        setAnchorElNav(null)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <AppBar
            position="absolute"
            sx={{
                padding: {
                    lg: '2rem 4.5rem',
                    md: '2rem 4.5rem',
                    sm: '20px 2rem',
                    xs: '20px 20px',
                },
                width: '100%',

                background: 'transparent',
                boxShadow: 'none',
                color: '#fff',
            }}
        >
            <Box
                // maxWidth="100%"
                sx={{
                    width: '100%',
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        maxWidth: '1296px',
                        margin: '0 auto',
                    }}
                >
                    {/* Mobile Logo and Menu */}
                    <Box
                        sx={{
                            // display: { xs: 'flex', md: 'none' },
                            '@media (max-width:992px)':{
                                display:'flex',
                            },
                            display:'none',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <a href={routes?.DASHBOARD}>
                            <img
                                src={Images.HOWLogo}
                                alt="howlogo"
                                style={{
                                    maxWidth: '50px',
                                    width: 'auto',
                                    height: 'auto',
                                    cursor: 'pointer',
                                }}
                            />
                        </a>
                        <IconButton
                            size="large"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{ padding: '0' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                animation: `${slideIn} 0.3s ease-out`,
                            }}
                        >
                            {HEADER_NAVBARS.map((page) => (
                                <MenuItem
                                    key={page.id}
                                    onClick={handleCloseNavMenu}
                                    component={NavLink}
                                    to={page.navLink}
                                    style={({ isActive }) => ({
                                        textDecoration: isActive
                                            ? 'underline'
                                            : 'none',
                                        fontWeight: isActive ? '500' : '300',
                                        color: isActive
                                            ? 'rgba(0, 229, 253, 1)'
                                            : '#000',
                                        width: '100%',
                                    })}
                                >
                                    <Box
                                        sx={{
                                            fontSize: '1rem',
                                            textTransform: 'capitalize',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Lufga',
                                            }}
                                        >
                                            {page.headerName}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                            <MenuItem>
                                <PrimaryButton
                                    onClick={handleOpenUserMenu}
                                    style={{
                                        fontFamily: 'Lufga',
                                        borderRadius: '30px',
                                        backgroundColor:
                                            'rgba(247, 247, 247, 1)',
                                        textTransform: 'capitalize',
                                        fontSize: 'clamp(0.875rem, 3vw, 1rem)',
                                        padding: '12px 20px',
                                        fontWeight: 700,
                                        cursor: 'pointer',

                                        '&:hover': {
                                            backgroundColor: '',
                                        },
                                    }}
                                >
                                    Start a project
                                    <img
                                        style={{ marginLeft: '6px' }}
                                        src={Images.ArrowIcon}
                                        alt="ArrowIcon"
                                    />
                                </PrimaryButton>
                            </MenuItem>
                        </Menu>
                    </Box>

                    {/* Desktop Header */}
                    <Box
                        sx={{
                            // display: { xs: 'none', md: 'flex' },
                            '@media (min-width:992px)':{
                                display:'flex',
                            },
                            display:'none',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ width: { md: 'unset', md: '25%' },'@media (max-width:1250px)':{width:'20%'},'@media (max-width:1200px)':{width:'10%'}}}>
                            <a href={routes?.DASHBOARD}>
                                <img
                                    src={Images.HOWLogo}
                                    alt="howlogo"
                                    style={{ maxWidth: '150px' }}
                                />
                            </a>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                ' @media (min-width: 992px) and (max-width: 1080px)':{
                                    gap:1,
                                },
                                width: { md: 'unset', lg: '65%' },
                            }}
                        >
                            {HEADER_NAVBARS.map((page) => (
                                <Button
                                    key={page.id}
                                    component={NavLink}
                                    to={page.navLink}
                                    onClick={handleCloseNavMenu}
                                    sx={{
                                        fontSize: '1rem',
                                        textTransform: 'capitalize',
                                        fontFamily: 'Lufga',
                                        color: 'inherit',
                                        fontWeight: '300',
                                        textDecoration: 'none',
                                        '&.active': {
                                            fontWeight: '500',
                                            color: 'rgba(0, 229, 253, 1)',
                                            textDecoration: 'underline',
                                        },
                                       ' @media (min-width: 992px) and (max-width: 1080px)':{
                                            fontSize:'0.9rem',
                                        }
                                    }}
                                >
                                    {page.headerName}
                                </Button>
                            ))}
                        </Box>

                        {/* <PrimaryButton
                                onClick={handleOpenUserMenu}
                                style={{
                                    fontFamily: 'Lufga',
                                    borderRadius: '30px',
                                    backgroundColor: 'rgba(247, 247, 247, 1)',
                                    textTransform: 'capitalize',
                                    fontSize: '16px',
                                    padding: '12px 20px',
                                    fontWeight:500,
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(247, 247, 247, 1)',
                                        boxShadow:
                                            '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow on hover
                                    },
                                    transition:
                                        'background-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition for hover effect
                                }}
                            >
                                Start a project
                                <img
                                    style={{ marginLeft: '6px' }}
                                    src={Images.ArrowIcon}
                                    alt="ArrowIcon"
                                />
                            </PrimaryButton> */}

                        <div
                            style={{
                                fontFamily: 'Lufga',
                                borderRadius: '30px',
                                backgroundColor: 'rgba(247, 247, 247, 1)',
                                fontSize: '16px',
                                padding: '12px 20px',
                                transition:
                                    'background-color 0.3s ease, box-shadow 0.3s ease',
                                color: '#000000',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '3px',
                                textWrap: 'nowrap',
                                cursor: 'pointer',

                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: 'rgba(247, 247, 247, 1)',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                            onClick={handleOpenUserMenu}
                        >
                            Start a Project
                            <img
                                style={{ marginLeft: '6px' }}
                                src={Images.ArrowIcon}
                                alt="ArrowIcon"
                            />
                        </div>
                    </Box>
                </Toolbar>
            </Box>
        </AppBar>
    )
}

export default Header
