// chennai team 

import manek from '../../../asset/images/teamImages/Boss.webp';
import manager from '../../../asset/images/teamImages/Maneger.webp';
import TL from '../../../asset/images/teamImages/TL.webp';

import sri from '../../../asset/images/teamImages/Sridhar.webp';
import vishal from '../../../asset/images/teamImages/Vishal.webp';

//
import madhu from '../../../asset/images/teamImages/Madhumitha.webp';
import alwin from '../../../asset/images/teamImages/Alwyn.webp';
import gomathi from '../../../asset/images/teamImages/Snkaragomathi.webp';

import rohini from '../../../asset/images/teamImages/Rohini.webp';
import prasanth from '../../../asset/images/teamImages/Prasath.webp';
import ragavi from '../../../asset/images/teamImages/Sri ragavi.webp';
import hariSubra from '../../../asset/images/teamImages/Hari subramani.webp';
import iyapan from '../../../asset/images/teamImages/Iyyappan.webp';

//backend
import hema from '../../../asset/images/teamImages/Hema.webp';
import hide from '../../../asset/images/teamImages/Esther.webp';
import praveen from '../../../asset/images/teamImages/Praveen.webp';

import suresh from '../../../asset/images/teamImages/Sureshkumar.webp';
import mari from '../../../asset/images/teamImages/Mari muthu.webp';
import guna from '../../../asset/images/teamImages/Gunasekaran.webp';
import abi from '../../../asset/images/teamImages/Group 1321314738.webp';


//designers

import yag from '../../../asset/images/teamImages/Yagneswaran.webp';
import mubal from '../../../asset/images/teamImages/Mubal.webp';
import saravana from '../../../asset/images/teamImages/Saravana.webp';

// testing

import jaya from '../../../asset/images/teamImages/Jayaram.webp';
import padma from '../../../asset/images/teamImages/Padmavathi.webp';
import divya from '../../../asset/images/teamImages/Divya.webp';

// content writer

import rechal from '../../../asset/images/teamImages/Rachel.webp';
import chadra from '../../../asset/images/teamImages/Chandrasekar.webp';

// sales

import srikanth from '../../../asset/images/teamImages/srikanth.webp'
import preba from '../../../asset/images/teamImages/Keziah prabhavathy.webp';
import sai from '../../../asset/images/teamImages/Saikumar.webp';
import keshav from '../../../asset/images/teamImages/Kesavan.webp';
import reb from '../../../asset/images/teamImages/Rebacca.webp';
import raja from '../../../asset/images/teamImages/Rajakumari.webp';


// cuddalore team

import saravanaTL from '../../../asset/images/Cuddaloreteam/saravanakumar.png';
import leelaTL from '../../../asset/images/Cuddaloreteam/leelaTL.png';
import lincy from '../../../asset/images/Cuddaloreteam/lincy.png';
import vis from '../../../asset/images/Cuddaloreteam/visva.png';
import suba from '../../../asset/images/Cuddaloreteam/subashini.png';
import tamil from '../../../asset/images/Cuddaloreteam/tamzh.png';
import rajesh from '../../../asset/images/Cuddaloreteam/rajesh.png';
import gunaseelan from '../../../asset/images/Cuddaloreteam/guna.png';
import sriram from '../../../asset/images/Cuddaloreteam/sriram.png';
import yazhini from '../../../asset/images/Cuddaloreteam/yazhini.png';
import praveena from '../../../asset/images/Cuddaloreteam/praveena.png';

import jayaraman from '../../../asset/images/Cuddaloreteam/jayaram.png';
import gopi from '../../../asset/images/Cuddaloreteam/gopi.png';
import mohana from '../../../asset/images/Cuddaloreteam/mohana.png';
import reka from '../../../asset/images/Cuddaloreteam/Reka.png';
import gokul from '../../../asset/images/Cuddaloreteam/gokul.png';
import subashini2 from '../../../asset/images/Cuddaloreteam/subashini(2).png';
import kamala from '../../../asset/images/Cuddaloreteam/kamala.png';
import lawanya from '../../../asset/images/Cuddaloreteam/lawanya.png';
import nivetha from '../../../asset/images/Cuddaloreteam/nivetha.png';
import arul from '../../../asset/images/Cuddaloreteam/arulsiva.png';
import karthi from '../../../asset/images/Cuddaloreteam/karthick.png';
import nasim from '../../../asset/images/Cuddaloreteam/nasim.png';
import mani from '../../../asset/images/Cuddaloreteam/mani.png';
import saba from '../../../asset/images/Cuddaloreteam/sabanayagam.png';
import ram from '../../../asset/images/Cuddaloreteam/ramKumar.png';
import susa from '../../../asset/images/Cuddaloreteam/susatha.png';
import rag from '../../../asset/images/Cuddaloreteam/ranganath.png';


import testingBanner from '../../../asset/images/Tester team.webp';
import backendBanner from '../../../asset/images/Backend team.webp';
import designerBanner from '../../../asset/images/Designer team.webp';
import salesBanner from '../../../asset/images/sales team.webp';
import frontendBanner from '../../../asset/images/frontendTeam.webp';

import ChennaigroupBanner from '../../../asset/images/chennaiteam/How group image.webp';

// import cud1 from '../../../asset/images/Cuddaloreteam/Photo 93 1.png'

// import cud3 from '../../../asset/images/Cuddaloreteam/Photo 92 1.png'
// import cud4 from '../../../asset/images/Cuddaloreteam/Group 1321315009.png'
// import cud5 from '../../../asset/images/Cuddaloreteam/groupPhoto.png'

import cud3 from '../../../asset/images/Cuddaloreteam/111.png'
import cud4 from '../../../asset/images/Cuddaloreteam/112.png'
import cud5 from '../../../asset/images/Cuddaloreteam/113.png'



export const leaderObject = [{
    alt:'manekshawImg',
    image: manek,
    name: 'Manekshaw',
    role: 'Head'
},
{
    alt:'dineshImg',
    image: manager,
    name: 'Dinesh Babu ',
    role: 'Manager'
},
{
    alt:'hariImg',
    image: TL,
    name: 'Hariharan',
    role: 'Team Leader',
}]

export const analistObject = [{
    alt:'sridharImg',
    image: sri,
    name: 'Sridhar V',
    role: 'Business Analyst'
},
{
    alt:'vishalImg',
    image: vishal,
    name: 'Vishal Anand',
    role: 'Business Analyst',
},
]

export const developerObject = [{
    alt:'madhuImg',
    image: madhu,
    name: 'Madhumitha',
    role: 'Senior Developer'
},
{
    alt:'gomathiImg',
    image: gomathi,
    name: 'Sankaragomathi ',
    role: 'Front End Developer',
},
{
    alt:'alwynImg',
    image: alwin,
    name: 'Alwyn Jayakumar',
    role: 'Front End Developer'
},
{
    alt:'RohinikumarImg',
    image: rohini,
    name: 'Rohini Kumar',
    role: 'Front End Developer',
},
{
    alt:'prasath',
    image: prasanth,
    name: 'Prasath',
    role: 'Front End Developer'
},
{
    alt:'ragaviImg',
    image: ragavi,
    name: 'Sri Ragavi',
    role: 'Front End Developer',
},
{
    alt:'hariImg',
    image: hariSubra,
    name: 'Hari Subramanian',
    role: 'Front End Developer'
},
{
    alt:'iyyappanImg',
    image: iyapan,
    name: 'Iyyappan',
    role: 'Front End Developer',
},
]


export const backend = [{
    alt:'hemalathaImg',
    image: hema,
    name: 'Hemalatha',
    role: 'Developer'
},
{
    alt:'praveenImg',
    image: praveen,
    name: 'Praveenkumar',
    role: 'Developer',
},
{
    alt:'estherImg',
    image: hide,
    name: 'Esther',
    role: 'Developer'
},
{
    alt:'sureshImg',
    image: suresh,
    name: 'Sureshkumar',
    role: 'Developer',
},
{
    alt:'mariImg',
    image: mari,
    name: 'Marimuthu',
    role: 'Developer'
},
{
    alt:'gunaImg',
    image: guna,
    name: 'Gunasekaran',
    role: 'Developer',
},
{
    alt:'abiImg',
    image: abi,
    name: 'Abishak',
    role: 'Developer'
},];

export const designInfo = [

    {
        alt:'yagneswaranImg',
        image: yag,
        name: 'Yagneswaran',
        role: 'UI/UX Designer'
    },
    {
        alt:'saravanaImg',
        image: saravana,
        name: 'Saravana Perumal',
        role: 'UI/UX Designer',
    },
    {
        alt:'mubalImg',
        image: mubal,
        name: 'Mubal Raj A',
        role: 'UI/UX Designer'
    },

]

export const testing = [

    {
        alt:'jayaramImg',
        image: jaya,
        name: 'Jayaram',
        role: 'Tester'
    },
    {
        alt:'divyaImg',
        image: divya,
        name: 'Divya',
        role: 'Tester',
    },
    {
        alt:'padmaImg',
        image: padma,
        name: 'Padmavathi',
        role: 'Tester'
    },
]

export const contentWriter = [

    {
        alt:'rachelImg',
        image: rechal,
        name: 'Rachel Sharmila',
        role: 'Sr Content Writer'
    },
    // {
    //     image: chadra,
    //     name: 'Chandrasekar',
    //     role: 'Sr Executive Content Writer',
    // },
]




export const salesTeam = [
    {
        alt:'srikanthImg',
        image: srikanth,
        name: 'Srikanth GR',
        role: 'Head Sales'
    },
    {
        alt:'rebeccaImg',
        image: reb,
        name: 'Rebecca Nishanthi',
        role: 'Inside Sales Head',
    },
    {
        alt:'prabhaImg',
        image: preba,
        name: 'Keziah Prabhavathy',
        role: 'Sales Assistant Manager'
    },
    {
        alt:'rajaImg',
        image: raja,
        name: 'Rajakumari',
        role: 'Assistant Manager'
    },
    {
        alt:'saikumarImg',
        image: sai,
        name: 'Saikumar',
        role: 'Team Leader Sales',
    },

    {
        alt:'keshavImg',
        image: keshav,
        name: 'Kesavan',
        role: 'Inside Sales Executive'
    },


];


export const cuddaloreTL = [{
    alt:'saravanaImg',
    image: saravanaTL,
    name: 'Saravanakumar V',
    role: 'Team Leader'
},
{
    alt:'leelaImg',
    image: leelaTL,
    name: 'Leela Rosy T',
    role: 'Team Leader',
}
];


export const cuddaloreRangers = [
    {
        alt:'lincyImg',
        image: lincy,
        name: 'Lincy J',
        role: 'Business Analyst',
    },
    {
        alt:'visvanathImg',
        image: vis,
        name: 'Visvanath S',
        role: 'Business Analyst'
    }

];

export const cuddaloreCoders = [{
    alt:'subashiniImg',
    image: suba,
    name: 'Subashini V',
    role: 'Developer'
},
{
    alt:'thamizhImg',
    image: tamil,
    name: 'Thamizhvallavan N',
    role: 'Developer',
},
{
    alt:'rajeshImg',
    image: rajesh,
    name: 'Rajesh P',
    role: 'Developer'
},
{
    alt:'gunaseelanImg',
    image: gunaseelan,
    name: 'Gunaseelan P',
    role: 'Developer',
},
{
    alt:'yazhiniImg',
    image: yazhini,
    name: 'Yazhini M',
    role: 'Junior Developer'
}, {
    alt:'praveenaImg',
    image: praveena,
    name: 'Praveena V',
    role: 'Junior Developer'
}, {
    alt:'jayaramanImg',
    image: jayaraman,
    name: 'Jayaraman R',
    role: 'Junior Developer'
}, {
    alt:'sriramImg',
    image: sriram,
    name: 'Sriram R',
    role: 'Junior Developer'
}, {
    alt:'gopiImg',
    image: gopi,
    name: 'Gopinath R',
    role: 'Junior Developer'
},
{
    alt:'mohanaImg',
    image: mohana,
    name: 'Mohana S',
    role: 'Junior Developer'
}, {
    alt:'rekachittyImg',
    image: reka,
    name: 'Rekachitty D',
    role: 'Junior Developer'
},
];

export const cuddaloreCodingCrew = [
    {
        alt:'gokulImg',
        image: gokul,
        name: 'Gokulanath J P',
        role: 'Web Developer',
    },
    {
        alt:'subashiniImg',
        image: subashini2,
        name: 'Subashini S',
        role: 'Web Developer'
    },
    {
        alt:'kamalaImg',
        image: kamala,
        name: 'Kamalaveni P',
        role: 'Web Developer',
    },
    {
        alt:'lawanyaImg',
        image: lawanya,
        name: 'Lawanya',
        role: 'Web Developer'
    },
    {
        alt:'nivethaImg',
        image: nivetha,
        name: 'Nivethaa M',
        role: 'Web Developer',
    },
    {
        alt:'arulsivaImg',
        image: arul,
        name: 'Arulsiva jayabal',
        role: 'Web Developer'
    }

];


export const cuddaloreDesign = [
    {
        alt:'karthiImg',
        image: karthi,
        name: 'Karthik K M K',
        role: 'UI/UX Designer',
    }
];

export const cuddaloreSEO = [
    // {
    //     image: nasim,
    //     name: 'Nasim Ali B',
    //     role: 'SEO Analyst',
    // }, 
    {
        alt:'manikandanImg',
        image: mani,
        name: 'ManiKandan D',
        role: 'SEO Executive',
    }, {
        alt:'sabaImg',
        image: saba,
        name: 'Sabanayagam R',
        role: 'SEO Executive',
    }, {
        alt:'ramkumarImg',
        image: ram,
        name: 'RamKumar S',
        role: 'SEO Analyst',
    }, {
        alt:'susaethaImg',
        image: susa,
        name: 'Susaetha V',
        role: 'SEO Analyst',
    }
];


export const CuddaloreSupport = [{
    alt:'ranganthImg',
    image: rag,
    name: 'Ranganath N',
    role: 'L1 Support',
}]























export const ChennaiBanner = [
    {
        alt:'img1',
        images: ChennaigroupBanner,
    },
    {
        alt:'img2',
        images: backendBanner,
    },
    {
        alt:'img3',
        images: testingBanner,
    },
    {
        alt:'img4',
        images: designerBanner,
    },
    {
        alt:'img5',
        images: salesBanner,
    },
    {
        alt:'img6',
        images: frontendBanner,
    },
]


export const cuddaloreTeam = [
 
    
    // {
    //     image: cud1,
    // },
    // {
    //     image: cud2,
    // },
    {
        alt:'img1',
        image: cud3,
    },
    {
        alt:'img2',
        image: cud5,
    }, 
    {
        alt:'img3',
        image: cud4,
    },
]