
import React, { useEffect, useRef } from "react";
import { Box, Tooltip } from "@mui/material";
import { imageSectionCase, imgCon1, imgCon2, imgConAlign1, imgConAlign2, imgConBg } from "./style";

const ImageSection = ({ content = {} }) => {
  const marqueeRow1Ref = useRef(null);
  const marqueeRow2Ref = useRef(null);
  const marquee1AnimationRef = useRef(null);
  const marquee2AnimationRef = useRef(null);

  useEffect(() => {
    const startMarquee = (ref, direction, animationRef) => {
      if (!ref.current) return;

      const container = ref.current;
      const scrollSpeed = 0.9;
      let animationFrameId;

      const scroll = () => {
        if (direction === "left") {
          container.scrollLeft += scrollSpeed;
          if (container.scrollLeft >= container.scrollWidth / 2) {
            container.scrollLeft = 0;
          }
        } else {
          container.scrollLeft -= scrollSpeed-0.4;
          if (container.scrollLeft <= 0) {
            container.scrollLeft = container.scrollWidth / 2;
          }
        }
        animationFrameId = requestAnimationFrame(scroll);
        animationRef.current = animationFrameId;
      };

      scroll();
      return () => cancelAnimationFrame(animationFrameId);
    };

    // Start scrolling for both rows
    marquee1AnimationRef.current = startMarquee(marqueeRow1Ref, "left", marquee1AnimationRef);
    marquee2AnimationRef.current = startMarquee(marqueeRow2Ref, "right", marquee2AnimationRef);

    return () => {
      cancelAnimationFrame(marquee1AnimationRef.current);
      cancelAnimationFrame(marquee2AnimationRef.current);
    };
  }, []);

  const handleMouseEnter = (animationRef) => {
    cancelAnimationFrame(animationRef.current);
  };

  const handleMouseLeave = (ref, direction, animationRef) => {
    if (!ref.current) return;

    const container = ref.current;
    const scrollSpeed = 0.9;
    let animationFrameId;

    const scroll = () => {
      if (direction === "left") {
        container.scrollLeft += scrollSpeed;
        if (container.scrollLeft >= container.scrollWidth / 2) {
          container.scrollLeft = 0;
        }
      } else {
        container.scrollLeft -= scrollSpeed-0.4;
        if (container.scrollLeft <= 0) {
          container.scrollLeft = container.scrollWidth / 2;
        }
      }
      animationFrameId = requestAnimationFrame(scroll);
      animationRef.current = animationFrameId;
    };

    scroll();
  };

  const images1 = Array.isArray(content.image1) ? [...content.image1, ...content.image1] : [];
  const images2 = Array.isArray(content.image2) ? [...content.image2, ...content.image2] : [];

  return (
    <Box>
      <Box sx={imgConBg}>
        {/* First Row */}
        <Box sx={imageSectionCase}>
          <Box
            ref={marqueeRow1Ref}
            sx={{ ...imgConAlign1, display: "flex", overflow: "hidden", whiteSpace: "nowrap" }}
            onMouseEnter={() => handleMouseEnter(marquee1AnimationRef)}
            onMouseLeave={() => handleMouseLeave(marqueeRow1Ref, "left", marquee1AnimationRef)}
          >
            {images1.map((src, index) => (
              <Box key={`row1-${index}`} sx={imgCon1}>
                 {/* <Tooltip placement="image" title='image'> */}
                <img width="100%" src={src} alt={`Case Study ${index + 1}`} />
                  {/* </Tooltip> */}
              </Box>  
            ))}
          </Box>

          {/* Second Row */}
          <Box
            ref={marqueeRow2Ref}
            sx={{ ...imgConAlign2, display: "flex", overflow: "hidden", whiteSpace: "nowrap" }}
            onMouseEnter={() => handleMouseEnter(marquee2AnimationRef)}
            onMouseLeave={() => handleMouseLeave(marqueeRow2Ref, "right", marquee2AnimationRef)}
          >
            {images2.map((src, index) => (
              <Box key={`row2-${index}`} sx={imgCon2}>
                <img width="100%" src={src} alt={`Case Study ${index + 1}`} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageSection;
