import { Images } from '../asset/images'

export const Projects = [
    {
        image: Images.LandingProject1,
        title: 'New concept properties',
        description: 'Property Portal',
    },
    {
        image: Images.LandingProject2,
        title: 'Neuranest',
        description: 'CMS',
    },
    {
        image: Images.LandingProject3,
        title: "Ck's Bakery",
        description: 'Web Design',
    },
    {
        image: Images.LandingProject4,
        title: 'kailasa kaladhara',
        description: 'CMS',
    },
    {
        image: Images.LandingProject5,
        title: 'Mycelium',
        description: 'Branding',
    },
]

export const TestimonialSlides = [
    {
        title: 'New concept properties',
        description:
            'NCP Project Working with House of Websites on the NCP Property Portal was a game-changer. Their team understood our requirements and crafted a platform that is not only user-friendly but also highly scalable. The property listings are seamless, and our customers are loving the new experience.',
        image: Images?.MaskGroup,
        projectName: 'NCP Project',
        clientName:'Khaled',
    },
    {
        title: 'House Of Websites',

        description:
            'Neuranest House of Websites has done an exceptional job in developing a custom CMS solution for us at Neuranest. It has simplified our content management process and enhanced the efficiency of our team. The seamless interface and quick deployment exceeded our expectations.',
        image: Images?.MaskGroup,
        projectName: 'Neuranest',
        clientName:'Rahul',
    },
    {
        title: 'House Of Websites',

        description:
            `CK's Bakery Our website redesign by House of Websites has given CK's Bakery a fresh, vibrant online presence. The team nailed our vision, from design to functionality. Customers now rave about the ease of ordering through our new site, and it's truly transformed our business.`,
        image: Images?.MaskGroup,
        projectName: "Ck's Bakery",
        clientName:'Aniruth',
    },
    {
        title: 'House Of Websites',

        description:
            'Our e-commerce site has never looked better or performed so efficiently, all thanks to House of Websites. They provided us with a sleek, easy-to-navigate platform that has greatly enhanced our sales and customer satisfaction. Their team is responsive and professional.',
        image: Images?.MaskGroup,
        projectName: 'kailasa kaladhara',
        clientName:'Ravishankar',
    },
    {
        title: 'House Of Websites',

        description:
            "Mycelium Our branding journey with House of Websites was nothing short of transformative. They captured the essence of Mycelium Data Technologies and brought it to life through a cohesive, future-driven brand identity. Their expertise in combining sustainability with modern tech branding made all the difference.",
        image: Images?.MaskGroup,
        projectName: 'Mycelium',
        clientName:'Sam',
    },
]

export const accordionData = [
    {
        panel: 'panel1',
        title: 'Branding',
        content:
            'Crafting unique identities, we transform ideas into memorable brands, ensuring your business stands out with a compelling visual presence.',
        image: Images.Branding,

        progressValue: 35,
        height: '130px',
        topImage: '-90px',
        topPara: '-75px',
    },
    {
        panel: 'panel2',
        title: 'Design & Development',
        content:
            'Creating visually stunning, user-centric websites that balance aesthetics with functionality, driving engagement and delivering seamless digital experiences.',
        image: Images.DesignAndDevelopment,
        progressValue: 35,
        height: '130px',
        topImage: '-90px',
        topPara: '-75px',
    },
    {
        panel: 'panel3',
        title: 'SEO',
        content:
            'Optimizing your online presence with strategic SEO techniques, ensuring your website ranks high and attracts the right audience organically.',
        image: Images.SEO,
        progressValue: 35,
        height: '130px',
        topImage: '-90px',
        topPara: '-75px',
    },
    {
        panel: 'panel4',
        title: 'Ecommerce',
        content:
            'Building robust eCommerce platforms tailored to your business, empowering you to sell efficiently and grow in the digital marketplace.',
        image: Images.Ecommerce,
        progressValue: 35,
        height: '135px',
        topImage: '-95px',
        topPara: '-90px',
    },
]

export const sideBars = [
    ' Creative Agency',
    'Design Magic',
    'Boldly Crafted',
    'Elevate Ideas',
    'Unleash Awesome',
]

export const UserData = [
    {
        img: Images.Eugene1,
        in: Images.Linkedin,
        name: 'Manekshaw',
        roll: 'Head of Growth',
    },
    {
        img: Images.Eugene1,
        in: Images.Linkedin,
        name: 'Rebecca',
        roll: 'Sales Team',
    },
    {
        img: Images.Eugene1,
        in: Images.Linkedin,
        name: 'Kesavan',
        roll: 'Sales Team',
    },
]
export const multi_Diversity = [
    {
        div_img: Images.Innovation,
        div_headline: 'Innovation',
        div_para:
            'We value fresh ideas and creative thinking, constantly seeking ways to improve, adapt, and make meaningful changes in our work.',
        // fade_time: '1000',
    },
    {
        div_img: Images.Colabrate,
        div_headline: 'Collaboration',
        div_para:
            'We believe in strong teamwork, working together closely, sharing ideas, and supporting each other to achieve the best possible outcomes.',
        // fade_time: '1200',
    },
    {
        div_img: Images.Empathy,
        div_headline: 'Empathy',
        div_para:
            "We care deeply about understanding others' feelings and needs, ensuring our work reflects kindness, compassion, and genuine human connection.",
        // fade_time: '1400',
    },
    {
        div_img: Images.Excelence,
        div_headline: 'Excellence',
        div_para:
            'We strive to do our absolute best in every task, delivering high-quality work that meets high standards and expectations.',
        // fade_time: '1600',
    },
    {
        div_img: Images.Adoptablity,
        div_headline: 'Adaptability',
        div_para:
            'We embrace change with open minds, quickly adjusting to new situations and challenges, ensuring we stay effective and relevant.',
        // fade_time: '1800',
    },
    {
        div_img: Images.Acountablity,
        div_headline: 'Accountability',
        div_para:
            'We take full responsibility for our actions, always following through on commitments and maintaining integrity in everything we do.',
        // fade_time: '2000',
    },
]
export const Current_opening = [
    {
        current_p: 'Tester',
        current_p_cont:
            'Ensure product quality through rigorous testing. Identify and resolve issues, ensuring seamless performance and a flawless user experience across all platforms.',
        current_loc_area: 'Chennai, India',
        fade_time: '1000',

        postedDate: '2024-08-20',
        experience: '2 - 5 years',
        salary: '10 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Front End Developer',
        current_p_cont:
            'Be part of a dynamic team crafting intuitive, responsive user interfaces that enhance digital interactions and elevate user experiences.',
        current_loc_area: 'Chennai, India',
        fade_time: '1300',

        postedDate: '2022-08-20',
        experience: '0 - 5 years',
        salary: '5 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Back End Developer',
        current_p_cont:
            'Develop cutting-edge solutions, ensuring seamless functionality and robust performance across platforms, driving the technical backbone of our projects.',
        current_loc_area: 'Chennai, India',
        fade_time: '1600',

        postedDate: '2022-08-20',
        experience: '0 years',
        salary: '1 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Content Writer',
        current_p_cont:
            'Create compelling content that engages audiences and drives results. Transform ideas into clear, impactful messaging that resonates with target readers.',
        current_loc_area: 'Chennai, India',
        fade_time: '1900',

        postedDate: '2024-08-20',
        experience: '2 years',
        salary: '3 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Product Designer',
        current_p_cont:
            'Design innovative, user-centered products that blend aesthetics with functionality. Drive the creation of intuitive, engaging solutions that meet user needs.',
        current_loc_area: 'Chennai, India',
        fade_time: '1200',

        postedDate: '2024-07-20',
        experience: '5 years',
        salary: '10 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
    {
        current_p: 'Data Analyst',
        current_p_cont:
            'Transform complex data into actionable insights. Analyze trends, patterns, and metrics to guide strategic decisions and optimize performance across projects.',
        current_loc_area: 'Chennai, India',
        fade_time: '1400',

        postedDate: '2024-01-20',
        experience: '7 years',
        salary: '20 Lacs P.A.',
        jobType: 'Hybrid',
        jobDetails:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying.',
        jobRole: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
        jobDescription: [
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
            'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
            'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
            'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
            'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
            'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
        ],
    },
]
export const Careerslider = [
    {
        slideimg: Images.SlideImg1,
    },
    {
        slideimg: Images.SlideImg2,
    },
    {
        slideimg: Images.SlideImg3,
    },
    {
        slideimg: Images.SlideImg4,
    },
    {
        slideimg: Images.SlideImg5,
    },
]

export const Glassdoordata = [
    {
        slideimg: Images.EmpProfile1,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Mubal Raj',
        emprole: 'UI/UX Designer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile2,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Esther',
        emprole: 'Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile3,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Praveenkumar',
        emprole: 'Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile4,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Hemalatha',
        emprole: 'Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile5,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Madhumitha',
        emprole: 'Senior Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile6,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Vishal Anand',
        emprole: 'Business Analyst, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
    {
        slideimg: Images.EmpProfile7,
        imgAlt: 'Empprofile',
        quoteimg: Images.QuoteImg,
        empname: 'Sureshkumar',
        emprole: 'Developer, House of Websites',
        desc: "From the first day, I felt I am a part of the team. My ideas and suggestions were heard and appreciated. It's a great atmosphere with a bunch of talented and fun-loving people",
    },
]

export const contactTeamInfo = [
    ,
    //     {
    //     image:Images.ContactImg,
    //     name:'Manekshaw',
    //     role:'Head of Growth',
    // }
    {
        image: Images.ContactVishal,
        name: 'Vishal',
        role: 'Buisness Consultant',
        link: 'https://www.linkedin.com/in/vishal-anand-9666a478/',
    },
    {
        image: Images.ContactSridhar,
        name: 'Sridhar V',
        role: 'Buisness Consultant',
        link: 'https://www.linkedin.com/in/sridhar-v-6a933a22b/',
    },
]

// export  const contactStatus = [
//     {
//         image: Images.ContactImg,
//         name: 'Manekshaw',
//         role: 'Head of Growth',
//     },
//     {
//         image: Images.ContactImg,
//         name: 'Rebecca',
//         role: 'Sales Team',
//     },
//     {
//         image: Images.ContactImg,
//         name: 'Kesavan',
//         role: 'Sales Team',
//     },
// ]

export const contactStatus = [
    {
        id:1,
        detail: 'Guaranteed response within 24 hours',
    },
    {
        id:2,
        detail: 'Confidentiality assured: NDA available upon request',
    },
    {
        id:3,
        detail: 'Direct access to our expert web development specialists',
    },
]
export const countData = [
    {
        start: 0,
        end: 4,
        projectName: 'Years of Services',
        class: 'item1',
        number: '80',
        fade_time: '1000',
        add: '+',
    },
    {
        start: 0,
        end: 80,
        projectName: 'Team Members',
        class: 'item2',
        number: '80',
        fade_time: '1200',
        add: '+',
    },
    {
        start: 0,
        end: 40,
        projectName: 'Projects',
        class: 'item3',
        number: '80',
        fade_time: '1400',
        add: '+',
    },
    {
        start: 0,
        end: 70,
        projectName: 'Happy Clients',
        class: 'item4',
        number: '80',
        fade_time: '1600',
        add: '+',
    },
    {
        start: 0,
        end: 2,
        projectName: 'Location',
        class: 'item5',
        number: '80',
        fade_time: '1800',
    },
]

export const contactSteps = [
    {
        step: 'STEP 1',
        image: Images.Video,
        des: 'Our team reaches out to you within 24 hours.',
    },
    {
        step: 'STEP 2',
        image: Images.AvatarUser,
        des: 'We discuss your project details and gather all necessary requirements.',
    },
    {
        step: 'STEP 3',
        image: Images.SearchWhat,
        des: 'We analyze, review, and finalize the requirements to create the brief.',
    },
    {
        step: 'STEP 4',
        image: Images.StepMail,
        des: 'We prepare the estimates and present you with the proposal.',
    },
]


export const serviceSteps = [
    {
        step:'Step 1',
        title:'Discover',
        des:'Uncovering your vision and understanding user needs to shape a compelling website strategy.',
        image:Images.DiscoverImg,
    },
    {
        step:'Step 2',
        title:'Define',
        des:'Crafting a detailed project scope and outlining a clear roadmap for website development.',
        image:Images.DefineImg,
    },
    {
        step:'Step 3',
        title:'Design',
        des:'Creating user flows, engaging interactions, and refining concepts through mockups and wireframes.',
        image:Images.DesignImg,
    },
    {
        step:'Step 4',
        title:'Develop',
        des:'Transforming designs into functional websites, integrating interactive features with cutting-edge technology.',
        image:Images.DevelopImg,
    },
    {
        step:'Step 5',
        title:'Delivery',
        des:'Finalizing, testing, and launching the website to ensure a polished, market-ready product.',
        image:Images.DeliveryImg,
    }
]



export const BlogSections=[

    {
        id:1,
        blogImage:Images?.BlogContent1,
        read:"Design  •  5 min read",
        blogSectionTitle:'Grid systems for better design user interface',
        blogSectionDescription:'The point of using Lorem Ipsum is that it has a more or less normal distribution of letters, as opposed to using content.',
        BlogContentName:"Ramesh Babu  •",
        BlogContentDate:' 05 Sept, 2024'

    },
    {
        id:2,
        blogImage:Images?.BlogContent2,
        read:"Design • 10 min read",
        blogSectionTitle:'The Future: How AI is shaping the future of UI UX design',
        blogSectionDescription:'The point of using Lorem Ipsum is that it has a more or less normal distribution of letters, as opposed to using content.',
        BlogContentName:"Ramesh Babu  •",
        BlogContentDate:' 05 Sept, 2024'

    },
    {
        id:3,
        blogImage:Images?.BlogContent3,
        read:"Design • 8 min read",
        blogSectionTitle:'15 UI/UX Rules That Set Your App Apart from the Rest',
        blogSectionDescription:'The point of using Lorem Ipsum is that it has a more or less normal distribution of letters, as opposed to using content.',
        BlogContentName:"Ramesh Babu  •",
        BlogContentDate:' 05 Sept, 2024'

    }
]


export const ServiceAccord = [

    {
        uni:1,
        panal:'panel1',
        id:'panel1d-header',
        arial:'panel1d-content',
        service:'Branding',
        images:Images.AccordBlue,
        content:'At House of Websites, we believe that a strong brand is the cornerstone of any successful business. Our branding services are designed to create a unique and memorable identity for your company, ensuring that it stands out in a crowded market.',
        temp:[
            {
                title: 'Logo Design',
                description: 'Your logo is the face of your brand. Our team of talented designers works closely with you to create a logo that is not only visually appealing but also reflects your brand’s values and personality. We provide multiple design concepts and revisions to ensure complete satisfaction.',
            },
            {
                title: 'Brand Messaging',
                description: 'Crafting a compelling brand message is crucial for connecting with your audience. We help you define your brand voice and create messaging that resonates with your target market. From taglines to mission statements, we ensure consistency across all communication channels.',
            },
            {
                title: 'Brand Strategy Development',
                description: 'A strong brand requires a solid strategy. We conduct thorough market research and competitor analysis to develop a comprehensive brand strategy. This includes positioning, value proposition, and long-term brand goals to ensure sustained growth and recognition.',
            },
            {
                title: 'Visual Identity',
                description: 'Beyond the logo, we develop a complete visual identity for your brand. This includes colour schemes, typography, imagery, and design guidelines that ensure consistency across all platforms and marketing materials.',
            },
            {
                title: 'Brand Storytelling',
                description: 'Engage your audience with compelling brand stories. We create narratives that highlight your brand’s journey, values, and vision, building an emotional connection with your customers.',
            }
        ]
    }, 
    {
        uni:2,
        panal:'panel2',
        id:'panel2d-header',
        arial:'panel2d-content',
        service:'Web Design',
        images:Images.AccordGreen,
        content:'A well-designed website is more than just a digital storefront; it’s an experience. At House of Websites, we specialize in creating visually stunning and highly functional websites that captivate your audience and drive engagement.',
        temp:[
            {
                title: 'Custom Website Design',
                description: 'Every business is unique, and so should be its website. Our team of designers creates custom website designs tailored to your brand’s identity and goals. We focus on user experience, ensuring your site is easy to navigate and visually appealing.',
            },
            {
                title: 'Responsive Design',
                description: 'In today’s mobile-driven world, having a responsive website is crucial. Our designs are optimized for all devices, from desktops to smartphones, ensuring a seamless experience for all users.',
            },
            {
                title: 'UI/UX Design',
                description: 'User interface (UI) and user experience (UX) are at the heart of our design process. We create intuitive and engaging interfaces that make it easy for visitors to find what they’re looking for. Our UX design focuses on user satisfaction and improving the overall usability of your site.',
            },
            {
                title: 'Graphic Design',
                description: 'Beyond web design, we offer comprehensive graphic design services. From banners and icons to infographics and marketing materials, our designers ensure that all visual elements align with your brand’s aesthetics.',
            },
            {
                title: 'Prototyping and Wireframing',
                description: 'Before diving into design, we create detailed prototypes and wireframes. This allows you to visualize the layout and functionality of your site and make any necessary adjustments before the design is finalized.',
            },
            {
                title: 'SEO-Friendly Design',
                description: 'A beautiful website is useless if it can’t be found. We integrate SEO best practices into our design process, ensuring your site is optimized for search engines from the ground up.',
            },
        ]

    },   {
        uni:3,
        panal:'panel3',
        id:'panel3d-header',
        arial:'panel3d-content',
        service:'Web Development',
        images:Images.AccordPurple,
        content:'Transform your ideas into reality with our top-notch web development services. At House of Websites, we build robust, scalable, and high-performing websites that cater to your business needs and deliver an exceptional user experience.',
        temp:[
            {
                title: 'Custom Web Development',
                description: 'Whether you need a simple landing page or a complex web application, we provide custom web development solutions tailored to your requirements. Our developers use the latest technologies and frameworks to build secure and scalable websites.',
            },
            {
                title: 'Content Management Systems (CMS): ',
                description: 'Easily manage your website content with our CMS solutions. We specialize in WordPress, Joomla, Drupal, and other popular CMS platforms, providing you with the flexibility to update your site without any technical expertise.',
            },
            {
                title: 'Ecommerce Development',
                description: 'Expand your business online with our ecommerce development services. We create secure, user-friendly, and feature-rich ecommerce websites that enhance the shopping experience and drive sales. Our solutions include payment gateway integration, inventory management, and more.',
            },
            {
                title: 'API Integration',
                description: 'Seamlessly connect your website with third-party services and applications through API integration. We enable smooth data exchange between your site and other platforms, enhancing functionality and user experience.',
            },
            {
                title: 'Web Applications',
                description: 'Bring your web app ideas to life with our custom web application development services. From SaaS applications to complex business tools, we build solutions that streamline operations and provide value to your users.',
            },
            {
                title: 'Maintenance and Support',
                description: 'Keep your website running smoothly with our maintenance and support services. We offer regular updates, security patches, and technical support to ensure your site remains functional and secure.',
            },

        ]
    },
    {
        uni:4,
        panal:'panel4',
        id:'panel4d-header',
        arial:'panel4d-content',
        service:'Ecommerce Websites',
        images:Images.AccordRed,
        content:'Unlock the full potential of your business with a powerful ecommerce website. At House of Websites, we specialize in creating ecommerce solutions that drive sales and provide an exceptional shopping experience for your customers.',
        temp:[
            {
                title: 'Custom Ecommerce Design',
                description: 'Stand out from the competition with a unique and visually appealing ecommerce website. Our custom designs reflect your brand’s identity and provide an intuitive shopping experience.',
            },
            {
                title: 'User-Friendly Interface',
                description: 'A seamless and user-friendly interface is crucial for retaining customers. We design ecommerce websites that are easy to navigate, ensuring a smooth shopping experience from browsing to checkout.',
            },
            {
                title: 'Secure Payment Gateways',
                description: 'Security is paramount in ecommerce. We integrate secure payment gateways like PayPal, Stripe, and others, ensuring safe and hassle-free transactions for your customers.',
            },
            {
                title: 'Product Management',
                description: 'Easily manage your product inventory with our user-friendly admin panels. Add, update, or remove products, manage categories, and track stock levels with ease.',
            },
            {
                title: 'Mobile Optimization',
                description: 'With more people shopping on mobile devices, having a mobile-optimized ecommerce site is essential. Our designs are responsive and optimized for all devices, providing a consistent shopping experience across platforms.',
            },
            {
                title: 'SEO and Marketing Tools',
                description: 'Drive traffic to your ecommerce site with our built-in SEO and marketing tools. We implement SEO best practices and provide tools for managing discounts, promotions, and email marketing campaigns.',
            },
            {
                title: 'Analytics and Reporting',
                description: 'Gain valuable insights into your ecommerce performance with our analytics and reporting tools. Track sales, customer behavior, and other key metrics to make informed business decisions.',
            },
        ]
    },
    {
        uni:5,
        panal:'panel5',
        id:'panel5d-header',
        arial:'panel5d-content',
        service:'Mobile Application',
        images:Images.AccordGrey,
        content:'Stay ahead in the mobile-first world with our custom mobile application development services. At House of Websites, we design and develop high-quality mobile apps for iOS and Android platforms that engage users and drive business growth.',
        temp:[
            {
                title: 'Custom App Development',
                description: 'Whether you need a simple app or a complex enterprise solution, we provide custom mobile app development tailored to your needs. Our developers use the latest technologies to build secure, scalable, and high-performing apps.',
            },
            {
                title: 'iOS and Android Development',
                description: 'Reach a wider audience with our iOS and Android app development services. We create apps that run smoothly on both platforms, providing a consistent user experience.',
            },
            {
                title: 'User-Centric Design',
                description: 'Our design philosophy is centered around the user. We create intuitive and engaging interfaces that provide an excellent user experience, ensuring high user retention and satisfaction.',
            },
            {
                title: 'App Testing and Quality Assurance',
                description: 'Ensure your app is free from bugs and performs flawlessly with our rigorous testing and quality assurance processes. We conduct thorough testing to identify and fix any issues before launch.',
            },
            {
                title: 'App Store Optimization (ASO)',
                description: ' Maximize your app’s visibility and downloads with our ASO services. We optimize your app’s title, description, keywords, and other elements to improve its ranking on app stores.',
            },
            {
                title: 'Maintenance and Updates',
                description: 'Keep your app up-to-date and running smoothly with our maintenance and update services. We provide regular updates, feature enhancements, and technical support to ensure your app remains competitive.',
            },
        ],
      
    },
    {
        uni:6,
        panal:'panel6',
        id:'panel6d-header',
        arial:'panel6d-content',
        service:'SEO (Search Engine Optimization)',
        images:Images.AccordBlue,
        content:'Improve your website’s visibility and ranking on search engines with our comprehensive SEO services. At House of Websites, we implement on-page and off-page SEO strategies to attract organic traffic and enhance your online presence.',
        temp:[
            {
                title: 'Keyword Research',
                description: 'Identify the right keywords to target with our thorough keyword research. We analyse your industry, competitors, and search trends to find keywords that will drive relevant traffic to your site.',
            },
            {
                title: 'On-Page SEO',
                description: 'Optimize your website’s content and structure with our on-page SEO services. We focus on elements like title tags, meta descriptions, headers, and content optimization to improve your site’s search engine ranking.',
            },
            {
                title: 'Off-Page SEO',
                description: 'Build your website’s authority and credibility with our off-page SEO strategies. We implement link-building, social media marketing, and other techniques to enhance your site’s reputation and drive traffic.',
            },
            {
                title: 'Technical SEO',
                description: 'Ensure your website is technically sound with our technical SEO services. We address issues like site speed, mobile-friendliness, crawlability, and indexability to improve your site’s performance on search engines.',
            },
            {
                title: 'Local SEO',
                description: 'Attract local customers with our local SEO services. We optimize your site for local searches, manage your Google My Business listing, and implement strategies to improve your visibility in local search results.',
            },
            {
                title: 'SEO Audits',
                description: 'Identify and fix SEO issues with our comprehensive SEO audits. We conduct a thorough analysis of your site’s SEO performance and provide actionable recommendations for improvement.',
            },
            {
                title: 'Content Strategy',
                description: 'Create high-quality, SEO-friendly content with our content strategy services. We develop a content plan that includes blog posts, articles, and other content that targets relevant keywords and engages your audience.',
            },
        ]
    }
]



//country code

// export const countries = [
//     { code: 'AD', label: 'Andorra', phone: '+376' },
//     {
//       code: 'AE',
//       label: 'United Arab Emirates',
//       phone: '+971',
//     },
//     { code: 'AF', label: 'Afghanistan', phone: '+93' },
//     {
//       code: 'AG',
//       label: 'Antigua and Barbuda',
//       phone: '+1-268',
//     },
//     { code: 'AI', label: 'Anguilla', phone: '+1-264' },
//     { code: 'AL', label: 'Albania', phone: '+355' },
//     { code: 'AM', label: 'Armenia', phone: '+374' },
//     { code: 'AO', label: 'Angola', phone: '+244' },
//     { code: 'AQ', label: 'Antarctica', phone: '+672' },
//     { code: 'AR', label: 'Argentina', phone: '+54' },
//     { code: 'AS', label: 'American Samoa', phone: '+1-684' },
//     { code: 'AT', label: 'Austria', phone: '+43' },
//     {
//       code: 'AU',
//       label: 'Australia',
//       phone: '+61',
//       suggested: true,
//     },
//     { code: 'AW', label: 'Aruba', phone: '+297' },
//     { code: 'AX', label: 'Alland Islands', phone: '+358' },
//     { code: 'AZ', label: 'Azerbaijan', phone: '+994' },
//     {
//       code: 'BA',
//       label: 'Bosnia and Herzegovina',
//       phone: '+387',
//     },
//     { code: 'BB', label: 'Barbados', phone: '+1-246' },
//     { code: 'BD', label: 'Bangladesh', phone: '+880' },
//     { code: 'BE', label: 'Belgium', phone: '+32' },
//     { code: 'BF', label: 'Burkina Faso', phone: '+226' },
//     { code: 'BG', label: 'Bulgaria', phone: '+359' },
//     { code: 'BH', label: 'Bahrain', phone: '+973' },
//     { code: 'BI', label: 'Burundi', phone: '+257' },
//     { code: 'BJ', label: 'Benin', phone: '+229' },
//     { code: 'BL', label: 'Saint Barthelemy', phone: '+590' },
//     { code: 'BM', label: 'Bermuda', phone: '+1-441' },
//     { code: 'BN', label: 'Brunei Darussalam', phone: '+673' },
//     { code: 'BO', label: 'Bolivia', phone: '+591' },
//     { code: 'BR', label: 'Brazil', phone: '+55' },
//     { code: 'BS', label: 'Bahamas', phone: '+1-242' },
//     { code: 'BT', label: 'Bhutan', phone: '+975' },
//     { code: 'BV', label: 'Bouvet Island', phone: '+47' },
//     { code: 'BW', label: 'Botswana', phone: '+267' },
//     { code: 'BY', label: 'Belarus', phone: '+375' },
//     { code: 'BZ', label: 'Belize', phone: '+501' },
//     {
//       code: 'CA',
//       label: 'Canada',
//       phone: '+1',
//       suggested: true,
//     },
//     {
//       code: 'CC',
//       label: 'Cocos (Keeling) Islands',
//       phone: '+61',
//     },
//     {
//       code: 'CD',
//       label: 'Congo, Democratic Republic of the',
//       phone: '+243',
//     },
//     {
//       code: 'CF',
//       label: 'Central African Republic',
//       phone: '+236',
//     },
//     {
//       code: 'CG',
//       label: 'Congo, Republic of the',
//       phone: '+242',
//     },
//     { code: 'CH', label: 'Switzerland', phone: '+41' },
//     { code: 'CI', label: "Cote d'Ivoire", phone: '+225' },
//     { code: 'CK', label: 'Cook Islands', phone: '+682' },
//     { code: 'CL', label: 'Chile', phone: '+56' },
//     { code: 'CM', label: 'Cameroon', phone: '+237' },
//     { code: 'CN', label: 'China', phone: '+86' },
//     { code: 'CO', label: 'Colombia', phone: '+57' },
//     { code: 'CR', label: 'Costa Rica', phone: '+506' },
//     { code: 'CU', label: 'Cuba', phone: '+53' },
//     { code: 'CV', label: 'Cape Verde', phone: '+238' },
//     { code: 'CW', label: 'Curacao', phone: '+599' },
//     { code: 'CX', label: 'Christmas Island', phone: '+61' },
//     { code: 'CY', label: 'Cyprus', phone: '+357' },
//     { code: 'CZ', label: 'Czech Republic', phone: '+420' },
//     {
//       code: 'DE',
//       label: 'Germany',
//       phone: '+49',
//       suggested: true,
//     },
//     { code: 'DJ', label: 'Djibouti', phone: '+253' },
//     { code: 'DK', label: 'Denmark', phone: '+45' },
//     { code: 'DM', label: 'Dominica', phone: '+1-767' },
//     {
//       code: 'DO',
//       label: 'Dominican Republic',
//       phone: '+1-809',
//     },
//     { code: 'DZ', label: 'Algeria', phone: '+213' },
//     { code: 'EC', label: 'Ecuador', phone: '+593' },
//     { code: 'EE', label: 'Estonia', phone: '+372' },
//     { code: 'EG', label: 'Egypt', phone: '+20' },
//     { code: 'EH', label: 'Western Sahara', phone: '+212' },
//     { code: 'ER', label: 'Eritrea', phone: '+291' },
//     { code: 'ES', label: 'Spain', phone: '+34' },
//     { code: 'ET', label: 'Ethiopia', phone: '+251' },
//     { code: 'FI', label: 'Finland', phone: '+358' },
//     { code: 'FJ', label: 'Fiji', phone: '+679' },
//     {
//       code: 'FK',
//       label: 'Falkland Islands (Malvinas)',
//       phone: '+500',
//     },
//     {
//       code: 'FM',
//       label: 'Micronesia, Federated States of',
//       phone: '+691',
//     },
//     { code: 'FO', label: 'Faroe Islands', phone: '+298' },
//     {
//       code: 'FR',
//       label: 'France',
//       phone: '+33',
//       suggested: true,
//     },
//     { code: 'GA', label: 'Gabon', phone: '+241' },
//     { code: 'GB', label: 'United Kingdom', phone: '+44' },
//     { code: 'GD', label: 'Grenada', phone: '+1-473' },
//     { code: 'GE', label: 'Georgia', phone: '+995' },
//     { code: 'GF', label: 'French Guiana', phone: '+594' },
//     { code: 'GG', label: 'Guernsey', phone: '+44' },
//     { code: 'GH', label: 'Ghana', phone: '+233' },
//     { code: 'GI', label: 'Gibraltar', phone: '+350' },
//     { code: 'GL', label: 'Greenland', phone: '+299' },
//     { code: 'GM', label: 'Gambia', phone: '+220' },
//     { code: 'GN', label: 'Guinea', phone: '+224' },
//     { code: 'GP', label: 'Guadeloupe', phone: '+590' },
//     { code: 'GQ', label: 'Equatorial Guinea', phone: '+240' },
//     { code: 'GR', label: 'Greece', phone: '+30' },
//     {
//       code: 'GS',
//       label: 'South Georgia and the South Sandwich Islands',
//       phone: '+500',
//     },
//     { code: 'GT', label: 'Guatemala', phone: '+502' },
//     { code: 'GU', label: 'Guam', phone: '+1-671' },
//     { code: 'GW', label: 'Guinea-Bissau', phone: '+245' },
//     { code: 'GY', label: 'Guyana', phone: '+592' },
//     { code: 'HK', label: 'Hong Kong', phone: '+852' },
//     {
//       code: 'HM',
//       label: 'Heard Island and McDonald Islands',
//       phone: '+672',
//     },
//     { code: 'HN', label: 'Honduras', phone: '+504' },
//     { code: 'HR', label: 'Croatia', phone: '+385' },
//     { code: 'HT', label: 'Haiti', phone: '+509' },
//     { code: 'HU', label: 'Hungary', phone: '+36' },
//     { code: 'ID', label: 'Indonesia', phone: '+62' },
//     { code: 'IE', label: 'Ireland', phone: '+353' },
//     { code: 'IL', label: 'Israel', phone: '+972' },
//     { code: 'IM', label: 'Isle of Man', phone: '+44' },
//     { code: 'IN', label: 'India', phone: '+91' },
//     {
//       code: 'IO',
//       label: 'British Indian Ocean Territory',
//       phone: '+246',
//     },
//     { code: 'IQ', label: 'Iraq', phone: '+964' },
//     {
//       code: 'IR',
//       label: 'Iran, Islamic Republic of',
//       phone: '+98',
//     },
//     { code: 'IS', label: 'Iceland', phone: '+354' },
//     { code: 'IT', label: 'Italy', phone: '+39' },
//     { code: 'JE', label: 'Jersey', phone: '+44' },
//     { code: 'JM', label: 'Jamaica', phone: '+1-876' },
//     { code: 'JO', label: 'Jordan', phone: '+962' },
//     {
//       code: 'JP',
//       label: 'Japan',
//       phone: '+81',
//       suggested: true,
//     },
//     { code: 'KE', label: 'Kenya', phone: '+254' },
//     { code: 'KG', label: 'Kyrgyzstan', phone: '+996' },
//     { code: 'KH', label: 'Cambodia', phone: '+855' },
//     { code: 'KI', label: 'Kiribati', phone: '+686' },
//     { code: 'KM', label: 'Comoros', phone: '+269' },
//     {
//       code: 'KN',
//       label: 'Saint Kitts and Nevis',
//       phone: '+1-869',
//     },
//     {
//       code: 'KP',
//       label: "Korea, Democratic People's Republic of",
//       phone: '+850',
//     },
//     { code: 'KR', label: 'Korea, Republic of', phone: '+82' },
//     { code: 'KW', label: 'Kuwait', phone: '+965' },
//     { code: 'KY', label: 'Cayman Islands', phone: '+1-345' },
//     { code: 'KZ', label: 'Kazakhstan', phone: '+7' },
//     {
//       code: 'LA',
//       label: "Lao People's Democratic Republic",
//       phone: '+856',
//     },
//     { code: 'LB', label: 'Lebanon', phone: '+961' },
//     { code: 'LC', label: 'Saint Lucia', phone: '+1-758' },
//     { code: 'LI', label: 'Liechtenstein', phone: '+423' },
//     { code: 'LK', label: 'Sri Lanka', phone: '+94' },
//     { code: 'LR', label: 'Liberia', phone: '+231' },
//     { code: 'LS', label: 'Lesotho', phone: '+266' },
//     { code: 'LT', label: 'Lithuania', phone: '+370' },
//     { code: 'LU', label: 'Luxembourg', phone: '+352' },
//     { code: 'LV', label: 'Latvia', phone: '+371' },
//     { code: 'LY', label: 'Libya', phone: '+218' },
//     { code: 'MA', label: 'Morocco', phone: '+212' },
//     { code: 'MC', label: 'Monaco', phone: '+377' },
//     {
//       code: 'MD',
//       label: 'Moldova, Republic of',
//       phone: '+373',
//     },
//     { code: 'ME', label: 'Montenegro', phone: '+382' },
//     {
//       code: 'MF',
//       label: 'Saint Martin (French part)',
//       phone: '+590',
//     },
//     { code: 'MG', label: 'Madagascar', phone: '+261' },
//     { code: 'MH', label: 'Marshall Islands', phone: '+692' },
//     {
//       code: 'MK',
//       label: 'Macedonia, the Former Yugoslav Republic of',
//       phone: '+389',
//     },
//     { code: 'ML', label: 'Mali', phone: '+223' },
//     { code: 'MM', label: 'Myanmar', phone: '+95' },
//     { code: 'MN', label: 'Mongolia', phone: '+976' },
//     { code: 'MO', label: 'Macao', phone: '+853' },
//     {
//       code: 'MP',
//       label: 'Northern Mariana Islands',
//       phone: '+1-670',
//     },
//     { code: 'MQ', label: 'Martinique', phone: '+596' },
//     { code: 'MR', label: 'Mauritania', phone: '+222' },
//     { code: 'MS', label: 'Montserrat', phone: '+1-664' },
//     { code: 'MT', label: 'Malta', phone: '+356' },
//     { code: 'MU', label: 'Mauritius', phone: '+230' },
//     { code: 'MV', label: 'Maldives', phone: '+960' },
//     { code: 'MW', label: 'Malawi', phone: '+265' },
//     { code: 'MX', label: 'Mexico', phone: '+52' },
//     { code: 'MY', label: 'Malaysia', phone: '+60' },
//     { code: 'MZ', label: 'Mozambique', phone: '+258' },
//     { code: 'NA', label: 'Namibia', phone: '+264' },
//     { code: 'NC', label: 'New Caledonia', phone: '+687' },
//     { code: 'NE', label: 'Niger', phone: '+227' },
//     { code: 'NF', label: 'Norfolk Island', phone: '+672' },
//     { code: 'NG', label: 'Nigeria', phone: '+234' },
//     { code: 'NI', label: 'Nicaragua', phone: '+505' },
//     { code: 'NL', label: 'Netherlands', phone: '+31' },
//     { code: 'NO', label: 'Norway', phone: '+47' },
//     { code: 'NP', label: 'Nepal', phone: '+977' },
//     { code: 'NR', label: 'Nauru', phone: '+674' },
//     { code: 'NU', label: 'Niue', phone: '+683' },
//     { code: 'NZ', label: 'New Zealand', phone: '+64' },
//     { code: 'OM', label: 'Oman', phone: '+968' },
//     { code: 'PA', label: 'Panama', phone: '+507' },
//     { code: 'PE', label: 'Peru', phone: '+51' },
//     { code: 'PF', label: 'French Polynesia', phone: '+689' },
//     { code: 'PG', label: 'Papua New Guinea', phone: '+675' },
//     { code: 'PH', label: 'Philippines', phone: '+63' },
//     { code: 'PK', label: 'Pakistan', phone: '+92' },
//     { code: 'PL', label: 'Poland', phone: '+48' },
//     {
//       code: 'PM',
//       label: 'Saint Pierre and Miquelon',
//       phone: '+508',
//     },
//     { code: 'PN', label: 'Pitcairn', phone: '+870' },
//     { code: 'PR', label: 'Puerto Rico', phone: '+1' },
//     {
//       code: 'PS',
//       label: 'Palestine, State of',
//       phone: '+970',
//     },
//     { code: 'PT', label: 'Portugal', phone: '+351' },
//     { code: 'PW', label: 'Palau', phone: '+680' },
//     { code: 'PY', label: 'Paraguay', phone: '+595' },
//     { code: 'QA', label: 'Qatar', phone: '+974' },
//     { code: 'RE', label: 'Reunion', phone: '+262' },
//     { code: 'RO', label: 'Romania', phone: '+40' },
//     { code: 'RS', label: 'Serbia', phone: '+381' },
//     { code: 'RU', label: 'Russian Federation', phone: '+7' },
//     { code: 'RW', label: 'Rwanda', phone: '+250' },
//     { code: 'SA', label: 'Saudi Arabia', phone: '+966' },
//     { code: 'SB', label: 'Solomon Islands', phone: '+677' },
//     { code: 'SC', label: 'Seychelles', phone: '+248' },
//     { code: 'SD', label: 'Sudan', phone: '+249' },
//     { code: 'SE', label: 'Sweden', phone: '+46' },
//     { code: 'SG', label: 'Singapore', phone: '+65' },
//     { code: 'SH', label: 'Saint Helena', phone: '+290' },
//     { code: 'SI', label: 'Slovenia', phone: '+386' },
//     {
//       code: 'SJ',
//       label: 'Svalbard and Jan Mayen',
//       phone: '+47',
//     },
//     { code: 'SK', label: 'Slovakia', phone: '+421' },
//     { code: 'SL', label: 'Sierra Leone', phone: '+232' },
//     { code: 'SM', label: 'San Marino', phone: '+378' },
//     { code: 'SN', label: 'Senegal', phone: '+221' },
//     { code: 'SO', label: 'Somalia', phone: '+252' },
//     { code: 'SR', label: 'Suriname', phone: '+597' },
//     { code: 'SS', label: 'South Sudan', phone: '+211' },
//     {
//       code: 'ST',
//       label: 'Sao Tome and Principe',
//       phone: '+239',
//     },
//     { code: 'SV', label: 'El Salvador', phone: '+503' },
//     {
//       code: 'SX',
//       label: 'Sint Maarten (Dutch part)',
//       phone: '+1-721',
//     },
//     {
//       code: 'SY',
//       label: 'Syrian Arab Republic',
//       phone: '+963',
//     },
//     { code: 'SZ', label: 'Swaziland', phone: '+268' },
//     {
//       code: 'TC',
//       label: 'Turks and Caicos Islands',
//       phone: '+1-649',
//     },
//     { code: 'TD', label: 'Chad', phone: '+235' },
//     {
//       code: 'TF',
//       label: 'French Southern Territories',
//       phone: '+262',
//     },
//     { code: 'TG', label: 'Togo', phone: '+228' },
//     { code: 'TH', label: 'Thailand', phone: '+66' },
//     { code: 'TJ', label: 'Tajikistan', phone: '+992' },
//     { code: 'TK', label: 'Tokelau', phone: '+690' },
//     { code: 'TL', label: 'Timor-Leste', phone: '+670' },
//     { code: 'TM', label: 'Turkmenistan', phone: '+993' },
//     { code: 'TN', label: 'Tunisia', phone: '+216' },
//     { code: 'TO', label: 'Tonga', phone: '+676' },
//     { code: 'TR', label: 'Turkey', phone: '+90' },
//     {
//       code: 'TT',
//       label: 'Trinidad and Tobago',
//       phone: '+1-868',
//     },
//     { code: 'TV', label: 'Tuvalu', phone: '+688' },
//     {
//       code: 'TW',
//       label: 'Taiwan',
//       phone: '+886',
//     },
//     {
//       code: 'TZ',
//       label: 'United Republic of Tanzania',
//       phone: '+255',
//     },
//     { code: 'UA', label: 'Ukraine', phone: '+380' },
//     { code: 'UG', label: 'Uganda', phone: '+256' },
//     {
//       code: 'US',
//       label: 'United States',
//       phone: '+1',
//       suggested: true,
//     },
//     { code: 'UY', label: 'Uruguay', phone: '+598' },
//     { code: 'UZ', label: 'Uzbekistan', phone: '+998' },
//     {
//       code: 'VA',
//       label: 'Holy See (Vatican City State)',
//       phone: '+379',
//     },
//     {
//       code: 'VC',
//       label: 'Saint Vincent and the Grenadines',
//       phone: '+1-784',
//     },
//     { code: 'VE', label: 'Venezuela', phone: '+58' },
//     {
//       code: 'VG',
//       label: 'British Virgin Islands',
//       phone: '+1-284',
//     },
//     {
//       code: 'VI',
//       label: 'US Virgin Islands',
//       phone: '+1-340',
//     },
//     { code: 'VN', label: 'Vietnam', phone: '+84' },
//     { code: 'VU', label: 'Vanuatu', phone: '+678' },
//     { code: 'WF', label: 'Wallis and Futuna', phone: '+681' },
//     { code: 'WS', label: 'Samoa', phone: '+685' },
//     { code: 'XK', label: 'Kosovo', phone: '+383' },
//     { code: 'YE', label: 'Yemen', phone: '+967' },
//     { code: 'YT', label: 'Mayotte', phone: '+262' },
//     { code: 'ZA', label: 'South Africa', phone: '+27' },
//     { code: 'ZM', label: 'Zambia', phone: '+260' },
//     { code: 'ZW', label: 'Zimbabwe', phone: '+263' },
//   ];

export const countries = [
    { code: 'AD', label: 'Andorra', phone: '+376', phoneLength: 6},
    { code: 'AE', label: 'United Arab Emirates', phone: '+971', phoneLength: 9},
    { code: 'AF', label: 'Afghanistan', phone: '+93', phoneLength: 9},
    { code: 'AG', label: 'Antigua and Barbuda', phone: '+1-268', phoneLength: 10},
    { code: 'AI', label: 'Anguilla', phone: '+1-264', phoneLength: 10},
    { code: 'AL', label: 'Albania', phone: '+355', phoneLength: 9},
    { code: 'AM', label: 'Armenia', phone: '+374', phoneLength: 6},
    { code: 'AO', label: 'Angola', phone: '+244', phoneLength: 9},
    { code: 'AQ', label: 'Antarctica', phone: '+672', phoneLength: 6},
    { code: 'AR', label: 'Argentina', phone: '+54', phoneLength: [6,7,8]},					
    { code: 'AS', label: 'American Samoa', phone: '+1-684', phoneLength: 10},
    { code: 'AT', label: 'Austria', phone: '+43', phoneLength: [10,11]},			
    { code: 'AU', label: 'Australia', phone: '+61', suggested: true, phoneLength: 9},
    { code: 'AW', label: 'Aruba', phone: '+297', phoneLength: 7},
    { code: 'AX', label: 'Alland Islands', phone: '+358', min: 7, max: 10},				
    { code: 'AZ', label: 'Azerbaijan', phone: '+994', phoneLength: 9},
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '+387', phoneLength: 8},
    { code: 'BB', label: 'Barbados', phone: '+1-246', phoneLength: 10},
    { code: 'BD', label: 'Bangladesh', phone: '+880', phoneLength: 10},
    { code: 'BE', label: 'Belgium', phone: '+32', phoneLength: 9},
    { code: 'BF', label: 'Burkina Faso', phone: '+226', phoneLength: 8},
    { code: 'BG', label: 'Bulgaria', phone: '+359', phoneLength: 9},
    { code: 'BH', label: 'Bahrain', phone: '+973', phoneLength: 8},
    { code: 'BI', label: 'Burundi', phone: '+257', phoneLength: 8},
    { code: 'BJ', label: 'Benin', phone: '+229', phoneLength: 8},
    { code: 'BL', label: 'Saint Barthelemy', phone: '+590', phoneLength: 9},				
    { code: 'BM', label: 'Bermuda', phone: '+1-441', phoneLength: 10},
    { code: 'BN', label: 'Brunei Darussalam', phone: '+673', phoneLength: 7},
    { code: 'BO', label: 'Bolivia', phone: '+591', phoneLength: 9},
    { code: 'BR', label: 'Brazil', phone: '+55', phoneLength: 11},
    { code: 'BS', label: 'Bahamas', phone: '+1-242', phoneLength: 10},
    { code: 'BT', label: 'Bhutan', phone: '+975', phoneLength: 7},
    { code: 'BV', label: 'Bouvet Island', phone: '+47',phoneLength: 10 },			
    { code: 'BW', label: 'Botswana', phone: '+267', phoneLength: 7},
    { code: 'BY', label: 'Belarus', phone: '+375', phoneLength: 9},
    { code: 'BZ', label: 'Belize', phone: '+501', phoneLength: 7},
    { code: 'CA', label: 'Canada', phone: '+1', suggested: true, phoneLength: 10},
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '+61', phoneLength: 10},			
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '+243', phoneLength: 7},	
    { code: 'CF', label: 'Central African Republic', phone: '+236', phoneLength: 8},
    { code: 'CG', label: 'Congo, Republic of the', phone: '+242', phoneLength: 9},	
    { code: 'CH', label: 'Switzerland', phone: '+41', phoneLength: 9},
    { code: 'CI', label: "Cote d'Ivoire", phone: '+225', phoneLength: 8},
    { code: 'CK', label: 'Cook Islands', phone: '+682', phoneLength: 5},
    { code: 'CL', label: 'Chile', phone: '+56', phoneLength: 9},
    { code: 'CM', label: 'Cameroon', phone: '+237', phoneLength: 9},
    { code: 'CN', label: 'China', phone: '+86', phoneLength: 11},
    { code: 'CO', label: 'Colombia', phone: '+57', phoneLength: 10},
    { code: 'CR', label: 'Costa Rica', phone: '+506', phoneLength: 8},
    { code: 'CU', label: 'Cuba', phone: '+53', phoneLength: 8},
    { code: 'CV', label: 'Cape Verde', phone: '+238', phoneLength: 7},
    { code: 'CW', label: 'Curacao', phone: '+599', phoneLength: 7},
    { code: 'CX', label: 'Christmas Island', phone: '+61', phoneLength: 9},	
    { code: 'CY', label: 'Cyprus', phone: '+357', phoneLength: 8},
    { code: 'CZ', label: 'Czech Republic', phone: '+420', phoneLength: 9},
    { code: 'DE', label: 'Germany', phone: '+49', suggested: true, phoneLength: 10},
    { code: 'DJ', label: 'Djibouti', phone: '+253', phoneLength: 10},
    { code: 'DK', label: 'Denmark', phone: '+45', phoneLength: 8},
    { code: 'DM', label: 'Dominica', phone: '+1-767', phoneLength: 10},
    { code: 'DO', label: 'Dominican Republic', phone: '+1-809', phoneLength: 10},
    { code: 'DZ', label: 'Algeria', phone: '+213', phoneLength: 9},
    { code: 'EC', label: 'Ecuador', phone: '+593', phoneLength: 9},
    { code: 'EE', label: 'Estonia', phone: '+372', phoneLength: 8},
    { code: 'EG', label: 'Egypt', phone: '+20', phoneLength: 10},
    { code: 'EH', label: 'Western Sahara', phone: '+212', phoneLength: 9},
    { code: 'ER', label: 'Eritrea', phone: '+291', phoneLength: 7},	
    { code: 'ES', label: 'Spain', phone: '+34', phoneLength: 9},
    { code: 'ET', label: 'Ethiopia', phone: '+251', phoneLength: 9},
    { code: 'FI', label: 'Finland', phone: '+358', min: 9, max: 11},
    { code: 'FJ', label: 'Fiji', phone: '+679', phoneLength: 7},
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '+500', phoneLength: 5},
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '+691', phoneLength: 7},
    { code: 'FO', label: 'Faroe Islands', phone: '+298', phoneLength: 5},
    { code: 'FR', label: 'France', phone: '+33', suggested: true, phoneLength: 9},
    { code: 'GA', label: 'Gabon', phone: '+241', phoneLength: 7},
    { code: 'GB', label: 'United Kingdom', phone: '+44', phoneLength: 10},
    { code: 'GD', label: 'Grenada', phone: '+1-473', phoneLength: 10},
    { code: 'GE', label: 'Georgia', phone: '+995', phoneLength: 9},
    { code: 'GF', label: 'French Guiana', phone: '+594', phoneLength: 9},
    { code: 'GG', label: 'Guernsey', phone: '+44', phoneLength: 10},
    { code: 'GH', label: 'Ghana', phone: '+233', phoneLength: 9},
    { code: 'GI', label: 'Gibraltar', phone: '+350', phoneLength: 8},
    { code: 'GL', label: 'Greenland', phone: '+299', phoneLength: 6},
    { code: 'GM', label: 'Gambia', phone: '+220', phoneLength: 7},
    { code: 'GN', label: 'Guinea', phone: '+224', phoneLength: 9},
    { code: 'GP', label: 'Guadeloupe', phone: '+590', phoneLength: 9},
    { code: 'GQ', label: 'Equatorial Guinea', phone: '+240', phoneLength: 9},
    { code: 'GR', label: 'Greece', phone: '+30', phoneLength: 10},
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '+500', phoneLength: 5},	
    { code: 'GT', label: 'Guatemala', phone: '+502', phoneLength: 8},
    { code: 'GU', label: 'Guam', phone: '+1-671', phoneLength: 10},
    { code: 'GW', label: 'Guinea-Bissau', phone: '+245', phoneLength: 9},
    { code: 'GY', label: 'Guyana', phone: '+592', phoneLength: 7},
    { code: 'HK', label: 'Hong Kong', phone: '+852', phoneLength: 8},
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '+672',phoneLength: 10 },		
    { code: 'HN', label: 'Honduras', phone: '+504', phoneLength: 8},
    { code: 'HR', label: 'Croatia', phone: '+385', phoneLength: 9},
    { code: 'HT', label: 'Haiti', phone: '+509', phoneLength: 8},
    { code: 'HU', label: 'Hungary', phone: '+36', phoneLength: 9},
    { code: 'ID', label: 'Indonesia', phone: '+62', phoneLength: 11},
    { code: 'IE', label: 'Ireland', phone: '+353', phoneLength: 9},
    { code: 'IL', label: 'Israel', phone: '+972', phoneLength: 9},
    { code: 'IM', label: 'Isle of Man', phone: '+44', phoneLength: 10},
    { code: 'IN', label: 'India', phone: '+91', phoneLength: 10},
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '+246', phoneLength: 7},
    { code: 'IQ', label: 'Iraq', phone: '+964', phoneLength: 10},	
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '+98', phoneLength: 11},
    { code: 'IS', label: 'Iceland', phone: '+354', phoneLength: 7},
    { code: 'IT', label: 'Italy', phone: '+39', phoneLength: 10},
    { code: 'JE', label: 'Jersey', phone: '+44', phoneLength: 10},
    { code: 'JM', label: 'Jamaica', phone: '+1-876', phoneLength: 10},
    { code: 'JO', label: 'Jordan', phone: '+962', phoneLength: [8,9]},	
    { code: 'JP', label: 'Japan', phone: '+81', suggested: true, phoneLength:10, },
    { code: 'KE', label: 'Kenya', phone: '+254', phoneLength: 10},
    { code: 'KG', label: 'Kyrgyzstan', phone: '+996', phoneLength: 9},
    { code: 'KH', label: 'Cambodia', phone: '+855', phoneLength: 9},
    { code: 'KI', label: 'Kiribati', phone: '+686', phoneLength: 8},
    { code: 'KM', label: 'Comoros', phone: '+269', phoneLength: 7},
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '+1-869', phoneLength: 10},
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '+850', phoneLength: [4,6,7,13]},	
    { code: 'KR', label: 'Korea, Republic of', phone: '+82', phoneLength: [7,8]},	
    { code: 'KW', label: 'Kuwait', phone: '+965', phoneLength: 8},
    { code: 'KY', label: 'Cayman Islands', phone: '+1-345', phoneLength: 7},
    { code: 'KZ', label: 'Kazakhstan', phone: '+7', phoneLength: 10},
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '+856', phoneLength: [8,9]},
    { code: 'LB', label: 'Lebanon', phone: '+961', phoneLength: [7,8]},	
    { code: 'LC', label: 'Saint Lucia', phone: '+1-758', phoneLength: 7},
    { code: 'LI', label: 'Liechtenstein', phone: '+423', phoneLength: 7},
    { code: 'LK', label: 'Sri Lanka', phone: '+94', phoneLength: 7},
    { code: 'LR', label: 'Liberia', phone: '+231', phoneLength: [8,9]},	
    { code: 'LS', label: 'Lesotho', phone: '+266', phoneLength: 8},
    { code: 'LT', label: 'Lithuania', phone: '+370', phoneLength: 8},
    { code: 'LU', label: 'Luxembourg', phone: '+352', phoneLength: 9},
    { code: 'LV', label: 'Latvia', phone: '+371', phoneLength: 8},
    { code: 'LY', label: 'Libya', phone: '+218', phoneLength: 10},
    { code: 'MA', label: 'Morocco', phone: '+212', phoneLength: 9},
    { code: 'MC', label: 'Monaco', phone: '+377', phoneLength: 8},
    { code: 'MD', label: 'Moldova, Republic of', phone: '+373', phoneLength: 8},
    { code: 'ME', label: 'Montenegro', phone: '+382', phoneLength: 8},
    { code: 'MF', label: 'Saint Martin (French part)', phone: '+590', phoneLength: 6},
    { code: 'MG', label: 'Madagascar', phone: '+261', phoneLength: 7},
    { code: 'MH', label: 'Marshall Islands', phone: '+692', phoneLength: 7},
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '+389', phoneLength: 8},
    { code: 'ML', label: 'Mali', phone: '+223', phoneLength: 8},
    { code: 'MM', label: 'Myanmar', phone: '+95', min: 7, max: 10},					
    { code: 'MN', label: 'Mongolia', phone: '+976', phoneLength: 8},
    { code: 'MO', label: 'Macao', phone: '+853', phoneLength: 8},
    { code: 'MP', label: 'Northern Mariana Islands', phone: '+1-670', phoneLength: 7},
    { code: 'MQ', label: 'Martinique', phone: '+596', phoneLength: 9},
    { code: 'MR', label: 'Mauritania', phone: '+222', phoneLength: 8},
    { code: 'MS', label: 'Montserrat', phone: '+1-664', phoneLength: 10},
    { code: 'MT', label: 'Malta', phone: '+356', phoneLength: 8},
    { code: 'MU', label: 'Mauritius', phone: '+230', phoneLength: 8},
    { code: 'MV', label: 'Maldives', phone: '+960', phoneLength: 7},
    { code: 'MW', label: 'Malawi', phone: '+265', phoneLength: [7,8,9]},	
    { code: 'MX', label: 'Mexico', phone: '+52', phoneLength: 10},
    { code: 'MY', label: 'Malaysia', phone: '+60', phoneLength: 7},
    { code: 'MZ', label: 'Mozambique', phone: '+258', phoneLength: 12},
    { code: 'NA', label: 'Namibia', phone: '+264', phoneLength: 7},
    { code: 'NC', label: 'New Caledonia', phone: '+687', phoneLength: 6},
    { code: 'NE', label: 'Niger', phone: '+227', phoneLength: 8},
    { code: 'NF', label: 'Norfolk Island', phone: '+672', phoneLength: 6},
    { code: 'NG', label: 'Nigeria', phone: '+234', phoneLength: 8},
    { code: 'NI', label: 'Nicaragua', phone: '+505', phoneLength: 8},
    { code: 'NL', label: 'Netherlands', phone: '+31', phoneLength: 9},
    { code: 'NO', label: 'Norway', phone: '+47', phoneLength: 8},
    { code: 'NP', label: 'Nepal', phone: '+977', phoneLength: 10},
    { code: 'NR', label: 'Nauru', phone: '+674', phoneLength: 7},
    { code: 'NU', label: 'Niue', phone: '+683', phoneLength: 4},
    { code: 'NZ', label: 'New Zealand', phone: '+64', phoneLength: [8,9]},	
    { code: 'OM', label: 'Oman', phone: '+968', phoneLength: 8},
    { code: 'PA', label: 'Panama', phone: '+507', phoneLength: 8},
    { code: 'PE', label: 'Peru', phone: '+51', phoneLength: 9},
    { code: 'PF', label: 'French Polynesia', phone: '+689', phoneLength: 8},
    { code: 'PG', label: 'Papua New Guinea', phone: '+675', phoneLength: 8},	
    { code: 'PH', label: 'Philippines', phone: '+63', phoneLength: 10},
    { code: 'PK', label: 'Pakistan', phone: '+92', phoneLength: 10},
    { code: 'PL', label: 'Poland', phone: '+48', phoneLength: 9},
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '+508', phoneLength: 6},
    { code: 'PN', label: 'Pitcairn', phone: '+870', phoneLength: 9},	
    { code: 'PR', label: 'Puerto Rico', phone: '+1', phoneLength: 10},
    { code: 'PS', label: 'Palestine, State of', phone: '+970', phoneLength: 9},					
    { code: 'PT', label: 'Portugal', phone: '+351', phoneLength: 9},
    { code: 'PW', label: 'Palau', phone: '+680', phoneLength: 7},
    { code: 'PY', label: 'Paraguay', phone: '+595', phoneLength: 9},
    { code: 'QA', label: 'Qatar', phone: '+974', phoneLength: 8},
    { code: 'RE', label: 'Reunion', phone: '+262', phoneLength: 10},
    { code: 'RO', label: 'Romania', phone: '+40', phoneLength: 10},
    { code: 'RS', label: 'Serbia', phone: '+381', phoneLength: 9},
    { code: 'RU', label: 'Russian Federation', phone: '+7', phoneLength: 10},
    { code: 'RW', label: 'Rwanda', phone: '+250', phoneLength: 9},
    { code: 'SA', label: 'Saudi Arabia', phone: '+966', phoneLength: 9},
    { code: 'SB', label: 'Solomon Islands', phone: '+677', phoneLength: 7},
    { code: 'SC', label: 'Seychelles', phone: '+248', phoneLength: 7},
    { code: 'SD', label: 'Sudan', phone: '+249', phoneLength: 7},
    { code: 'SE', label: 'Sweden', phone: '+46', phoneLength: 7},
    { code: 'SG', label: 'Singapore', phone: '+65', phoneLength: 8},
    { code: 'SH', label: 'Saint Helena', phone: '+290', phoneLength: 4},
    { code: 'SI', label: 'Slovenia', phone: '+386', phoneLength: 9},
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '+47', phoneLength: 8},	
    { code: 'SK', label: 'Slovakia', phone: '+421', phoneLength: 9},
    { code: 'SL', label: 'Sierra Leone', phone: '+232', phoneLength: 8},
    { code: 'SM', label: 'San Marino', phone: '+378', phoneLength: 10},	
    { code: 'SN', label: 'Senegal', phone: '+221', phoneLength: 9},
    { code: 'SO', label: 'Somalia', phone: '+252', phoneLength: [8,9]},	
    { code: 'SR', label: 'Suriname', phone: '+597', phoneLength: [6,7]},	
    { code: 'SS', label: 'South Sudan', phone: '+211', phoneLength: 7},
    { code: 'ST', label: 'Sao Tome and Principe', phone: '+239', phoneLength: 7},
    { code: 'SV', label: 'El Salvador', phone: '+503', phoneLength: 8},
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '+1-721', phoneLength: 10},
    { code: 'SY', label: 'Syrian Arab Republic', phone: '+963', phoneLength: 7},					
    { code: 'SZ', label: 'Swaziland', phone: '+268', phoneLength: 8},	
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '+1-649', phoneLength: 10},
    { code: 'TD', label: 'Chad', phone: '+235', phoneLength: 6},
    { code: 'TF', label: 'French Southern Territories', phone: '+262', phoneLength: 10},
    { code: 'TG', label: 'Togo', phone: '+228', phoneLength: 8},
    { code: 'TH', label: 'Thailand', phone: '+66', phoneLength: 9},
    { code: 'TJ', label: 'Tajikistan', phone: '+992', phoneLength: 9},	
    { code: 'TK', label: 'Tokelau', phone: '+690', phoneLength: 5},
    { code: 'TL', label: 'Timor-Leste', phone: '+670', phoneLength: 7},
    { code: 'TM', label: 'Turkmenistan', phone: '+993', phoneLength: 8},	
    { code: 'TN', label: 'Tunisia', phone: '+216', phoneLength: 8},
    { code: 'TO', label: 'Tonga', phone: '+676', phoneLength: 5},
    { code: 'TR', label: 'Turkey', phone: '+90', phoneLength: 11},
    { code: 'TT', label: 'Trinidad and Tobago', phone: '+1-868', phoneLength: 7},
    { code: 'TV', label: 'Tuvalu', phone: '+688', phoneLength: 5},
    { code: 'TW', label: 'Taiwan, Province of China', phone: '+886', phoneLength: 9},	
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '+255', phoneLength: 7},
    { code: 'UA', label: 'Ukraine', phone: '+380', phoneLength: 9},
    { code: 'UG', label: 'Uganda', phone: '+256', phoneLength: 7},
    { code: 'US', label: 'United States', phone: '+1', suggested: true, phoneLength: 10},
    { code: 'UY', label: 'Uruguay', phone: '+598', phoneLength: 8},
    { code: 'UZ', label: 'Uzbekistan', phone: '+998', phoneLength: 9},
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '+379' ,phoneLength: 10},			
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '+1-784', phoneLength: 7},
    { code: 'VE', label: 'Venezuela', phone: '+58', phoneLength: 7},
    { code: 'VG', label: 'British Virgin Islands', phone: '+1-284', phoneLength: 7},
    { code: 'VI', label: 'US Virgin Islands', phone: '+1-340', phoneLength: 10},
    { code: 'VN', label: 'Vietnam', phone: '+84', phoneLength: 9},
    { code: 'VU', label: 'Vanuatu', phone: '+678', phoneLength: 5},
    { code: 'WF', label: 'Wallis and Futuna', phone: '+681', phoneLength: 6},
    { code: 'WS', label: 'Samoa', phone: '+685', phoneLength: [5,6,7]},	 
    { code: 'XK', label: 'Kosovo', phone: '+383', phoneLength: 8},	
    { code: 'YE', label: 'Yemen', phone: '+967', phoneLength: 9},
    { code: 'YT', label: 'Mayotte', phone: '+262', phoneLength: 9},
    { code: 'ZA', label: 'South Africa', phone: '+27', phoneLength: 9},
    { code: 'ZM', label: 'Zambia', phone: '+260', phoneLength: 9},
    { code: 'ZW', label: 'Zimbabwe', phone: '+263', phoneLength: 9},
  ];