import { Box, boxClasses, styled } from "@mui/material";

  export const StyledBox = styled(Box)(({ theme }) => ({
    // boxShadow: theme.shadows[3],
    maxWidth: '1050px',
    margin: '0px auto',
    padding: '40px 4.5rem',
    zIndex:'2',
    '@media (max-width:768px)':{
      padding:'25px 20px',
    }

  }));

export const TeamHead = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  zIndex:'2',
  fontSize: '32px',
  textAlign: 'center',
  lineHeight: '38px',
  fontFamily: 'Dystopian',
  padding: '0 0 16px 0',
  '@media (max-width:768px)':{
    fontSize:'24px',
  }
}));

export const TeamDescription = styled(Box)(({ theme }) => ({
  lineHeight: '26px',
  fontFamily: 'Lufga',
  textAlign: 'center',
  fontWeight: 300,
  paddingBottom: '50px',

}));

export const TeamBox = styled(Box)(({ theme }) => ({
  maxWidth: '625px',
  margin: '0 auto',
  position:'relative',
  zIndex:'2',
}))

export const Team = styled(Box)(({ theme }) => ({
  maxWidth: '1296px',
  margin: '0px auto',
  padding: '40px 4.5rem',
  zIndex:'2',
  '@media (max-width:768px)':{
    padding:'25px 2rem',
  },
  '@media (max-width:600px)':{
    padding:'25px 20px',
  }
}))

export const TeamCardBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(30%, 1fr))',
  gap: '16px',
  rowGap: '30px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)', 
  },


  [theme.breakpoints.down(600)]: {
    gridTemplateColumns: 'repeat(1, 1fr)', 
    justifyItems: 'center',
  },

}))

export const TeamCardStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '416px',
  fontFamily: 'Lufga',
  zIndex:'2',

}))

export const TeamImage = styled(Box)(({ theme }) => ({
  maxWidth: '416px',
  height: 'auto',
  fontSize: '26px',

}))


export const TeamName = styled(Box)(({ theme }) => ({
  lineHeight: '34px',
  fontSize: '20px',
  '@media (max-width:500px)':{
    fontSize:'18px'
  }


}))
export const TeamRole = styled(Box)(({ theme }) => ({
  maxWidth: '416px',
  height: 'auto',
  color: 'rgba(239, 239, 239, 1)',
  fontWeight: 300
}))

export const TeamOut = styled(Box)(({ theme }) => ({
  position: 'relative', 
  padding: '0', 
  margin: '0'
}))

export const LeaderDes = styled(Box)(({ theme }) => ({
  position:'absolute',
  top:'-300px',
  left:'0px',
  width:'450px',
  '@media (max-width:1200px)':{
    width:'400px'
  },
  '@media (max-width:992px)':{
    width:'350px',
    top:'-250px'
  },
  '@media (max-width:600px)':{
    width:'350px',
  },
  '@media (max-width:400px)':{
    width:'300px',
  }
}))

export const DoubleCircle = styled(Box)(({ theme }) => ({
position:'absolute',
bottom:'-10%',
right:'0px',
width:'400px',
'@media (max-width:1200px)':{
  width:'350px'
},
'@media (max-width:992px)':{
  width:'300px',
},
'@media (max-width:768px)':{
  width:'250px',
},
'@media (max-width:400px)':{
  width:'200px',
}
}))
export const PurpleBlurBig = styled(Box)(({ theme }) => ({
  position:'absolute',
  bottom:'10%',
  right:'0px',
  width:'400px'
  }))
export const PurpleBlur = styled(Box)(({ theme }) => ({
  position:'absolute',
  top:'-10%',
  right:'0px',
  width:'400px',
  height:'400px',
}))
export const CubeBlur = styled(Box)(({ theme }) => ({
  position:'absolute',
  bottom:'10%',
  right:'0px',
  width:'400px',
  height:'400px',
  '@media (max-width:768px)':{
    width:'350px',
    height:'350px'
  },
  '@media (max-width:400px)':{
    width:'300px',
     height:'300px'
  }
}))
export const Star = styled(Box)(({ theme }) => ({
  position:'absolute',
  bottom:'0%',
  right:'0px',
  width:'300px'
}))
export const StarBlur = styled(Box)(({ theme }) => ({
  position:'absolute',
  bottom:'30%',
  right:'0px',
  width:'400px',
  height:'400px'
}))
export const GreenBlur = styled(Box)(({ theme }) => ({
  position:'absolute',
  top:'-10%',
  left:'0px',
  width:'400px',
  height:'400px',
  '@media (max-width:1200px)':{
    width:'400px'
  },
  '@media (max-width:992px)':{
    width:'350px',
  },
  '@media (max-width:600px)':{
    width:'300px',
  },
  '@media (max-width:400px)':{
    width:'200px',
    top:'0'
  }
}))
export const CircleBlur = styled(Box)(({ theme }) => ({
  position:'absolute',
  bottom:'0%',
  right:'0px',
  width:'400px',
  '@media (max-width:1200px)':{
    width:'400px'
  },
  '@media (max-width:992px)':{
    width:'350px',
  },
  '@media (max-width:600px)':{
    width:'250px',
  },
  '@media (max-width:400px)':{
    width:'200px',
  }
}))





