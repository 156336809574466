// import { Box, Container, Grid } from '@mui/material';
// import React from 'react';
// import project2 from '../../../asset/images/poject-img2.png';
// import project1 from '../../../asset/images/project-img1.png';

// const projects = () => {

//   return (
//     <Box>
//       <h1 style={{ margin: '0', textAlign: 'center' }}></h1>

//       <Box
//         sx={{
//           marginTop: '5%',
//           backgroundColor: '#515151',
//           color: '#fff',
//           padding: '10px 0',
//           borderRadius: '10px',
//         }}
//       >
//         <Container fixed>
//           <Grid container spacing={3}>
//             <Grid item xs={12}>
//               <Box
//                 sx={{
//                   backgroundColor: '#000',
//                   borderRadius: '10px',
//                   overflow: 'hidden',
//                 }}
//               >
//                 <Box
//                   component="img"
//                   src={project1}
//                   alt="Project Image 1"
//                   sx={{
//                     width: '100%',
//                     height: 'auto',
//                     display: 'block',
//                   }}
//                 />
//               </Box>
//             </Grid>

//             <Grid item xs={12}>
//               <Box
//                 sx={{
//                   backgroundColor: '#000',
//                   borderRadius: '10px',
//                   overflow: 'hidden',
//                 }}
//               >
//                 <Box
//                   component="img"
//                   src={project2}
//                   alt="Project Image 2"
//                   sx={{
//                     width: '100%',
//                     height: 'auto',
//                     display: 'block',
//                   }}
//                 />
//               </Box>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>

//     </Box>
//   );
// };

// export default projects;


import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { projectTitle, projectContent, projectContentContainer, projectImgBg, gridContainer, gridSecCon, gridFirstCon, gridSecImgCon, projectContain } from './style'
import { Images } from '../../../asset/images'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Projects = ({content}) => {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      AOS.init({
        duration: 1000, // Animation duration
        easing: 'ease-out', // Smooth animation
        once: true, // Animation happens only once
        offset: 120, // Animation triggers 120px before element is visible
      });
    }
  }, [isVisible]);

  // Intersection Observer
  const observerRef = React.useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set visibility when the component is in view
        }
      },
      { threshold: 0.3 } // Trigger when 30% of the component is visible
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box ref={observerRef}>
      {isVisible && (
        <>
          <Box>
            <Box sx={projectContentContainer}>
              <Box sx={{ maxWidth: '742px' }}>
                <Typography sx={projectTitle}>{content?.title1 || 'title'}</Typography>
                <Typography sx={projectContent}>{content?.description1 || 'description'}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={projectImgBg}>
            <Box sx={projectContain}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', }}>
                <Grid sx={gridContainer}>
                  <Grid sx={gridFirstCon}>
                    <img data-aos="fade-right" style={{ width: '100%', height: '100%' }} src={content?.images[0]} alt='Project Image' />
                  </Grid>
                  <Box sx={gridSecCon}>
                    <Grid sx={gridSecImgCon}>
                      <img data-aos="fade-left" style={{ width: '100%', height: '100%' }} src={content?.images[1]} alt='Project Image' />
                    </Grid>
                    <Grid sx={gridSecImgCon}>
                      <img data-aos="fade-left" style={{ width: '100%', height: '100%' }} src={content?.images[2]} alt='Project Image' />
                    </Grid>
                  </Box>
                </Grid>
                <Grid sx={gridContainer}>
                  <Box sx={gridSecCon}>
                    <Grid sx={gridSecImgCon}>
                      <img data-aos="fade-right" data-aos-delay="500" style={{ width: '100%', height: '100%' }} src={content?.images[3]} alt='Project Image' />
                    </Grid>
                    <Grid sx={gridSecImgCon}>
                      <img data-aos="fade-right" data-aos-delay="500" style={{ width: '100%', height: '100%' }} src={content?.images[4]} alt='Project Image' />
                    </Grid>
                  </Box>
                  <Grid sx={gridFirstCon}>
                    <img data-aos="fade-left" data-aos-delay="500" style={{ width: '100%', height: '100%' }} src={content?.images[5]} alt='Project Image' />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Projects
