export const testiCon = {
    backgroundColor: '#FFEEE8',
    color: '#121010',
    overflow: 'hidden', 
    position: 'relative',
}
export const testiConInner = {
    maxWidth:'1400px',
    margin:'0 auto',
    padding: '65px 7.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        padding: '50px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        padding: '40px 20px',
    },
}

export const testiTital = {
    fontSize: '32px',
    fontWeight: '700',
    paddingBottom: '50px',
    '@media (min-width: 901px) and (max-width: 1200px)': {
        paddingBottom: '40px',
    },
    '@media (min-width: 601px) and (max-width: 900px)': {
        paddingBottom: '30px',
    },
    '@media (min-width: 320px) and (max-width: 600px)': {
        paddingBottom: '20px',
    },
}

export const testiContainer = {
    maxWidth: '1149px',
}

export const testiContent1 = {
    fontSize: '96px',
    fontFamily: 'Lufga',
    '@media (min-width: 901px) and (max-width: 1200px)': {
        fontSize: '70px',
    },
    '@media (min-width: 601px) and (max-width: 900px)': {
        fontSize: '60px',
    },
    '@media (min-width: 320px) and (max-width: 600px)': {
        fontSize: '50px'
    },
}

export const testiContent2 = {
    fontSize: '96px',
    fontFamily: 'Lufga',
    textAlign: 'right',
    '@media (min-width: 901px) and (max-width: 1200px)': {
        fontSize: '70px',
    },
    '@media (min-width: 601px) and (max-width: 900px)': {
        fontSize: '60px',
    },
    '@media (min-width: 320px) and (max-width: 600px)': {
        fontSize: '50px'
    },
}

export const testiMainContent = {
    fontSize: '45px',
    fontFamily: 'Lufga',
    margin: '-50px 0px -20px',
    '@media (min-width: 901px) and (max-width: 1200px)': {
        fontSize: '38px',
    },
    '@media (min-width: 601px) and (max-width: 900px)': {
        fontSize: '32px',
        margin: '-30px 0px -14px',
    },
    '@media (min-width: 320px) and (max-width: 600px)': {
        fontSize: '24px',
        margin: '-28px 0px -4px',
    },
}

export const testiName = {
    fontFamily: 'Lufga',
    color: '#4E4E4E',
    fontSize: '40px',
    '@media (min-width: 901px) and (max-width: 1200px)': {
        fontSize: '32px',
    },
    '@media (min-width: 601px) and (max-width: 900px)': {
        fontSize: '28px',
    },
    '@media (min-width: 320px) and (max-width: 600px)': {
        fontSize: '22px'
    },
}

export const testiPost = {
    fontFamily: 'Lufga',
    color: '#4E4E4E',
    fontSize: '24px',
    '@media (min-width: 320px) and (max-width: 600px)': {
        fontSize: '18px'
    },
}