import React, { useState, useEffect } from "react";
import CaseStudySec from "./caseStudySec";
import { overallbg, firstSection, caseStudyBanner, caseStudyImageStyle } from "./style";
import { Box } from "@mui/material";
import VisualLanguage from "./visualLanguage";
import Projects from "./projects";
import ImageSection from "./imageSection";
import Work from "./work";
import Testimonials from "./testimonials";
import CaseStudyImage from "../../asset/images/caseStudy/vivarta_caseStudy.png";
import { useParams } from "react-router-dom";
import { avanaSurgical, elixify, kailasaKalathara, mycelium, ncp, neuranest } from "../../constants/caseStudyContent";


function CaseStudy() {
   const [content, setContent] = useState({});
   const params = useParams();

   useEffect(() => {
      if (params == 'slug') {
         console.log('happy starting')
      }
      console.log('useEffect')
      console.log(params)
      switch (params.slug) {
         case "vivarta":
            setContent(kailasaKalathara);
            break;
         case "ncp":
            setContent(ncp);
            break;
         case "elixify":
            setContent(elixify);
            break;
         case "neuranest":
            setContent(neuranest);
            break;
         case "Mycelium":
            setContent(mycelium);
            break;
         case "avana":
            setContent(avanaSurgical);
            break;
         default:
            setContent({});
            break;
      }
   }, [params]);

   return (
      <div>
         <Box sx={overallbg}>
            <Box sx={firstSection}>
               <h1>{content?.heroSection?.title || "Header"}</h1>
               <Box sx={{
                  ...caseStudyBanner,
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${content?.heroSection?.bgImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
               }}>
                  <Box sx={caseStudyImageStyle}>
                     <img
                        src={content?.heroSection?.image || CaseStudyImage}
                        alt="Case Study Banner"
                        style={{
                           width: "100%",
                           float: "inline-end",
                           transition: "transform 0.8s ease-in-out, opacity 0.8s ease-in-out", // Smooth & slow effect
                        }}
                        onMouseEnter={(e) => {
                           e.target.style.transform = "translateY(10px) scale(1.05)"; // Move down & zoom in slightly
                           // e.target.style.opacity = "0.9"; // Light fade effect
                        }}
                        onMouseLeave={(e) => {
                           e.target.style.transform = "translateY(0) scale(1)"; // Reset position & zoom
                           e.target.style.opacity = "1"; // Reset opacity
                        }}
                     />

                  </Box>
               </Box>
            </Box>

            <CaseStudySec content={content.secondSection} />
            <ImageSection content={content.thirdSection} />
            <VisualLanguage content={content.fourthSection} />
            <Projects content={content.fifthSection} />
            {Array.isArray(content?.Testimonial) && content.Testimonial.length > 0 && (
               <Testimonials content={content.Testimonial} />
            )}
            <Work />
         </Box>
      </div>
   );
}

export default CaseStudy;
