import React, { useState, useEffect, useRef } from 'react'
import { Autocomplete, Box, Button, InputAdornment, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { blueGradient, ConstInput, contactBtn, contactButton, contactErrorStyle, contactField, contactLeft, contactRight, ContactRightHead, contactRightInner, ContactsInfo, ContactStatus, contactStyle, ContactTeam, ContactText, CustomSelect, greenGradient, menuProps, styleModel } from './contact_style'
import { contactStatus, contactTeamInfo, countries } from '../../../constants'
import { Images } from '../../../asset/images'
import apiClient from '../../../component/api/apiClient'
import { apiRouters } from '../../../component/api/apiRouters'
import { ErrorHandler } from './formErrorHandler'

import ReCAPTCHA from 'react-google-recaptcha'
import Progress from '../../../component/UI-KIT/progressBar'

import WarningIcon from '@mui/icons-material/Warning';

function ContactMain() {


  const [submitModel, setSubmitModel] = useState(false);

  const [openBack, setOpenBack] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [nameError, setNameError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [infoError, setInfoError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [numberErrorText, setNumberErrorText] = useState('');

  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [aboutProject, setAboutProject] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  // const [captchaError,setCaptchaError] = useState(false);
const [numberLength,setNumberLength] = useState(10);


  //postcontact function for post api 
  const postContact = async () => {

    const check = ErrorHandler(setNameError, setMailError, setInfoError, setNumberError, numberLength, fullName,phoneNo,emailAddress,aboutProject);
    if (check != 4) {
      return;
    }

    const phoneMerge = countryCode + " " +phoneNo;

    const formData = {
      fullName: fullName,
      emailAddress: emailAddress,
      aboutProject: aboutProject,
      phoneNo: phoneMerge,
    };

    try {
      setOpenBack(true);
      const response = await apiClient.post(apiRouters.contactPost, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status == 201) {
        setOpenBack(false);
        handleOpen();
        setTimeout(() => { handleClose() }, 3000)
        // console.log(response.data);
      }
      else {
        setOpenBack(false);
        handleOpen();
        setSubmitModel(true);
        setTimeout(() => { handleClose() }, 3000)
      }

    }
    catch (e) {
      console.log(e);
      setOpenBack(false);
      handleOpen();
      setSubmitModel(true);
      setTimeout(() => { handleClose() }, 3000)

    }
  }

  // const handleCaptchaChange = (value) => {
  //   // setCaptchaError(false);
  // }
  // const siteKey = process.env.REACT_APP_LOCALHOST_CAPTCHA_TOKEN;



  const [countryCode, setCountryCode] = useState('+91');

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  useEffect(() => {

    let temp = 'IN';
    countries.map((data) => {
      if (data.phone == countryCode) {
        temp = data.code;
        setNumberLength(data.phoneLength);
      }
    }
    )
    setNumberErrorText(`Please Enter a Valid ${temp} Phone Number`)
  }, [countryCode])



  const renderCount = useRef(0);
  useEffect(()=>{
  renderCount.current += 1; 

    if (renderCount.current <= 2) {
      return; 
    }
  ErrorHandler(setNameError, setMailError, setInfoError, setNumberError, numberLength, fullName,phoneNo,emailAddress,aboutProject);
},[fullName,aboutProject,phoneNo,emailAddress])
  return (
    <>
      <Box sx={blueGradient}>

        <Box sx={greenGradient}>
          <Box sx={contactStyle}>

            <Box sx={contactLeft} >

              {/* <Box sx={{
                display: 'flex',
                justifyContent: 'space-evenly ',
                padding: '40px 0 0 0',
                '@media (max-width:600px)': { padding: '15px 20px' },
                flexWrap: 'wrap',

              }}>
                {contactTeamInfo.map((data) => (
                  <ContactTeam>
                    <Box sx={{ position: 'relative', width: '75px' }}>
                      <img src={data.image} style={{ borderRadius: '50%', maxWidth: '100%' }} />
                      <a style={{ position: 'absolute', bottom: '-10px', left: '35%' }} href={data.link} target='_blank'>
                        <img src={Images.ContactIn} />
                      </a>
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                      {data.name}
                    </Box>
                    <Box sx={{ fontSize: '14px', fontWeight: '300' }}>
                      {data.role}
                    </Box>
                  </ContactTeam>))}
              </Box> */}


              <Box sx={{ padding: '25px 0px', fontWeight: '500' }}>

                {contactStatus.map((data) => (
                  <ContactStatus key={data.id}>
                    <Box>
                      <img src={Images.ContactSVG} alt='ContactSVG' style={{ paddingRight: '12px' }} />
                    </Box>
                    <Box>
                      {data.detail}
                    </Box>
                  </ContactStatus>
                ))}

              </Box>



              <Box sx={{ padding: '25px 0 48px 0px', }}>

                <Box sx={{ fontWeight: '350' }}>Project Inquiries</Box>

                <Box sx={ContactsInfo}>

                  <img src={Images.ContactMail} alt='ContactMail' />
                  <a href='mailto:info@houseofwebsites.com'>info@houseofwebsites.com</a>

                </Box >

                <Box sx={ContactsInfo}>

                  <img src={Images.ContactPhone} alt='ContactPhone' />
                  <a href='tel:9280233350' style={{ borderBottom: '1px solid' }}>Book a call</a>

                </Box>

              </Box>


            </Box>





            <Box sx={contactRight}>

              <Box sx={contactRightInner}>



                <Typography variant='h4' sx={ContactRightHead}>Tell us about your project</Typography>
                <Box sx={contactField}>
                  <Box sx={ContactText}>
                    <TextField id="standard-basic" label="Full Name" variant="standard" sx={ConstInput} onChange={(e) => {
                      setFullName(e.target.value)
                    }} name='fullName' />

                    {nameError && <Typography sx={contactErrorStyle}>Please Fill the Name</Typography>}
                  </Box>
                  <Box sx={ContactText}>
                    <TextField id="standard-basic" label="Email Address" variant="standard" sx={ConstInput} onChange={(e) => {
                      setEmailAddress(e.target.value)
                    }} name='emailAddress' />
                    {mailError && <Typography sx={contactErrorStyle}>Please Fill the Valid Mail</Typography>}
                  </Box>
                </Box>


                <Box sx={{ marginBottom: '25px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'strech', gap: '10px', maxWidth: '49%', '@media (max-width:600px)': { maxWidth: '100%' } }}>

                    <CustomSelect
                      value={countryCode}
                      onChange={handleCountryCodeChange}
                      variant="standard"
                      MenuProps={menuProps}
                    >
                      {countries.map((option) => (
                        <MenuItem key={option.phone} value={option.phone}>
                          {<img src={`https://flagcdn.com/48x36/${option.code.toLowerCase()}.png`} alt='flag' style={{ width: "15px", paddingRight: '5px' }} />} {option.phone}
                        </MenuItem>
                      ))}
                    </CustomSelect>

                    {/* Phone Number Input Field */}
                    <TextField
                      label="Phone Number"
                      variant="standard"
                      type='number'
                      name='phoneNo'
                      fullWidth
                      onChange={(e) => {
                        setPhoneNo(e.target.value)
                      }}
                      sx={{
                        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                          WebkitAppearance: 'none',
                        }
                      }}
                    />

                  </Box>
                  {numberError && <Typography sx={contactErrorStyle}>{numberErrorText}</Typography>}

                </Box>

                <Box >
                  <TextField id="standard-basic" label="About Project" variant="standard" sx={ConstInput} onChange={(e) => {
                    setAboutProject(e.target.value)
                  }} name='aboutProject' />
                  {infoError && <Typography sx={contactErrorStyle}>Please Tell us About Project</Typography>}
                </Box>

                {/* <div>
                  <div style={{ marginTop: '2rem' }}>

                    <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <p>
                    {captchaError && <Typography sx={contactErrorStyle}>Please Fill the Captcha</Typography>}
                  </p>
                </div> */}

                <Box sx={contactBtn}>

                  <Box sx={{
                    color: 'rgba(112, 114, 113, 1)', maxWidth: '228px', fontSize: '12px', fontWeight: '500', '@media(max-width:768px)': { textAlign: 'center' }, lineHeight: '18px', 'a': {
                      textDecoration: 'none !important', color: '#000'
                    }
                  }}>
                    By submitting this form you agree to our <a href=''>Terms of Use</a> and <a href=''>Privacy Policy</a>
                  </Box>

                  <Box>


                    <Box sx={contactButton} onClick={postContact}>
                      <Button sx={contactButton.contactFirst}>
                        <img src={Images.ContactArrow} alt='ContactArrow' />
                      </Button>
                      <Button sx={contactButton.contactSecond}>
                        Submit
                      </Button>
                    </Box>

                  </Box>
                </Box>

                {/* form submitted model  */}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModel}>
                    <Box></Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      {submitModel ? <WarningIcon /> : <img src={Images.SuccessIcon} alt='SuccessIcon' />}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, fontFamily: 'Lufga' }}>
                      {submitModel ? 'Error to Submit Form' : 'Form Submitted Successfully'}
                    </Typography>
                  </Box>
                </Modal>

                {/* for backdrop functionality */}
                <Progress openBack={openBack} />



              </Box>

            </Box>



          </Box>


        </Box>




      </Box>

    </>
  )
}

export default ContactMain