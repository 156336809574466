export const workContainer = {
    maxWidth:'1400px',
    margin:'0 auto',
    padding: '15px 7.5rem',
    '@media (min-width: 601px) and (max-width: 900px)' : {
        padding: '70px 2rem',
    },
    '@media (min-width: 320px) and (max-width: 600px)' : {
        padding: '40px 20px',
    },
}
export const workContainerOuter = {
    
}

export const contentContainer = {
    maxWidth: '742px',
}

export const workContent = {
    fontWeight: 'bold',
    color: '#FFFFFF',
    fontSize: '40px',
    '@media (min-width: 320px) and (max-width: 600px)': {
        fontSize: '32px',
    },
}

export const workDesc = {
    fontWeight: '300',
    color: '#FFFFFF',
    fontSize: '18px',
    fontFamily: 'Lufga',
}