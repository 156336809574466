import React from 'react'
import TeamBanner from './teamBanner'
import GroupSlider from './teamGroup'
import { Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'

export default function Ourteam() {
    return (
      <>
      <Helmet>
    <title>
        Our Team | House of Websites - Meet Our Creative Professionals
    </title>
    <meta
        name="title"
        content="Our Team | House of Websites - Meet Our Creative Professionals"
    />
    <meta
        name="description"
        content="Meet the talented team at House of Websites. Our skilled professionals specialize in web design, development, and digital marketing, working together to deliver innovative solutions for your business."
    />
    <meta
        name="keywords"
        content="House of Websites portfolio
                            our works,
                            web design projects Chennai,
                            website development portfolio,
                            successful web design examples,
                            creative project showcase,
                            digital solutions portfolio,
                            client work examples,
                            website design case studies,
                            innovative design projects,
                            web development case studies,
                            branding projects,
                            eCommerce website examples,
                            responsive web design works,
                            Chennai web agency portfolio,
                            House of Websites team,
                            web design experts,
                            digital marketing specialists,
                            experienced designers,
                            creative professionals"
    />
    <link rel="canonical" href="https://houseofwebsites.com" />
</Helmet>
        <Box
            sx={{
                backgroundColor: '#0F0121',
                fontFamily: 'Lufga',
            }}
        >
            <TeamBanner />
            <GroupSlider />
        </Box>
      </>
    )
}

