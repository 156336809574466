import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { testiCon, testiTital, testiContent, testiMainContent, testiName, testiPost, testiContent2, testiContent1, testiContainer, testiConInner } from './style';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from 'swiper/modules';

const Testimonials = ({ content = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  // useEffect(() => {
  //   if (!content || content.length === 0) return; // Prevent interval if no content

  //   const interval = setInterval(() => {
  //     setIsSliding(true);
  //     setTimeout(() => {
  //       setIsSliding(false);
  //       setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
  //     }, 1000);
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [content]);

  // Fallback if content is empty
  if (!content || content.length === 0) {
    return (
      <Box sx={testiCon}>
        <Box sx={testiConInner}>
          <Typography sx={testiTital}>Testimonial</Typography>
          <Typography sx={testiMainContent}>No testimonials available.</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={testiCon}>
      <Box sx={testiConInner}>
        <Typography sx={testiTital}>Testimonial</Typography>


        {/* <Box
          sx={{
            transition: 'transform 1s ease-in-out',
            transform: isSliding ? 'translateX(-100%)' : 'translateX(0)',
          }}
        >
          <Box sx={testiContainer}>
            <Typography sx={testiContent1}>“</Typography>
            <Typography sx={testiMainContent}>
              {content[currentIndex]?.content || 'Loading...'}
            </Typography>
            <Typography sx={testiContent2}>“</Typography>
            <Typography sx={testiName}>{content[currentIndex]?.name || ''}</Typography>
            <Typography sx={testiPost}>{content[currentIndex]?.post || ''}</Typography>
          </Box>
        </Box> */}
        

        <Swiper    
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        >
        {content.map((data)=>(
          <SwiperSlide>
            <Box
          
          >
            <Box sx={testiContainer}>
              <Typography sx={testiContent1}>“</Typography>
              <Typography sx={testiMainContent}>
                {data?.content || 'Loading...'}
              </Typography>
              <Typography sx={testiContent2}>“</Typography>
              <Typography sx={testiName}>{data?.name || ''}</Typography>
              <Typography sx={testiPost}>{data?.post || ''}</Typography>
            </Box>
          </Box>
          </SwiperSlide>
        ))}
        </Swiper>






      </Box>
    </Box>
  );
};

export default Testimonials;
