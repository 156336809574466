import React from 'react'
import { CircleBlur, GreenBlur, Team, TeamBox, TeamCardBox, TeamDescription, TeamHead, TeamOut } from '../../teamgroupStyle'
import { cuddaloreRangers} from '../../TeamInfo'
import TeamCard from '../../teamCards'
import des from '../../../../../asset/images/cubes.png'
import blur from '../../../../../asset/images/green blur.png'
import { Images } from '../../../../../asset/images'

function CudAnalist() {
  return (
    <>
 <TeamOut>

 <CircleBlur>
          <img src={Images.Cubes} alt='cubes' style={{width:'100%'}}/>
        </CircleBlur>
        <GreenBlur>
          <img src={Images.Greenblur} alt='Greenblur' style={{width:'100%'}}/>
        </GreenBlur>

 <Team>
        <TeamBox>
            <TeamHead>
            Requirement Rangers
            </TeamHead>
            <TeamDescription>
            Our business analysts expertly bridge client needs and technical solutions, ensuring every project is perfectly aligned with your goals. 
            </TeamDescription>
        </TeamBox>
        <TeamCardBox>
            {cuddaloreRangers.map((data)=> <TeamCard data={data}/>)}
        </TeamCardBox>
       </Team>
 </TeamOut>
    </>
  )
}

export default CudAnalist
