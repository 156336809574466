import React from 'react'

import {
    AppContainer,
    BannerSection,
    HorizontalLine,
    JoinBtn,
    StyledGradient,
} from './career.style'
import { Box, Typography } from '@mui/material'
import Corevalues from './corevalues'
import Curentopenings from './openings'
import CareerSlider from './careerslider'
import Glassdoor from './glassdoor'
import { Images } from '../../asset/images'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
import { Helmet } from 'react-helmet-async'
AOS.init()
export default function Careers() {
    return (
        <>
            <Helmet>
                <title>
                    Careers at House of Websites | Join Our Creative Team
                </title>
                <meta
                    name="title"
                    content="Careers at House of Websites | Join Our Creative Team"
                />
                <meta
                    name="description"
                    content="Explore exciting career opportunities at House of Websites. Join our talented team in Chennai and contribute to innovative web design and digital marketing projects that make an impact."
                />
                <meta
                    name="keywords"
                    content="careers at House of Websites,
                            job opportunities Chennai,
                            web design jobs,
                            digital marketing careers,
                            join our team"
                />
                <link rel="canonical" href="https://houseofwebsites.com" />
            </Helmet>
            <Box>
                <BannerSection>
                    <video
                        src={Images?.CareerVideo}
                        autoPlay
                        muted
                        loop
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            objectFit: 'cover',
                            top: '0',
                        }}
                    >
                        Your browser does not support the video tag.
                    </video>
                    <AppContainer>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: {
                                        xl: 80,
                                        lg: 80,
                                        md: 72,
                                        sm: 50,
                                        xs: 27,
                                    },
                                    fontFamily: 'Dystopian',
                                    fontWeight: '700',
                                    color: '#fff',
                                    letterSpacing: '1.2px',
                                    paddingTop: {
                                        xl: '210px',
                                        lg: '210px',
                                        md: '210px',
                                        sm: '210px',
                                        xs: '150px',
                                    },
                                }}
                                data-aos="fade-up"
                                data-aos-duration="1000"
                            >
                                <StyledGradient>Career</StyledGradient> With{' '}
                                <br></br>
                                <HorizontalLine></HorizontalLine>
                                House of Websites
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: {
                                        xl: 20,
                                        lg: 20,
                                        md: 18,
                                        sm: 18,
                                        xs: 16,
                                    },
                                    fontFamily: 'Lufga',
                                    fontWeight: '300',
                                    color: '#fff',
                                    lineHeight: {
                                        lg: '32px',
                                        lg: '32px',
                                        md: '30px',
                                        sm: '30px',
                                        xs: '28px',
                                    },
                                    width: {
                                        xl: '58%',
                                        lg: '58%',
                                        md: '75%',
                                        sm: '95%',
                                        xs: '100%',
                                    },
                                }}
                                data-aos="fade-up"
                                data-aos-duration="1400"
                            >
                                Looking for a career that's all about making,
                                shaping, and celebrating the extraordinary? then
                                you are home!
                            </Typography>

                            <a href="#career-navigate">
                                <JoinBtn>Join our Team</JoinBtn>
                            </a>
                        </Box>
                    </AppContainer>
                </BannerSection>
                <Box
                    sx={{
                        height: { xl: 300, lg: 300, md: 175, sm: 130, xs: 135 },
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <AppContainer>
                        <CareerSlider />
                    </AppContainer>
                </Box>
                <Corevalues />
                <Glassdoor />
                <Curentopenings />
            </Box>
        </>
    )
}
