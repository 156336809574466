import { Box, Typography } from '@mui/material'
import React from 'react'
import { bg, caseStudyBg, heading, secondHead, secondSecContent, secondSecContent2 } from './style'

const CaseStudySec = ({content}) => {
  return (
    <Box sx={bg}>
     <Box sx={caseStudyBg}>
     <Box sx={{paddingBottom: '50px'}}>
        <Typography sx={heading}>
          {content?.title || 'Client'}
        </Typography>
        <Typography sx={secondHead}>
        {content?.subTitle || 'Service'}
        </Typography>
        <Typography sx={secondSecContent}>
        {content?.description || 'description'}
        </Typography>
      </Box>
      <Box>
        <Typography sx={heading}>
        {content?.title1 || 'Client'}
        </Typography>
        <Typography sx={secondHead}>
        {content?.subTitle1 || 'Service'}
        </Typography>
        <Typography sx={secondSecContent2}>
        {content?.description1 || 'description'}
        </Typography>
      </Box>
     </Box>
    </Box>
  )
}

export default CaseStudySec
